import useRootRoute from '@hooks/routes/useRootRoute';
import useToken from '@hooks/store/useToken';
import { apiRoute, BasicApiResponse, requestSecurePost } from '@libs/api/api';
import RootNavigation from '@routes/RootNavigation';
import { AuthTypes } from '@typedef/components/common/auth.types';
import { stringify } from 'querystring';
import React, { useCallback, useEffect, useState } from 'react';

const RootNavigationContainer = () => {
  const {
    getAccessToken,
    getRefreshToken,
    setAccessToken,
    setRefreshToken,
    clearAccessToken,
    claerRefreshToken,
  } = useToken();
  const { getRootRoute, setRootRoute } = useRootRoute();

  const [checked, setChecked] = useState(false);

  const checkRoot = useCallback(async () => {
    const root = getRootRoute();

    if (!root) {
      setChecked(true);
      return;
    }

    if (root === 'main') {
      const refreshToken = getRefreshToken();

      if (!refreshToken) {
        setRootRoute('login');
        setChecked(true);
        return;
      }

      const { config, data } = await requestSecurePost<AuthTypes>(
        apiRoute.auth.refresh,
        {},
        {},
        refreshToken,
      );

      if (config.status === 200) {
        setAccessToken(data.accessToken);
        setRefreshToken(data.refreshToken);
        setRootRoute('main');
        setChecked(true);
      } else {
        setRootRoute('login');
        clearAccessToken();
        claerRefreshToken();
        setChecked(true);
      }
    } else {
      setChecked(true);
    }
  }, []);

  useEffect(() => {
    checkRoot();
  }, []);

  return checked ? <RootNavigation root={getRootRoute() || 'login'} /> : <></>;
};

export default RootNavigationContainer;
