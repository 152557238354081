import { formatDate } from '@libs/factory';
import { CouponTypes } from '@typedef/components/Coupon/coupon.types';
import React from 'react';
import CouponItem from './CouponItem';
import '../styles/partners.detail.coupon.styles.css';
import CPagingContainer from '@components/common/CPaging/containers/CPagingContainer';

type Props = {
  isEditable: boolean;
  maxPage: number;
  currentPage: number;
  couponList: CouponTypes[];
  setCouponList: React.Dispatch<React.SetStateAction<CouponTypes[]>>;
  onAddCouponClicked: () => void;
  onDeleteCouponClicked: (idx: number) => void;
  onEditCouponClicked: (idx: number) => void;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
};
const PartnersDetailCoupon = ({
  isEditable,
  maxPage,
  couponList,
  setCouponList,
  onAddCouponClicked,
  onDeleteCouponClicked,
  onEditCouponClicked,
  currentPage,
  setCurrentPage,
}: Props) => {
  return (
    <div className='content-root partners-coupon'>
      <div className='table-container'>
        <div className='title-section'>
          <span className='table-title'>쿠폰내역</span>
          <button
            disabled={!isEditable}
            className={`add-coupon-button `}
            onClick={onAddCouponClicked}>
            추가하기
          </button>
        </div>
        <div className='header-section'>
          <div className='item'>발행일</div>
          <div className='item'>만료일</div>
          <div className='item'>쿠폰이름</div>
          <div className='item amount'>금액</div>
          <div className='item'>사용조건</div>
          <div className='item '>삭제</div>
          <div className='item '>저장</div>
        </div>
        <div className={`body-section ${!isEditable ? 'view' : ''}`}>
          {couponList.map((item, idx) => (
            <CouponItem
              couponItem={item}
              key={idx}
              onDeleteCouponClicked={onDeleteCouponClicked}
              onEditCouponClicked={onEditCouponClicked}
              idx={idx}
              setCouponList={setCouponList}
            />
          ))}
        </div>
        <div className='footer-section'>
          <CPagingContainer
            maxPage={maxPage}
            page={currentPage}
            setPage={setCurrentPage}
          />
        </div>
      </div>
    </div>
  );
};

export default PartnersDetailCoupon;
