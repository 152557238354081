import { combineReducers } from 'redux';
import popupReducer from './popup/reducer';

const rootReducer = combineReducers({
  popupReducer,
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
