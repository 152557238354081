import useToken from '@hooks/store/useToken';
import { useTab } from '@hooks/useTab';
import { alertMessage } from '@libs/alertMessage';
import {
  apiRoute,
  BasicListDataType,
  requestGet,
  requestSecurePost,
} from '@libs/api/api';
import {
  FaqListItemTypes,
  FqaTabTypes,
} from '@typedef/components/FAQ/faq.types';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import FAQList from '../components/FAQList';

const FAQLListContainer = () => {
  const { getAccessToken } = useToken();
  const { tab: tabItem } = useTab<{}>('faq');
  const navigate = useNavigate();
  const { queryParams, updateQueryParamsFromSession } = useTab<{
    page: number;
  }>('faq');

  const [category, setCategory] = useState('fqa');
  const [tab, setTab] = useState<FqaTabTypes>(
    (tabItem as FqaTabTypes) || 'fqa',
  );
  const [faqList, setFaqList] = useState<FaqListItemTypes[]>([]);
  const [maxPage, setMaxpage] = useState(0);
  const [currentPage, setCurrentPage] = useState(queryParams.page || 0);

  const onTabClicked = useCallback((tab: FqaTabTypes) => {
    setTab(tab);
  }, []);

  const onAddBtnClicked = useCallback(() => {
    navigate('add');
  }, [navigate]);

  const onCategoryAddBtnClicked = useCallback(async () => {
    const { config } = await requestSecurePost(
      apiRoute.faq.category.addCategory,
      {},
      {
        name: category,
      },
      getAccessToken()!,
    );

    if (config.status === 201) {
      alertMessage('카테고리가 추가되었습니다');
    } else {
      alertMessage('카테고리 추가에 실패했습니다');
    }
  }, [category]);

  const loadList = useCallback(async () => {
    const { data, config } = await requestGet<
      BasicListDataType<FaqListItemTypes>
    >(apiRoute.faq.getFaqs + `?page=${currentPage}&perPage=${10}`, {});

    if (config.status === 200) {
      setFaqList(data.rows);
      setMaxpage(data.maxPage);
    }
  }, [currentPage]);

  const onListItemClicked = useCallback(
    (item: FaqListItemTypes) => {
      navigate('detail', {
        state: {
          item,
        },
      });
    },
    [navigate],
  );

  useEffect(() => {
    loadList();
  }, [loadList]);

  useEffect(() => {
    updateQueryParamsFromSession('', { page: currentPage });
  }, [currentPage]);

  return (
    <FAQList
      currentPage={currentPage}
      onAddBtnClicked={onAddBtnClicked}
      setCategory={setCategory}
      onCategoryAddBtnClicked={onCategoryAddBtnClicked}
      faqList={faqList}
      maxPage={maxPage}
      setCurrentPage={setCurrentPage}
      onPageChanged={setCurrentPage}
      onListItemClicked={onListItemClicked}
      tab={tab}
      onTabClicked={onTabClicked}
    />
  );
};

export default FAQLListContainer;
