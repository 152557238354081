import React from 'react';
import images from '../CSelect/assets/images';
import cselectImages from './assets/cselect.images';
import styles from './styles/cselect.module.css';

type Props = {
  selectedHour: string | null;
  onHourSelected: (item: string) => void;
  selectedMinutes: string | null;
  onMinutesSelected: (item: string) => void;
  show: boolean;
  nodeRef: React.RefObject<HTMLDivElement>;
  triggerRef: React.RefObject<HTMLDivElement>;
  isDisabled: boolean;
};

function CTimeSelect({
  selectedHour,
  selectedMinutes,
  show,
  nodeRef,
  triggerRef,
  onHourSelected,
  onMinutesSelected,
  isDisabled,
}: Props) {
  return (
    <div
      className={`${styles[`cselect-root`]} ${
        styles[isDisabled ? 'disabled' : '']
      }`}>
      <div
        ref={triggerRef}
        className={`${styles['cselect-input']} ${
          styles[show ? 'focus' : 'unfocus']
        } `}>
        <span className={styles[`cselect-label`]}>
          {selectedHour || selectedMinutes
            ? `${selectedHour} : ${selectedMinutes}`
            : '- : -'}
        </span>
        <div className={styles['cselect-under-arrow']}>
          <img src={images.underArrow} alt='cselect under arrow' />
        </div>
      </div>
      {show && (
        <div ref={nodeRef} className={`${styles[`cselect-option`]}  `}>
          <div className={styles['section']}>
            {new Array(24).fill(0).map((item, index) => (
              <div
                key={index}
                className={`${styles['cselect-option-item']} ${
                  styles[selectedHour === item ? 'selected' : '']
                }`}
                onClick={() =>
                  onHourSelected(index.toString().padStart(2, '0'))
                }>
                {`${index.toString().padStart(2, '0')} 시`}
              </div>
            ))}
          </div>
          <div className={styles['section']}>
            {['00', '30'].map((item, index) => (
              <div
                key={index}
                className={`${styles['cselect-option-item']} ${
                  styles[selectedMinutes === item ? 'selected' : '']
                }  `}
                onClick={() => onMinutesSelected(item)}>
                {`${item} 분`}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default CTimeSelect;
