import useToken from '@hooks/store/useToken';
import { alertMessage } from '@libs/alertMessage';
import { apiRoute, requestSecurePatch } from '@libs/api/api';
import { StoreListItemTypes } from '@typedef/components/SaleItem/sale.item.types';
import React from 'react';
import { useCallback } from 'react';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import SaleItemDetail from '../components/SaleItemDetail';

const SaleItemDetailContainer = () => {
  const navigate = useNavigate();
  const { getAccessToken } = useToken();
  const location = useLocation();
  const { store } = location.state as { store: StoreListItemTypes };

  const [discount, setDiscount] = useState(0);

  const onSaveBtnClicked = useCallback(async () => {
    const {
      data,
      config: { status },
    } = await requestSecurePatch<{}>(
      apiRoute.partners.updatePartner + store.partnersUid,
      {},
      { maxDiscountProductCount: discount },
      getAccessToken()!,
    );

    if (status !== 202) {
      alertMessage('수량 변경에 실패했습니다.');
      return;
    }

    console.log('변경 성공');

    navigate(-1);
  }, [store, discount]);

  const onBackBtnClicked = useCallback(() => {
    navigate(-1);
  }, []);

  return (
    <SaleItemDetail
      store={store}
      onSaveBtnClicked={onSaveBtnClicked}
      discount={discount}
      setDiscount={setDiscount}
      onBackBtnClicked={onBackBtnClicked}
    />
  );
};

export default SaleItemDetailContainer;
