import useToken from '@hooks/store/useToken';
import { useTab } from '@hooks/useTab';
import { alertMessage } from '@libs/alertMessage';
import {
  apiRoute,
  API_ORIGIN,
  BasicListDataType,
  requestGet,
  requestSecureGet,
} from '@libs/api/api';
import { CouponTypes } from '@typedef/components/Coupon/coupon.types';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import Coupon from '../Coupon';

const CouponContainer = () => {
  const navigate = useNavigate();
  const { queryParams, updateQueryParamsFromSession } = useTab<{
    page: number;
  }>('adminCoupon');
  const { getAccessToken } = useToken();
  const [couponList, setCouponList] = useState<CouponTypes[]>([]);
  const [page, setPage] = useState(queryParams.page || 0);
  const [maxPage, setMaxPage] = useState(0);
  const itemCnt = 10;

  const loadCouponList = useCallback(async () => {
    const {
      data,
      config: { status },
    } = await requestSecureGet<BasicListDataType<CouponTypes>>(
      apiRoute.coupon.getCoupons +
        `?page=${page}&perPage=${itemCnt}&isLately=true`,
      {},
      getAccessToken()!,
    );

    if (status !== 200) {
      alertMessage('목록을 불러오지 못했습니다');
    } else {
      setCouponList(data.rows);
      setMaxPage(data.maxPage);
    }
  }, [getAccessToken, page]);

  const onListItemClicked = useCallback(
    (item: CouponTypes) => {
      navigate('detail', {
        state: {
          item,
        },
      });
    },
    [navigate],
  );

  const onAddCouponClicked = useCallback(() => {
    navigate('add');
  }, [navigate]);

  useEffect(() => {
    loadCouponList();
  }, [loadCouponList]);

  useEffect(() => {
    updateQueryParamsFromSession('', { page });
  }, [page]);

  return (
    <Coupon
      page={page}
      couponList={couponList}
      onListItemClicked={onListItemClicked}
      onAddCouponClicked={onAddCouponClicked}
      maxPage={maxPage}
      setPage={setPage}
    />
  );
};

export default CouponContainer;
