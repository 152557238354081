import React from 'react';
import images from './assets/images';
import './styles/ccheckbox.styles.css';

type Props = {
  checked: boolean;
  onCheckClicked: () => void;
  checkType: 'square' | 'round';
  colorType: 'fill' | 'border';
  label: string;
  disabled: boolean;
};

const CCheckbox = ({
  checked,
  onCheckClicked,
  checkType,
  colorType,
  label,
  disabled,
}: Props) => {
  return (
    <div className='ccheckbox-root' onClick={onCheckClicked}>
      <div className={`ccheckbox-icon-container`}>
        {disabled ? (
          <div className={`ccheckbox-icon ${checkType} disabled`} />
        ) : (
          <div
            className={`ccheckbox-icon ${
              checked ? colorType : 'unchecked'
            } ${checkType}`}>
            {checked && (
              <img
                src={
                  colorType === 'fill' ? images.checkWhite : images.checkFocus
                }
                alt='checked image'
              />
            )}
          </div>
        )}
      </div>
      <div
        className={`ccheckbox-label ${checked && 'checked'} ${
          disabled && 'disabled'
        }`}>
        {label}
      </div>
    </div>
  );
};

export default CCheckbox;
