import CFileInputContainer from '@components/common/CFileInput/containers/CFileInputContainer';
import image from '@components/common/assets/image';
import CInputContainer from '@components/common/CInput/containers/CInputContainer';
import React from 'react';
import '../styles/main.banner.add.styles.css';
import MainBannerFileInput from './MainBannerFileInput';

type Props = {
  preview: string;
  title: string;
  desc: string;
  thumbnail: File | null;
  thumbnailImage: string;
  bannerFile: File | null;
  bannerFileImage: string;
  onCancelClicked: () => void;
  onAddBtnClicked: () => void;
  onTitleChanged: (value: string) => void;
  onDescChanged: (value: string) => void;
  onPreviewBtnClicked: (type: 'thumbnail' | 'banner') => void;
  onThumbnailChanged: (file: File) => void;
  onBannerFileChanged: (file: File) => void;
};

const MainBannerAdd = ({
  thumbnail,
  title,
  desc,
  bannerFile,
  preview,
  onBannerFileChanged,
  onThumbnailChanged,
  onDescChanged,
  onTitleChanged,
  onPreviewBtnClicked,
  onCancelClicked,
  onAddBtnClicked,
}: Props) => {
  return (
    <div className='main-banner-add-root content-root'>
      <div className='title-container'>
        <div className='title-item'>
          <div className='title-label'>{'메인배너생성'}</div>
          <div className='subtitle-label'>{'메인배너셍성 페이지 입니다'}</div>
        </div>
      </div>
      <div className='main-banner-add-button-container'>
        <button className='primary-btn' onClick={onCancelClicked}>
          돌아가기
        </button>
        <button className='primary-btn' onClick={onAddBtnClicked}>
          생성하기
        </button>
      </div>
      <div className='main-banner-add-input-container'>
        <div className='image-preview-section'>
          {preview ? (
            <img
              id='image-preview'
              className='image-placeholder'
              src={preview ?? ''}
              alt={'미리보기'}
            />
          ) : (
            <div className='image-placeholder' />
          )}
        </div>
        <div className='input-section'>
          <div className='input-item'>
            <label className='input-label' htmlFor='main-banner-detail-title'>
              {'제목'}
            </label>
            <input
              type='text'
              id='main-banner-detail-title'
              placeholder='제목을 입력해주세요'
              value={title}
              onChange={(e) => onTitleChanged(e.target.value)}
            />
          </div>
          <div className='input-item'>
            <label className='input-label' htmlFor='main-banner-detail-desc'>
              {'설명'}
            </label>
            <textarea
              id='main-banner-detail-desc'
              placeholder='설명을 입력해주세요'
              value={desc}
              onChange={(e) => onDescChanged(e.target.value)}
            />
          </div>
          <div className='file-input-item'>
            <MainBannerFileInput
              label='썸네일'
              onFileChanged={onThumbnailChanged}
              id='thumbnail'
              fileName={thumbnail?.name}
              onPreviewBtnClicked={() => onPreviewBtnClicked('thumbnail')}
            />
          </div>
          <div className='file-input-item'>
            <MainBannerFileInput
              label='상세페이지'
              onFileChanged={onBannerFileChanged}
              id='banner-file'
              fileName={bannerFile?.name}
              onPreviewBtnClicked={() => onPreviewBtnClicked('banner')}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainBannerAdd;
