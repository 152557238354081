import CInputContainer from '@components/common/CInput/containers/CInputContainer';
import {
  OptionTypes,
  ParsedOptionsTypes,
  ProductOptionsTypes,
} from '@typedef/components/Item/item.types';
import React from 'react';
import '../styles/option.popup.styles.css';

type Props = {
  idx: number;
  itemOption: ParsedOptionsTypes;
  setItemOption: React.Dispatch<React.SetStateAction<ParsedOptionsTypes>>;
  parsedOptions: ParsedOptionsTypes[];
  productOptions: ProductOptionsTypes[];
  setParsedOptions: React.Dispatch<React.SetStateAction<ParsedOptionsTypes[]>>;
  setProductOptions: React.Dispatch<
    React.SetStateAction<ProductOptionsTypes[]>
  >;
  onUpdateOptionItemClicked: () => void;
  onAddOptionItemClicked: () => void;
  onDeleteOptionItemClicked: (idx: number) => void;
};

const OptionPopup = ({
  idx,
  itemOption,
  setItemOption,
  parsedOptions,
  productOptions,
  setParsedOptions,
  setProductOptions,
  onUpdateOptionItemClicked,
  onAddOptionItemClicked,
  onDeleteOptionItemClicked,
}: Props) => {
  return (
    <div className='content-root option-popup-root'>
      <div className='table-container option'>
        <div className='title-wrapper'>
          <span className='table-title'>{'옵션 설정'}</span>
          <button
            className='primary-btn update-option'
            onClick={onUpdateOptionItemClicked}>
            {'적용하기'}
          </button>
        </div>
        <div className='body-section'>
          <span className='label'>{'옵션 종류'}</span>
          <input
            className='optName'
            placeholder='옵션 종류'
            id='option-popup-title-input'
            type='text'
            onChange={(e) => {
              setItemOption((prev) => {
                const clone = { ...prev };
                clone.optName = e.target.value;
                return clone;
              });
            }}
            value={itemOption.optName}
          />
          <span className='label'>{'옵션'}</span>
          {itemOption.opt.map((item, idx) => {
            return (
              <div className='opt-wrapper' key={idx}>
                <input
                  key={idx}
                  className='opt'
                  placeholder='옵션'
                  type={'text'}
                  id='option-popup-body-input'
                  onChange={(e) => {
                    if (e.target.value.includes('/')) {
                      alert('"/"는 사용이 불가합니다.');
                      return;
                    }
                    setItemOption((prev) => {
                      const clone = { ...prev };
                      clone.opt[idx] = e.target.value;
                      return clone;
                    });
                  }}
                  value={itemOption.opt[idx]}
                />
                <button
                  className='delete-opt-btn'
                  onClick={() => onDeleteOptionItemClicked(idx)}>
                  {'삭제'}
                </button>
              </div>
            );
          })}
          <button className='add-option-btn' onClick={onAddOptionItemClicked}>
            {'옵션 추가'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default OptionPopup;
