import useRootRoute, { ROOT_ROUTE_KEY } from '@hooks/routes/useRootRoute';
import useToken, {
  ACCESS_TOKEN_KEY,
  REFRESH_TOKEN_KEY,
} from '@hooks/store/useToken';
import { alertMessage } from '@libs/alertMessage';
import { AuthTypes } from '@typedef/components/common/auth.types';
import axios, { AxiosResponse } from 'axios';

export const apiRoute = {
  auth: {
    login: '/login-admin',
    refresh: '/refresh',
  },
  user: {
    getUser: '/users',
    detail: '/users/',
    updateUser: '/users/',
    getUserPaymentList: '/payments/users/',
    addPoint: '/points',
    getUserCouponList: '/coupons/downloads/users/',
    deleteUser: '/users/',
  },
  product: {
    getProducts: '/stores/products',
    getProductDetail: '/stores/products/',
    updateProductDetail: '/stores/products/',
    deleteProductDetail: '/stores/products/',
  },
  category: {
    getCategory: '/categories',
    addCategory: '/categories',
    updateCategory: '/categories/',
    deleteCategory: '/categories/',
    getSubCategory: '/categories/',
    addSubCategory: '/categories/',
    updateSubCategory: '/categories/details/',
    deleteSubCategory: '/categories/details/',
  },
  partners: {
    getPartners: '/partners',
    addPartners: '/partners',
    updatePartner: '/partners/',
    deletePartner: '/partners/',
    tags: {
      getTags: '/stores/tags',
      addTags: '/stores/tags',
    },
  },
  banners: {
    getBanners: '/banners',
    addBanners: '/banners',
    updateBanner: '/banners/',
    deleteBanner: '/banners/',
  },
  faq: {
    getFaqs: '/faqs',
    addFags: '/faqs',
    detail: '/faqs/',
    deleteFags: '/faqs/',
    updateFags: '/faqs/',
    category: {
      getCategory: '/faqs/categories',
      addCategory: '/faqs/categories',
    },
  },
  notice: {
    getNotice: '/notices',
    addNotice: '/notices',
    detail: '/notices/',
    updateNotice: '/notices/',
    deleteNotice: '/notices/',
  },
  events: {
    getEvents: '/events',
    addEvents: '/events',
    detail: '/events/',
    updateEvents: '/events/',
    deleteEvents: '/events/',
  },
  qrPoint: {
    check: '/points/qr/',
  },
  point: {
    getPoints: '/points',
    getPointAccumulation: '/points/',
    addPoint: '/points/points',
    updatePointAccumulation: '/points/points',
    qr: '/points/qr',
    payment: '/points/payment',
  },
  coupon: {
    getCoupons: '/coupons',
    addCoupon: '/coupons',
    getStoreCoupons: '/coupons/downloads/stores/',
    getUsersCoupons: '/coupons/downloads/users/',
    addStoreCoupons: '/coupons/stores/',
    editStoreCoupons: '/coupons/',
    editAdminCoupons: '/coupons/',
    deleteCoupons: '/coupons/',
    addUsersCoupons: '/coupons/users/',
  },
  payment: {
    getPaymentsList: '/payments',
    getPartnersPayments: '/payments/partners/',
    getPaymentDetail: '/payments/',
  },
  store: {
    getStoreList: '/stores',
    getDiscounts: '/stores/discounts',
    updateDiscounts: '/stores/discounts',
    updateTargetTag: '/stores/tags/',
    deleteTargetTag: '/stores/tags/',
    getUpdateStoreList: '/stores/updates',
    patchUpdateStore: '/stores/updates/',
  },
  settlement: {
    getSettlementList: '/settlements',
    getSettlementDetail: '/settlements/',
    updateSettlement: '/settlements/',
    updateDeliverySettlement: '/settlements/',
    updateRate: '/settlements',
    cancelOrder: '/orders/',
    getRate: '/settlements/rate',
  },
};

export const API_ORIGIN =
  process.env.NODE_ENV === 'development'
    ? 'https://api.incleapp.com'
    : 'https://api.incleapp.com';

axios.defaults.baseURL = `${API_ORIGIN}/api/v1`;

axios.interceptors.request.use((req) => {
  if (process.env.NODE_ENV === 'development') {
    console.log(req);
  }

  return req;
});

axios.interceptors.response.use(
  (res) => {
    if (process.env.NODE_ENV === 'development') {
      console.log(res);
    }
    return res;
  },
  async (err) => {
    const status = err?.response?.status;
    const config = err?.response?.config;

    if (process.env.NODE_ENV === 'development') {
      console.log(status, config);
    }

    if (
      config?.headers['Authorization'] &&
      config?.url !== apiRoute.auth.refresh &&
      (status === 403 || status === 401)
    ) {
      const refreshToken = sessionStorage.getItem(REFRESH_TOKEN_KEY);

      if (process.env.NODE_ENV === 'development') {
        console.log('interceptor :', refreshToken);
      }

      if (!refreshToken) {
        sessionStorage.removeItem(ACCESS_TOKEN_KEY);
        sessionStorage.removeItem(REFRESH_TOKEN_KEY);
        sessionStorage.removeItem(ROOT_ROUTE_KEY);
        window.location.reload();

        alertMessage('로그인이 만료 되었습니다');
        return Promise.reject(err);
      }

      const {
        data,
        config: { status },
      } = await requestSecurePost<AuthTypes>(
        apiRoute.auth.refresh,
        {},
        {},
        refreshToken,
      );

      if (status === 200) {
        sessionStorage.setItem(ACCESS_TOKEN_KEY, data.accessToken);
        sessionStorage.setItem(REFRESH_TOKEN_KEY, data.refreshToken);
        config.headers['Authorization'] = `Bearer ${data.accessToken}`;

        return axios(config);
      } else {
        sessionStorage.removeItem(ACCESS_TOKEN_KEY);
        sessionStorage.removeItem(REFRESH_TOKEN_KEY);
        sessionStorage.removeItem(ROOT_ROUTE_KEY);
        window.location.reload();

        alertMessage('로그인이 만료 되었습니다');
      }
    }
    return Promise.reject(err);
  },
  {
    synchronous: true,
  },
);

export type BasicApiResponse<T> = {
  data: T;
  config: {
    status: number;
  };
};

export type BasicListDataType<T> = {
  rows: T[];
  page: number;
  maxPage: number;
  meta: {
    page: number;
    perPage: number;
    maxCount: number;
    maxPage: number;
  };
};

export function responseHandler<T>(res: AxiosResponse<any, any>) {
  return {
    data: res.data as T,
    config: {
      status: res.status,
      ...res.data?.meta,
    },
  } as BasicApiResponse<T>;
}

export function responseErrorHandler<T>(err: any) {
  console.error('[Axios Error]', err.response);
  const status = err?.response?.status || -1;
  const resMessage = err?.response?.data?.message;

  return {
    data: {} as T,
    config: {
      status: status,
      message: resMessage,
    },
  } as BasicApiResponse<T>;
}

export function requestGet<T>(
  url: string,
  header: object,
): Promise<BasicApiResponse<T>> {
  return axios
    .get(url, {
      headers: {
        'Content-Type': 'application/json',
        ...header,
      },
    })
    .then((res) => responseHandler<T>(res))
    .catch((err) => responseErrorHandler(err));
}

export function requestSecureGet<T>(
  url: string,
  header: object,
  token: string,
): Promise<BasicApiResponse<T>> {
  return axios
    .get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        ...header,
      },
    })
    .then((res) => responseHandler<T>(res))
    .catch((err) => responseErrorHandler(err));
}

export function requestDelete<T>(
  url: string,
  header: object,
): Promise<BasicApiResponse<T>> {
  return axios
    .delete(url, {
      headers: {
        'Content-Type': 'application/json',
        ...header,
      },
    })
    .then((res) => responseHandler<T>(res))
    .catch((err) => responseErrorHandler(err));
}

export function requestSecureDelete<T>(
  url: string,
  header: object,
  token: string,
): Promise<BasicApiResponse<T>> {
  return axios
    .delete(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        ...header,
      },
    })
    .then((res) => responseHandler<T>(res))
    .catch((err) => responseErrorHandler(err));
}

export function requestSecurePost<T>(
  url: string,
  header: object,
  body: object,
  token: string,
): Promise<BasicApiResponse<T>> {
  return axios
    .post(url, body, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        ...header,
      },
    })
    .then((res) => responseHandler<T>(res))
    .catch((err) => responseErrorHandler(err));
}

export function requestPost<T>(
  url: string,
  header: object,
  body: object,
): Promise<BasicApiResponse<T>> {
  return axios
    .post(url, body, {
      headers: {
        'Content-Type': 'application/json',
        ...header,
      },
    })
    .then((res) => responseHandler<T>(res))
    .catch((err) => responseErrorHandler(err));
}

export function requestSecurePatch<T>(
  url: string,
  header: object,
  body: object,
  token: string,
): Promise<BasicApiResponse<T>> {
  return axios
    .patch(url, body, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        ...header,
      },
    })
    .then((res) => responseHandler<T>(res))
    .catch((err) => responseErrorHandler(err));
}

export function requestMultipartPost<T>(
  url: string,
  header: object,
  body: FormData,
  token: string,
): Promise<BasicApiResponse<T>> {
  return axios
    .post(url, body, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
        ...header,
      },
    })
    .then((res) => responseHandler<T>(res))
    .catch((err) => responseErrorHandler(err));
}

export function requestMultipartPatch<T>(
  url: string,
  header: object,
  body: FormData,
  token: string,
): Promise<BasicApiResponse<T>> {
  return axios
    .patch(url, body, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
        ...header,
      },
    })
    .then((res) => responseHandler<T>(res))
    .catch((err) => responseErrorHandler(err));
}
