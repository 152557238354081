import useToken from '@hooks/store/useToken';
import { alertMessage } from '@libs/alertMessage';
import {
  apiRoute,
  BasicApiResponse,
  requestSecureDelete,
  requestSecurePatch,
} from '@libs/api/api';
import { getEndOfDate } from '@libs/factory';
import { CouponTypes } from '@typedef/components/Coupon/coupon.types';
import React from 'react';
import { useCallback } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import CouponDetail from '../components/CouponDetail';

const CouponDetailContainer = () => {
  const navigate = useNavigate();
  const { getAccessToken } = useToken();
  const { item } = useLocation().state as { item: CouponTypes };

  const [name, setName] = useState('');
  const [amount, setAmount] = useState(0);
  const [condition, setCondition] = useState(0);
  const [limitDate, setLimitDate] = useState<string | null>(null);
  const [isInfinite, setIsInfinite] = useState(false);

  const onEditButtonClicked = useCallback(async () => {
    if (!isInfinite && !limitDate) {
      alertMessage('기간을 입력해주세요');
      return;
    }

    const {
      data,
      config: { status },
    } = await requestSecurePatch<BasicApiResponse<{}>>(
      apiRoute.coupon.editAdminCoupons + item.couponUid,
      {},
      {
        amount: amount,
        condition: condition,
        name,
        limitDate: isInfinite ? 0 : getEndOfDate(limitDate!),
      },
      getAccessToken()!,
    );

    if (status !== 202) {
      alertMessage('쿠폰 수정에 실패했습니다.');
      return;
    }

    navigate(-1);
  }, [
    limitDate,
    name,
    amount,
    condition,
    isInfinite,
    getAccessToken,
    navigate,
    item,
  ]);

  const onDeleteButtonClicked = useCallback(async () => {
    const {
      data,
      config: { status },
    } = await requestSecureDelete(
      apiRoute.coupon.deleteCoupons + item.couponUid,
      {},
      getAccessToken()!,
    );

    if (status !== 202) return;

    navigate(-1);
  }, [navigate, item]);

  return (
    <CouponDetail
      name={name}
      setName={setName}
      amount={amount}
      setAmount={setAmount}
      condition={condition}
      setCondition={setCondition}
      limitDate={limitDate}
      setLimitDate={setLimitDate}
      isInfinite={isInfinite}
      setIsInfinite={setIsInfinite}
      coupon={item}
      onDeleteButtonClicked={onDeleteButtonClicked}
      onEditButtonClicked={onEditButtonClicked}
    />
  );
};

export default CouponDetailContainer;
