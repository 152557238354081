import CMultiFileInputContainer from '@components/common/CFileInput/containers/CMultiFileInputContainer';
import CDateInputContainer from '@components/common/CInput/containers/CDateInputContainer';
import CInputContainer from '@components/common/CInput/containers/CInputContainer';
import { EventListItemTypes } from '@typedef/components/NoticeEvent/notice.event.types';
import React from 'react';
import '../styles/event.detail.styles.css';
import EventFileInput from './EventFileInput';

type Props = {
  startDate: string;
  endDate: string;
  eventFile: File | null;
  eventFileUrl: string;
  referenceUrl: string;
  setStartDate: React.Dispatch<React.SetStateAction<string>>;
  setEndDate: React.Dispatch<React.SetStateAction<string>>;
  onUpdateBtnClicked: () => void;
  onDeleteBtnClicked: () => void;
  onEventFileChanged: (file: File) => void;
  setEventFile: React.Dispatch<React.SetStateAction<File | null>>;
  setReferenceUrl: React.Dispatch<React.SetStateAction<string>>;
};

const EventDetail = ({
  startDate,
  endDate,
  eventFile,
  eventFileUrl,
  referenceUrl,
  setStartDate,
  setEndDate,
  setEventFile,
  setReferenceUrl,
  onEventFileChanged,
  onDeleteBtnClicked,
  onUpdateBtnClicked,
}: Props) => {
  return (
    <div className='event-root content-root'>
      <div className='title-container'>
        <div className='title-item'>
          <div className='title-label'>{'이벤트 상세'}</div>
          <div className='subtitle-label'>{'이벤트 상세 페이지 입니다'}</div>
        </div>
      </div>
      <div className='button-container'>
        <button className='delete-btn' onClick={onDeleteBtnClicked}>
          삭제하기
        </button>
        <button className='primary-btn' onClick={onUpdateBtnClicked}>
          저장하기
        </button>
      </div>
      <div className='event-input-container'>
        <div className='image-preview-section'>
          {eventFileUrl ? (
            <img
              id='image-preview'
              className='image-placeholder'
              src={eventFileUrl}
              alt={'이벤트 이미지 미리보기'}
            />
          ) : (
            <div className='image-placeholder' />
          )}
        </div>
        <div className='input-section'>
          <div className='input-item'>
            <label className='input-label' htmlFor='main-banner-detail-title'>
              {'이벤트 URL'}
            </label>
            <input
              type='text'
              id='main-banner-detail-title'
              placeholder='URL을 입력해주세요'
              value={referenceUrl}
              onChange={(e) => setReferenceUrl(e.target.value)}
            />
          </div>
          <div className='input-item'>
            <label className='input-label' htmlFor='main-banner-detail-desc'>
              {'게시 기간'}
            </label>
            <div className='date-container'>
              <input
                type='date'
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
              <div>{' ~ '}</div>
              <input
                type='date'
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </div>
          </div>
          <div className='file-input-item'>
            <EventFileInput
              label='이벤트 파일'
              onFileChanged={onEventFileChanged}
              id='event-file'
              fileName={eventFile?.name}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventDetail;
