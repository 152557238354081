import useToken from '@hooks/store/useToken';
import { alertMessage } from '@libs/alertMessage';
import { apiRoute, requestGet, requestSecurePost } from '@libs/api/api';
import { FaqCategoryListItemTypes } from '@typedef/components/FAQ/faq.types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { RefObject } from 'react';
import { useNavigate } from 'react-router-dom';
import FAQAdd from '../components/FAQAdd';

const FAQAddContainer = () => {
  const { getAccessToken } = useToken();
  const navigate = useNavigate();

  const [categoryOption, setCategoryOption] = useState<
    FaqCategoryListItemTypes[]
  >([]);

  const [category, setCategory] = useState<FaqCategoryListItemTypes | null>(
    null,
  );
  const [title, setTitle] = useState('');
  const [files, setFiles] = useState<File[]>([]);
  const [content, setContent] = useState('');

  const onAddBtnClicked = useCallback(async () => {
    const form = new FormData();

    if (category) {
      form.append('faqCategoryUid', category.faqCategoryUid + '');
    }

    form.append('title', title);
    form.append('contents', content);

    files.forEach((item) => {
      form.append('faqFile', item);
    });

    const { config } = await requestSecurePost(
      apiRoute.faq.addFags,
      {
        'Content-Type': 'multipart/form-data',
      },
      form,
      getAccessToken()!,
    );

    if (config.status === 201) {
      navigate(-1);
    } else {
      alertMessage('FAQ 등록에 실패 했습니다');
    }
  }, [category, title, files, content, navigate]);

  const onBackBtnClicked = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const loadCategories = useCallback(async () => {
    const { data, config } = await requestGet<FaqCategoryListItemTypes[]>(
      apiRoute.faq.category.getCategory,
      {},
    );

    if (config.status === 200) {
      setCategoryOption(data);
    } else {
      alertMessage('카테고리를 불러오지 못했습니다');
    }
  }, []);

  useEffect(() => {
    loadCategories();
  }, [loadCategories]);

  return (
    <FAQAdd
      content={content}
      categoryOption={categoryOption}
      onAddBtnClicked={onAddBtnClicked}
      onBackBtnClicked={onBackBtnClicked}
      setFiles={setFiles}
      setTitle={setTitle}
      setCategory={setCategory}
      setContent={setContent}
    />
  );
};

export default FAQAddContainer;
