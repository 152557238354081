import React, { RefObject } from 'react';
import './styles/cfile.input.styles.css';

type Props = {
  inputRef: RefObject<HTMLInputElement>;
  onFileSelected: (file: File) => void;
  onFileSelectClicked: () => void;
  selectedFile: File | null;
  label: string;
};

const CFileInput = ({
  inputRef,
  onFileSelected,
  onFileSelectClicked,
  selectedFile,
  label,
}: Props) => {
  return (
    <div className='cfile-input-root'>
      <input
        ref={inputRef}
        type='file'
        hidden
        onChange={(e) => {
          const selected = e.target.files?.item(0);

          if (selected) {
            onFileSelected(selected);
          }
        }}
      />
      <div className='cfile-label-container'>
        <div className='cfile-label'>{label}</div>
      </div>
      <div className='cfile-select-container'>
        <div className='cfile-input-file-name'>
          {selectedFile?.name || '파일을 선택해주세요'}
        </div>
        <button
          type='button'
          className='primary-btn select-btn'
          onClick={onFileSelectClicked}>
          선택
        </button>
      </div>
    </div>
  );
};

export default CFileInput;
