import image from '@components/common/assets/image';
import CInputContainer from '@components/common/CInput/containers/CInputContainer';

import CPagingContainer from '@components/common/CPaging/containers/CPagingContainer';
import CSearchContainer from '@components/common/CSearch/containers/CSearchContainer';
import { checkIncludeNotNumber } from '@libs/validation';
import { StoreListItemTypes } from '@typedef/components/SaleItem/sale.item.types';
import React from 'react';
import './styles/sale.item.styles.css';

type Props = {
  storeList: StoreListItemTypes[];
  page: number;
  discount: number;
  maxPage: number;
  searchInput: string;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  onSearchInputChanged: (value: string) => void;
  onSearchBtnClicked: () => void;
  setDiscount: React.Dispatch<React.SetStateAction<number>>;
  onSaveClicked: () => void;
  onItemClicked: (idx: number) => void;
};

const SaleItem = ({
  storeList,
  page,
  maxPage,
  discount,
  searchInput,
  setPage,
  onSearchInputChanged,
  onSearchBtnClicked,
  setDiscount,
  onSaveClicked,
  onItemClicked,
}: Props) => {
  return (
    <div className='content-root sale-item-root'>
      <div className='title-container'>
        <div className='title-item'>
          <div className='title-label'>{'특가 상품 관리'}</div>
          <div className='subtitle-label'>{'특가 상품 관리 페이지 입니다'}</div>
        </div>
      </div>
      <div className='table-container'>
        <div className='search-section'>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              onSearchBtnClicked();
            }}>
            <div className='input-container'>
              <label
                htmlFor='input-sale-item-search'
                className='input-label'
                hidden>
                {'검색'}
              </label>
              <input
                className='input-box search'
                type='text'
                value={searchInput}
                onChange={(e) => onSearchInputChanged(e.target.value)}
                id='input-sale-item-search'
                placeholder=' 가게명으로 검색해주세요'
              />
              <button className='input-button'>
                <img src={image.searchBtn} alt='search-btn' />
              </button>
            </div>
          </form>
          <div className='setting-section'>
            <label htmlFor='sale-item-size-input'>
              특가상품 수량 일괄 설정
            </label>
            <div className='input-container'>
              <input
                type='text'
                value={discount || ''}
                id='sale-item-size-input'
                onChange={(e) => {
                  if (/[^0-9]/.test(e.target.value)) return;
                  setDiscount(Number(e.target.value));
                }}
              />
              <span>{'개'}</span>
            </div>
            <button className='primary-btn' onClick={onSaveClicked}>
              저장하기
            </button>
          </div>
        </div>
        <div className='header-section'>
          <div className='item'>가게사진</div>
          <div className='item'>가게명</div>
          <div className='item'>주소</div>
          <div className='item'>특가 상품 수량</div>
          <div className='item'> 특가 상품 최대 수량</div>
        </div>
        <div className='body-section'>
          {storeList.map((item, idx) => {
            return (
              <div
                className='row'
                key={idx}
                onClick={() => {
                  onItemClicked(idx);
                }}>
                <div className='item'>
                  <img
                    className='image'
                    src={
                      item.storeProfile
                        ? item.storeProfile[0]
                        : image.placeholderImg
                    }
                    alt='상품사진'
                    onError={(e) => {
                      e.currentTarget.src = image.placeholderImg;
                    }}
                  />
                </div>
                <div className='item'>{item.name}</div>
                <div className='item'>{item.location}</div>
                <div className='item'>{item.discountedProductsCount}</div>
                <div className='item'>{item.maxDiscountProductCount}</div>
              </div>
            );
          })}
        </div>
        <div className='footer-section'>
          <CPagingContainer maxPage={maxPage} page={page} setPage={setPage} />
        </div>
      </div>
    </div>
  );
};

export default SaleItem;
