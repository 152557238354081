import useToken from '@hooks/store/useToken';
import { alertMessage } from '@libs/alertMessage';
import {
  apiRoute,
  BasicListDataType,
  requestSecureDelete,
  requestSecureGet,
  requestSecurePatch,
  requestSecurePost,
} from '@libs/api/api';
import {
  CategoryListItemTypes,
  EditCategoryItemTypes,
  SubCategoryListItemTypes,
} from '@typedef/components/Category/category.types';
import React, { useCallback, useEffect, useState } from 'react';
import Category from '../Category';

const CategoryContainer = () => {
  const { getAccessToken } = useToken();

  const [selectedCategory, setSelectedCategory] =
    useState<CategoryListItemTypes | null>(null);

  const [categoryList, setCategoryList] = useState<CategoryListItemTypes[]>([]);

  const [tmpCategory, setTmpCategory] = useState('');

  const [subCategoryList, setSubCategoryList] = useState<
    SubCategoryListItemTypes[]
  >([]);

  const [tmpSubCategory, setTmpSubCategory] = useState('');
  const [editItem, setEditItem] = useState<EditCategoryItemTypes>({
    type: 'main',
    index: -1,
    uid: '',
  });

  const [editInput, setEditInput] = useState('');

  const loadList = useCallback(async () => {
    const { data, config } = await requestSecureGet<
      BasicListDataType<CategoryListItemTypes>
    >(apiRoute.category.getCategory, {}, getAccessToken()!);

    if (config.status === 200) {
      setSelectedCategory(data.rows[0]);
      setCategoryList(data.rows);
    } else {
      alertMessage('목록을 불러오지 못했습니다');
    }
  }, []);

  const onCategoryUpClicked = useCallback(
    async (item: CategoryListItemTypes, idx: number) => {
      const { config } = await requestSecurePatch(
        apiRoute.category.updateCategory,
        {},
        {
          productCategoryUidA: item.productCategoryUid,
          productCategoryUidB: categoryList[idx - 1].productCategoryUid,
        },
        getAccessToken()!,
      );

      if (config.status === 202) {
        setCategoryList((prev) => {
          const clone = [...prev];

          clone[idx] = clone[idx - 1];
          clone[idx - 1] = item;

          return clone;
        });
      } else {
        alertMessage('순서 변경에 실패했습니다');
      }
    },
    [categoryList],
  );

  const onCategoryDownClicked = useCallback(
    async (item: CategoryListItemTypes, idx: number) => {
      const { config } = await requestSecurePatch(
        apiRoute.category.updateCategory,
        {},
        {
          productCategoryUidA: item.productCategoryUid,
          productCategoryUidB: categoryList[idx + 1].productCategoryUid,
        },
        getAccessToken()!,
      );

      if (config.status === 202) {
        setCategoryList((prev) => {
          const clone = [...prev];

          clone[idx] = clone[idx + 1];
          clone[idx + 1] = item;

          return clone;
        });
      } else {
        alertMessage('순서 변경에 실패했습니다');
      }
    },
    [categoryList],
  );

  const onEditBtnClicked = useCallback(
    (type: 'main' | 'sub', index: number, uid: string) => {
      setEditItem({ type, index, uid });
      if (type === 'main') setEditInput(categoryList[index].name);
      else setEditInput(subCategoryList[index].name);
    },
    [categoryList, subCategoryList],
  );

  const onEditDoneBtnClicked = useCallback(async () => {
    const url = `${
      editItem.type === 'main'
        ? apiRoute.category.updateCategory
        : apiRoute.category.updateSubCategory
    }${editItem.uid}`;

    const { data, config } = await requestSecurePatch<CategoryListItemTypes>(
      url,
      {},
      {
        name: editInput,
      },
      getAccessToken()!,
    );

    if (config.status !== 202) {
      alertMessage('수정에 실패했습니다');
      setEditItem({ type: 'main', index: -1, uid: '' });
      setEditInput('');
      return;
    }

    if (editItem.type === 'main') {
      setCategoryList((prev) => {
        const clone = [...prev];
        clone[editItem.index].name = editInput;
        return clone;
      });
    } else {
      setSubCategoryList((prev) => {
        const clone = [...prev];
        clone[editItem.index].name = editInput;
        return clone;
      });
    }
    setEditItem({ type: 'main', index: -1, uid: '' });
    setEditInput('');
  }, [editItem, editInput, getAccessToken]);

  const onCategoryAddBtnClicked = useCallback(async () => {
    const { data, config } = await requestSecurePost<CategoryListItemTypes>(
      apiRoute.category.addCategory,
      {},
      {
        name: tmpCategory,
        detailCategoryName: '임시중분류(수정필수)',
      },
      getAccessToken()!,
    );

    if (config.status === 201) {
      setCategoryList((prev) => [...prev, data]);
    } else {
      alertMessage('대분류 추가에 실패했습니다');
    }
  }, [tmpCategory]);

  const onCategoryDeleteBtnClicked = useCallback(
    async (item: CategoryListItemTypes, idx: number) => {
      if (!window.confirm(`${item.name}을 삭제하시겠습니까?`)) {
        return;
      }

      const { config } = await requestSecureDelete(
        apiRoute.category.deleteCategory + item.productCategoryUid,
        {},
        getAccessToken()!,
      );

      if (config.status === 202) {
        setCategoryList((prev) => {
          const clone = [...prev];

          clone.splice(idx, 1);

          return clone;
        });
      } else {
        alertMessage(item.name + ' 삭제에 실패했습니다');
      }
    },
    [],
  );

  const loadSubList = useCallback(async () => {
    if (!selectedCategory) {
      return;
    }

    const { data, config } = await requestSecureGet<
      BasicListDataType<SubCategoryListItemTypes>
    >(
      apiRoute.category.getSubCategory + selectedCategory.productCategoryUid,
      {},
      getAccessToken()!,
    );

    if (config.status === 200) {
      setSubCategoryList(data.rows);
    } else {
      alertMessage('목록을 불러오지 못했습니다');
    }
  }, [selectedCategory]);

  const onSubCategoryAddBtnClicked = useCallback(async () => {
    const { data, config } = await requestSecurePost<SubCategoryListItemTypes>(
      apiRoute.category.addSubCategory + selectedCategory!.productCategoryUid,
      {},
      {
        name: tmpSubCategory,
      },
      getAccessToken()!,
    );

    if (config.status === 201) {
      setSubCategoryList((prev) => [...prev, data]);
    } else {
      alertMessage('중분류 추가에 실패했습니다');
    }
  }, [selectedCategory, tmpSubCategory]);

  const onSubCategoryUpClicked = useCallback(
    async (item: SubCategoryListItemTypes, idx: number) => {
      const { config } = await requestSecurePatch(
        apiRoute.category.updateSubCategory,
        {},
        {
          productCategoryDetailUidA: item.productCategoryDetailUid,
          productCategoryDetailUidB:
            subCategoryList[idx - 1].productCategoryDetailUid,
        },
        getAccessToken()!,
      );

      if (config.status === 202) {
        setSubCategoryList((prev) => {
          const clone = [...prev];

          clone[idx] = clone[idx - 1];
          clone[idx - 1] = item;

          return clone;
        });
      } else {
        alertMessage('순서 변경에 실패 했습니다');
      }
    },
    [subCategoryList],
  );

  const onSubCategoryDownClicked = useCallback(
    async (item: SubCategoryListItemTypes, idx: number) => {
      const { config } = await requestSecurePatch(
        apiRoute.category.updateSubCategory,
        {},
        {
          productCategoryDetailUidA: item.productCategoryDetailUid,
          productCategoryDetailUidB:
            subCategoryList[idx + 1].productCategoryDetailUid,
        },
        getAccessToken()!,
      );

      if (config.status === 202) {
        setSubCategoryList((prev) => {
          const clone = [...prev];

          clone[idx] = clone[idx + 1];
          clone[idx + 1] = item;

          return clone;
        });
      } else {
        alertMessage('순서 변경에 실패 했습니다');
      }
    },
    [subCategoryList],
  );

  const onSubCategoryDeleteClicked = useCallback(
    async (item: SubCategoryListItemTypes, idx: number) => {
      if (!window.confirm(`${item.name}을 삭제하시겠습니까?`)) {
        return;
      }

      const { config } = await requestSecureDelete(
        apiRoute.category.deleteSubCategory + item.productCategoryDetailUid,
        {},
        getAccessToken()!,
      );

      if (config.status === 202) {
        setSubCategoryList((prev) => {
          const clone = [...prev];

          clone.splice(idx, 1);

          return clone;
        });
      } else {
        alertMessage(item.name + ' 삭제에 실패했습니다');
      }
    },
    [],
  );

  useEffect(() => {
    loadList();
  }, [loadList]);

  useEffect(() => {
    loadSubList();
  }, [loadSubList]);

  return (
    <Category
      categoryList={categoryList}
      subCategoryList={subCategoryList}
      editItem={editItem}
      editInput={editInput}
      setEditItem={setEditItem}
      setEditInput={setEditInput}
      setTmpCategory={setTmpCategory}
      setTmpSubCategory={setTmpSubCategory}
      onCategoryAddBtnClicked={onCategoryAddBtnClicked}
      onSubCategoryAddBtnClicked={onSubCategoryAddBtnClicked}
      setSelectedCategory={setSelectedCategory}
      onCategoryDeleteBtnClicked={onCategoryDeleteBtnClicked}
      onSubCategoryDeleteClicked={onSubCategoryDeleteClicked}
      onCategoryUpClicked={onCategoryUpClicked}
      onCategoryDownClicked={onCategoryDownClicked}
      onSubCategoryUpClicked={onSubCategoryUpClicked}
      onSubCategoryDownClicked={onSubCategoryDownClicked}
      onEditBtnClicked={onEditBtnClicked}
      onEditDoneBtnClicked={onEditDoneBtnClicked}
    />
  );
};

export default CategoryContainer;
