import { useTab } from '@hooks/useTab';
import {} from '@libs/api/api';
import { FqaTabTypes } from '@typedef/components/FAQ/faq.types';
import React, { useCallback, useEffect, useState } from 'react';
import FAQ from '../FAQ';

const FAQContainer = () => {
  const { tab: tabItem } = useTab<{}>('faq');

  const [tab, setTab] = useState<FqaTabTypes>(
    (tabItem as FqaTabTypes) || 'faq',
  );

  const onTabClicked = useCallback((tab: FqaTabTypes) => {
    setTab(tab);
  }, []);

  return <FAQ tab={tab} onTabClicked={onTabClicked} />;
};

export default FAQContainer;
