import CMultiFileInputContainer from '@components/common/CFileInput/containers/CMultiFileInputContainer';
import CInputContainer from '@components/common/CInput/containers/CInputContainer';
import CSelectContainer from '@components/common/CSelect/containers/CSelectContainer';
import { FaqCategoryListItemTypes } from '@typedef/components/FAQ/faq.types';
import React from 'react';
import '../styles/faq.detail.styles.css';

type Props = {
  categoryOption: FaqCategoryListItemTypes[];
  onAddBtnClicked: () => void;
  onBackBtnClicked: () => void;
  setCategory: React.Dispatch<
    React.SetStateAction<FaqCategoryListItemTypes | null>
  >;
  setFiles: React.Dispatch<React.SetStateAction<File[]>>;
  setTitle: React.Dispatch<React.SetStateAction<string>>;
  setContent: React.Dispatch<React.SetStateAction<string>>;
  content: string;
};

const FAQAdd = ({
  categoryOption,
  onAddBtnClicked,
  onBackBtnClicked,
  setCategory,
  setFiles,
  setTitle,
  setContent,
  content,
}: Props) => {
  return (
    <div className='faq-root content-root'>
      <div className='title-container'>
        <div className='title-item'>
          <div className='title-label'>{'FAQ 추가'}</div>
          <div className='subtitle-label'>{'FAQ 추가 페이지 입니다'}</div>
        </div>
      </div>
      <div className='button-container'>
        <button className='primary-btn' onClick={onBackBtnClicked}>
          뒤로가기
        </button>
        <button className='primary-btn' onClick={onAddBtnClicked}>
          게시하기
        </button>
      </div>
      <div className='table-container'>
        <div className='body-section'>
          <div className='row'>
            <div className='item'>
              <CInputContainer
                containerStyle={{
                  width: '100%',
                }}
                containerType={{
                  type: 'outline',
                  adaptive: true,
                }}
                type='text'
                inputId='faq-add-title-input'
                onInputChanged={setTitle}
                label='제목'
                placeholder='제목을 입력해주세요'
              />
            </div>
            <div>
              <CSelectContainer
                options={categoryOption}
                onSelected={setCategory}
                placeholder='카테고리를 선택해주세요'
                labelSelector={(item) => item.name}
              />
            </div>
          </div>
          <div>
            <CMultiFileInputContainer onSelectdFilesChanged={setFiles} />
          </div>
          <div className='row content'>
            <textarea
              name='content'
              id='event-detail-content'
              cols={30}
              rows={10}
              value={content}
              onChange={(e) => setContent(e.target.value)}
              placeholder='내용을 입력해주세요'
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQAdd;
