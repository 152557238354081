import useToken from '@hooks/store/useToken';
import { alertMessage } from '@libs/alertMessage';
import {
  apiRoute,
  requestSecureGet,
  requestSecurePatch,
  requestSecurePost,
} from '@libs/api/api';
import { settlementCalc } from '@libs/factory';
import { UpdateSettlementOrderResponseTypes } from '@typedef/components/Calc/calc.types';
import {
  DeliveryFeeObjTypes,
  OrderListItemTypes,
  PaymentDetailItemTypes,
  PaymentListItemTypes,
} from '@typedef/components/Order/order.types';
import React from 'react';
import { useCallback } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import CalcDetail from '../components/CalcDetail';

const CalcDetailContainer = () => {
  const { getAccessToken } = useToken();
  const { paymentUid } = useLocation().state as {
    paymentUid: number;
  };

  const [settlementDetail, setSettlementDetail] =
    useState<PaymentListItemTypes>();
  const [settlementDetailObj, setSettlementDetailObj] =
    useState<PaymentDetailItemTypes>();

  const [deliveryFeeObj, setDeliveryFeeObj] = useState<DeliveryFeeObjTypes>();

  const [orders, setOrders] = useState<OrderListItemTypes[]>([]);

  const onSettlementDetailLoad = useCallback(async () => {
    const {
      data,
      config: { status },
    } = await requestSecureGet<PaymentListItemTypes>(
      apiRoute.settlement.getSettlementDetail + paymentUid,
      {},
      getAccessToken()!,
    );

    if (status !== 200) {
      alertMessage('정보를 불러오지 못했습니다.');
      return;
    }

    const detailTmpObj: PaymentDetailItemTypes = {};
    const deliveryTmpObj: DeliveryFeeObjTypes = {};
    data.orders.forEach((item) => {
      detailTmpObj[item.store.storeUid] = [];
    });

    data.orders.map((item) => {
      detailTmpObj[item.store.storeUid].push(item);
    });

    data.deliveryFees?.forEach((item) => {
      deliveryTmpObj[item.storeUid] = item;
    });

    setSettlementDetailObj(detailTmpObj);
    setDeliveryFeeObj(deliveryTmpObj);
    setSettlementDetail(data);
    setOrders(data.orders);
  }, [paymentUid]);

  const onSettlementBtnClicked = useCallback(
    async (item: OrderListItemTypes, idx: number) => {
      if (!settlementDetailObj || !settlementDetail) return;

      const price =
        item.amount +
        item.pointDiscount +
        (item.coupon?.storeUid === null ? item.couponDiscount : 0);

      if (
        !window.confirm(
          `${settlementCalc(
            price,
            settlementDetail.commissionRate,
          )}원을 정산하시겠습니까?`,
        )
      ) {
        return;
      }

      const {
        data,
        config: { status },
      } = await requestSecurePost<UpdateSettlementOrderResponseTypes>(
        apiRoute.settlement.updateSettlement + item.orderUid,
        {},
        {},
        getAccessToken()!,
      );

      if (status !== 202) {
        alertMessage('정산요청에 실패했습니다.');
        return;
      }

      setSettlementDetailObj((prev) => {
        const clone = { ...prev };
        clone[item.store.storeUid][idx].settlementAmount = settlementCalc(
          price,
          settlementDetail.commissionRate,
        );
        return clone;
      });
    },
    [settlementDetailObj, settlementDetail, getAccessToken],
  );

  const onDeliverySettlementBtnClicked = useCallback(
    async (storeUid: number, orderUid: number) => {
      if (!deliveryFeeObj || !settlementDetail) return;

      const price = deliveryFeeObj[storeUid].amount;

      if (
        !window.confirm(
          `${settlementCalc(
            price,
            settlementDetail.commissionRate,
          )}원을 정산하시겠습니까?`,
        )
      ) {
        return;
      }

      const {
        data,
        config: { status },
      } = await requestSecurePost<UpdateSettlementOrderResponseTypes>(
        `${apiRoute.settlement.updateDeliverySettlement}${orderUid}/delivery`,
        {},
        {},
        getAccessToken()!,
      );

      if (status !== 202) {
        alertMessage('정산요청에 실패했습니다.');
        return;
      }

      setDeliveryFeeObj((prev) => {
        const clone = { ...prev };
        clone[storeUid].settlementAmount = settlementCalc(
          price,
          settlementDetail.commissionRate,
        );
        return clone;
      });
    },
    [deliveryFeeObj, getAccessToken, settlementDetail],
  );

  const onCancelBtnClicked = useCallback(
    async (item: OrderListItemTypes, idx: number) => {
      if (
        !window.confirm(
          `${item.amount.toLocaleString()}원 결제 취소하시겠습니까?`,
        )
      ) {
        return;
      }

      const {
        data,
        config: { status },
      } = await requestSecurePatch<{}>(
        apiRoute.settlement.cancelOrder + `${item.orderUid}/cancels`,
        {},
        { orderStatus: 'CANCEL_COMPLETE' },
        getAccessToken()!,
      );

      if (status !== 202) {
        alertMessage('주문취소에 실패했습니다.');
        return;
      }

      setSettlementDetailObj((prev) => {
        const clone = { ...prev };
        clone[item.store.storeUid][idx].orderStatus = '취소완료';
        return clone;
      });
    },
    [orders, getAccessToken],
  );

  useEffect(() => {
    onSettlementDetailLoad();
  }, [onSettlementDetailLoad]);

  return settlementDetail && settlementDetailObj ? (
    <CalcDetail
      rate={settlementDetail.commissionRate}
      settlementDetail={settlementDetail}
      settlementDetailObj={settlementDetailObj}
      deliveryFeeObj={deliveryFeeObj}
      onSettlementBtnClicked={onSettlementBtnClicked}
      onDeliverySettlementBtnClicked={onDeliverySettlementBtnClicked}
      onCancelBtnClicked={onCancelBtnClicked}
    />
  ) : (
    <div></div>
  );
};

export default CalcDetailContainer;
