import useToken from '@hooks/store/useToken';
import { useTab } from '@hooks/useTab';
import { alertMessage } from '@libs/alertMessage';
import { apiRoute, BasicListDataType, requestSecureGet } from '@libs/api/api';
import { getEndOfDate, getStartOfDate } from '@libs/factory';

import {
  OrderListItemTypes,
  OrderQueryParamsTypes,
  PaymentListItemTypes,
} from '@typedef/components/Order/order.types';
import { PartnersPaymentsTypes } from '@typedef/components/Partners/partners.types';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Order from '../Order';

const OrderContainer = () => {
  const navigate = useNavigate();
  const { queryParams, updateQueryParamsFromSession, removeTabFromSession } =
    useTab<OrderQueryParamsTypes>('order');

  const { getAccessToken } = useToken();

  const [paymentList, setPaymentList] = useState<PaymentListItemTypes[]>([]);

  const [maxPage, setMaxPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(queryParams.page || 0);
  const [searchInput, setSearchInput] = useState(queryParams.searchInput || '');
  const [paymentFrom, setPaymentFrom] = useState(queryParams.paymentFrom || '');
  const [paymentTo, setPaymentTo] = useState(queryParams.paymentTo || '');
  const [isLoaded, setIsLoaded] = useState(false);

  const onSearchInputChanged = useCallback((value: string) => {
    setSearchInput(value);
  }, []);

  const loadPaymentList = useCallback(async () => {
    const paymentFromQ = paymentFrom
      ? '&paymentFrom=' + getStartOfDate(paymentFrom)
      : '';
    const paymentToQ = paymentTo ? '&paymentTo=' + getEndOfDate(paymentTo) : '';

    const q = searchInput ? '&q=' + searchInput : '';

    const {
      data,
      config: { status },
    } = await requestSecureGet<BasicListDataType<PaymentListItemTypes>>(
      apiRoute.payment.getPaymentsList +
        `?page=${currentPage}&perPage=${10}` +
        paymentFromQ +
        paymentToQ +
        q,
      {},
      getAccessToken()!,
    );

    if (status !== 200) {
      alertMessage('목록을 불러오지 못했습니다.');
      return;
    }

    setPaymentList(data.rows);
    setMaxPage(data.meta.maxPage);
    setIsLoaded(true);
  }, [currentPage, paymentFrom, paymentTo, searchInput]);

  const onPageChanged = useCallback((page: number) => {
    setCurrentPage(page);
  }, []);

  const onSearchBtnClicked = useCallback(() => {
    currentPage === 0 ? loadPaymentList() : setCurrentPage(0);
    loadPaymentList();
  }, [currentPage, loadPaymentList]);

  const onApplyBtnClicked = useCallback(() => {
    setCurrentPage(0);
    loadPaymentList();
  }, [loadPaymentList]);

  const onListItemClicked = useCallback(
    (item: PaymentListItemTypes) => {
      navigate('detail', {
        state: {
          paymentUid: item.paymentUid,
        },
      });
    },
    [navigate, currentPage, searchInput, paymentFrom, paymentTo],
  );

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (isLoaded) {
      timeout = setTimeout(() => {
        onApplyBtnClicked();
      }, 1000);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [searchInput]);

  useEffect(() => {
    loadPaymentList();
  }, [currentPage, paymentFrom, paymentTo]);

  useEffect(() => {
    updateQueryParamsFromSession('', {
      page: currentPage,
      searchInput,
      paymentFrom,
      paymentTo,
    });
  }, [currentPage, searchInput, paymentFrom, paymentTo]);

  return (
    <Order
      paymentList={paymentList}
      maxPage={maxPage}
      currentPage={currentPage}
      searchInput={searchInput}
      paymentFrom={paymentFrom}
      paymentTo={paymentTo}
      setCurrentPage={setCurrentPage}
      onPageChanged={onPageChanged}
      onSearchInputChanged={onSearchInputChanged}
      onListItemClicked={onListItemClicked}
      setSearchInput={setSearchInput}
      setPaymentFrom={setPaymentFrom}
      setPaymentTo={setPaymentTo}
      onSearchBtnClicked={onSearchBtnClicked}
      onApplyBtnClicked={onApplyBtnClicked}
    />
  );
};

export default OrderContainer;
