import useToken from '@hooks/store/useToken';
import { alertMessage } from '@libs/alertMessage';
import {
  apiRoute,
  BasicApiResponse,
  BasicListDataType,
  requestGet,
  requestMultipartPatch,
  requestSecureDelete,
  requestSecureGet,
} from '@libs/api/api';
import { uriToFile } from '@libs/trans';
import {
  ExistingStoreProfileTypes,
  KakaoAddressType,
  PartnersCategoryListItemTypes,
  PartnersDetailFormTypes,
  PartnersDetailTypes,
  StoreRestsTypes,
} from '@typedef/components/Partners/partners.types';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useCallback } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import PartnersDetail from '../components/PartnersDetail';

const PartnersDetailContainer = () => {
  const navigate = useNavigate();
  const { getAccessToken } = useToken();
  const { partnersUid, type, isEditable } = useLocation().state as {
    partnersUid: string;
    isEditable: boolean;
    type: 'detail' | 'signUp' | 'update';
  };

  const [partners, setPartners] = useState<PartnersDetailTypes>();

  const [partnersDetailForm, setPartnersDetailForm] =
    useState<PartnersDetailFormTypes>({
      name: '',
      phone: '',
      email: '',
      accountBank: null,
      accountName: '',
      accountNumber: '',
      partnersName: '',
      storeName: '',
      ownerName: '',
      storePhone: '',
      locationDetail: '',
      longitude: '',
      latitude: '',
      businessNumber: '',
      description: '',
      freeDelivery: 0,
      startHours: '',
      startMinutes: '',
      endHours: '',
      endMinutes: '',
      telSalesBusinessReportNumber: '',
    });

  const [targetTagUid, setTargetTagUid] = useState<{
    targetTagUid: number;
    name: string;
  } | null>(null);
  const [isRestHoliday, setIsRestHoliday] = useState(0);
  const [location, setLocation] = useState('');
  const [partnersProfile, setPartnersProfile] = useState('');
  const [storeProfile, setStoreProfile] = useState<string[]>([]);
  const [businessRegistrationFile, setBusinessRegistrationFile] =
    useState<File>();
  const [businessReportFile, setBusinessReportFile] = useState<File>();
  const [storeRests, setStoreRests] = useState<StoreRestsTypes[]>([]);

  const [images, setImages] = useState<(File | null)[]>([]);

  const [shopCategory, setShopCategory] = useState<
    PartnersCategoryListItemTypes[]
  >([]);

  const onPartnersDetailFormChanged = useCallback(
    (key: keyof PartnersDetailFormTypes, value: string | number) => {
      setPartnersDetailForm((prev) => {
        return { ...prev, [key]: value };
      });
    },
    [],
  );

  const loadPartnersData = useCallback(async () => {
    const {
      data,
      config: { status },
    } = await requestSecureGet<PartnersDetailTypes>(
      apiRoute.partners.getPartners + `/${partnersUid}`,
      {},
      getAccessToken()!,
    );

    if (status !== 200) {
      alertMessage('정보를 불러오지 못했습니다.');
    }

    setTargetTagUid(data.store.targetTag);
    setStoreRests(data.store.storeRests);
    if (data.store.storeProfile !== null) {
      setImages(
        await Promise.all(
          data.store.storeProfile.map((item) => uriToFile(item)),
        ),
      );
    }

    const startDate = data.store.startDate;
    const endDate = data.store.endDate;

    setIsRestHoliday(data.store.isRestHoliday);
    setPartnersDetailForm((prev) => {
      return {
        ...prev,
        accountBank: data.accountBank,
        startHours: startDate.split(':')[0],
        startMinutes: startDate.split(':')[1],
        endHours: endDate.split(':')[0],
        endMinutes: endDate.split(':')[1],
      };
    });
    setLocation(data.store.location);
    setPartners(data);
  }, [getAccessToken, partnersUid]);

  const loadCategory = useCallback(async () => {
    const { data, config } = await requestSecureGet<
      PartnersCategoryListItemTypes[]
    >(apiRoute.partners.tags.getTags, {}, getAccessToken()!);

    if (config.status === 200) {
      setShopCategory(data);
    } else {
      alertMessage('목록을 불러오지 못했습니다');
    }
  }, [getAccessToken]);

  const onStoreRestClicked = useCallback((week: number, day: number) => {
    setStoreRests((prev) => {
      const clone = [...prev];

      if (clone.some((item) => item.week === week && item.day === day)) {
        return clone.filter((item) => item.week !== week || item.day !== day);
      }
      clone.push({ week, day });
      return clone;
    });
  }, []);

  const onAddressInputClicked = useCallback(() => {
    window.daum.postcode.load(() => {
      const postcode = new window.daum.Postcode({
        oncomplete: function (data) {
          console.log(data);
          loadLatLng(data.address);
        },
      });
      postcode.open();
    });
  }, []);

  const loadLatLng = useCallback(async (location: string) => {
    const {
      data: { documents },
    } = await requestGet<any>(
      `https://dapi.kakao.com/v2/local/search/address.json?analyze_type=similar&page=1&size=10&query=${location}`,
      { Authorization: `KakaoAK 2c98d14566b8642fde1fc8c643fa605f` },
    );
    const address = documents as KakaoAddressType[];
    console.log(address);
    setLocation(location);
    setPartnersDetailForm((prev) => {
      return {
        ...prev,
        latitude: address[0].y,
        longitude: address[0].x,
      };
    });
  }, []);

  const onDeleteButtonClicked = useCallback(async () => {
    const willBeDeleted = window.confirm('정말로 삭제하시겠습니까?');

    if (!willBeDeleted) return;

    const {
      data,
      config: { status },
    } = await requestSecureDelete<BasicApiResponse<{}>>(
      apiRoute.partners.deletePartner + partnersUid,
      {},
      getAccessToken()!,
    );

    if (status !== 202) {
      alertMessage('파트너스 삭제에 실패했습니다.');
      return;
    }

    navigate(-1);
  }, [partnersUid, navigate, getAccessToken]);

  const onBackButtonClicked = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const onSaveButtonClicked = useCallback(async () => {
    const formData = new FormData();
    if (!partnersDetailForm.accountBank) {
      alertMessage('은행명을 선택해주세요.');
      return;
    }

    if (
      !(
        partnersDetailForm.startHours &&
        partnersDetailForm.startMinutes &&
        partnersDetailForm.endHours &&
        partnersDetailForm.endMinutes
      )
    ) {
      alertMessage('영업시간을 설정해주세요');
      return;
    }

    storeRests.forEach((item) =>
      formData.append('createStoreRestsDto', JSON.stringify(item)),
    );
    images &&
      images.forEach((item) => {
        formData.append('storeProfile', item as File);
      });
    formData.append('partnersName', partnersDetailForm.partnersName);
    formData.append('name', partnersDetailForm.name);
    formData.append('phone', partnersDetailForm.phone);
    formData.append('email', partnersDetailForm.email);
    formData.append('accountBank', partnersDetailForm.accountBank);
    formData.append('accountNumber', partnersDetailForm.accountNumber);
    formData.append('accountName', partnersDetailForm.accountName);
    formData.append('storeName', partnersDetailForm.storeName);
    formData.append('ownerName', partnersDetailForm.ownerName);
    formData.append('storePhone', partnersDetailForm.storePhone);
    formData.append('location', location);
    formData.append('locationDetail', partnersDetailForm.locationDetail);
    formData.append('longitude', String(partnersDetailForm.longitude));
    formData.append('latitude', String(partnersDetailForm.latitude));
    formData.append('businessNumber', partnersDetailForm.businessNumber);
    formData.append('isRestHoliday', String(isRestHoliday));
    partnersDetailForm.description &&
      formData.append('description', partnersDetailForm.description);
    formData.append('freeDelivery', String(partnersDetailForm.freeDelivery));
    formData.append('targetTagUid', String(targetTagUid?.targetTagUid));
    formData.append(
      'startDate',
      `${partnersDetailForm.startHours}:${partnersDetailForm.startMinutes}`,
    );
    formData.append(
      'endDate',
      `${partnersDetailForm.endHours}:${partnersDetailForm.endMinutes}`,
    );
    businessRegistrationFile &&
      formData.append(
        'businessRegistrationFile',
        businessRegistrationFile as File,
      );

    businessReportFile &&
      formData.append('businessReportFile', businessReportFile as File);

    const {
      data,
      config: { status },
    } = await requestMultipartPatch(
      apiRoute.partners.updatePartner + partners?.partnersUid,
      {},
      formData,
      getAccessToken()!,
    );

    console.log(data, status);

    if (status !== 202) {
      alertMessage('수정에 실패했습니다.');
      return;
    }
    navigate(-1);
  }, [
    navigate,
    storeRests,
    images,
    partnersDetailForm,
    location,
    isRestHoliday,
    targetTagUid,
    businessRegistrationFile,
    businessReportFile,
  ]);

  useEffect(() => {
    loadCategory();
    loadPartnersData();
  }, []);

  return partners ? (
    <PartnersDetail
      type={type}
      isEditable={isEditable}
      partners={partners}
      partnersDetailForm={partnersDetailForm}
      images={images}
      storeRests={storeRests}
      shopCategory={shopCategory}
      isRestHoliday={isRestHoliday}
      location={location}
      onPartnersDetailFormChanged={onPartnersDetailFormChanged}
      setImages={setImages}
      setLocation={setLocation}
      setTargetTagUid={setTargetTagUid}
      setPartnersProfile={setPartnersProfile}
      setStoreProfile={setStoreProfile}
      setIsRestHoliday={setIsRestHoliday}
      setBusinessRegistrationFile={setBusinessRegistrationFile}
      setBusinessReportFile={setBusinessReportFile}
      onStoreRestClicked={onStoreRestClicked}
      onSaveButtonClicked={onSaveButtonClicked}
      onDeleteButtonClicked={onDeleteButtonClicked}
      onBackButtonClicked={onBackButtonClicked}
      onAddressInputClicked={onAddressInputClicked}
    />
  ) : (
    <div className='content-root'></div>
  );
};

export default PartnersDetailContainer;
