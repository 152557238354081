import useToken from '@hooks/store/useToken';
import { alertMessage } from '@libs/alertMessage';
import { apiRoute, requestSecurePost } from '@libs/api/api';
import { NoticeOptionItemTypes } from '@typedef/components/NoticeEvent/notice.event.types';
import React, { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import NoticeAdd from '../components/NoticeAdd';

const NoticeAddContainer = () => {
  const navigate = useNavigate();

  const { getAccessToken } = useToken();

  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [files, setFiles] = useState<File[]>([]);
  const [target, setTarget] = useState<NoticeOptionItemTypes>({
    type: 'CLIENT',
    label: '클라이언트',
  });

  const targetOption = useMemo<[NoticeOptionItemTypes, NoticeOptionItemTypes]>(
    () => [
      { type: 'CLIENT', label: '클라이언트' },
      { type: 'PARTNERS', label: '파트너스' },
    ],
    [],
  );

  const onBackBtnClicked = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const onAddBtnClicked = useCallback(async () => {
    const form = new FormData();

    form.append('title', title);
    form.append('contents', content);
    form.append('target', target?.type || 'CLIENT');
    files.forEach((item) => form.append('noticeFile', item));

    const { config } = await requestSecurePost(
      apiRoute.notice.addNotice,
      {
        'Content-Type': 'multipart/form-data',
      },
      form,
      getAccessToken()!,
    );

    if (config.status === 201) {
      navigate(-1);
    } else {
      alertMessage('공지 추가에 실패했습니다');
    }
  }, [title, content, files, target, navigate]);

  return (
    <NoticeAdd
      content={content}
      setTitle={setTitle}
      setContent={setContent}
      setFiles={setFiles}
      setTarget={setTarget}
      targetOption={targetOption}
      onBackBtnClicked={onBackBtnClicked}
      onAddBtnClicked={onAddBtnClicked}
    />
  );
};

export default NoticeAddContainer;
