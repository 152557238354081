import { useTab } from '@hooks/useTab';
import { NoticeEventTabTypes } from '@typedef/components/NoticeEvent/notice.event.types';
import React, { useState } from 'react';
import { useCallback } from 'react';
import NoticeEvent from '../NoticeEvent';

const NoticeEventContainer = () => {
  const { tab: tabItem } = useTab<{}>('noticeEvent');
  const [tab, setTab] = useState<NoticeEventTabTypes>(
    (tabItem as NoticeEventTabTypes) || 'notice',
  );

  const onTabChanged = useCallback((tab: NoticeEventTabTypes) => {
    setTab(tab);
  }, []);

  return <NoticeEvent tab={tab} onTabChanged={onTabChanged} />;
};

export default NoticeEventContainer;
