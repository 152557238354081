import { ItemDescriptionTypes } from '@typedef/components/Item/item.types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ItemDescription from '../components/ItemDescription';

type Props = {
  idx: number;
  itemDescription: ItemDescriptionTypes;
  onUpdateDescriptionFile: (
    fileList: (File | null)[],
    descriptionIdx: number,
  ) => void;
  onUpdateDescription: (description: string, descriptionIdx: number) => void;
  onDeleteDescriptionClicked: (idx: number) => void;
};

const ItemDescriptionContainer = ({
  idx,
  itemDescription,
  onUpdateDescription,
  onUpdateDescriptionFile,
  onDeleteDescriptionClicked,
}: Props) => {
  const [fileList, setFileList] = useState<(File | null)[]>(
    itemDescription.descriptionFile,
  );

  const imageUrlList = useMemo(() => {
    return fileList
      ? fileList.map((item) => URL.createObjectURL(item as Blob))
      : [];
  }, [fileList]);

  const onAddFile = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;
    const files = e.target.files;

    const blobs = Array.from(files).map((file) => {
      return file?.slice(0, file.size, file.type);
    });

    const newFiles = blobs.map((blob, idx) => {
      return new File([blob], `${new Date().getTime().toString()}${idx}`, {
        type: files[idx].type,
      });
    });

    setFileList((prev) => {
      const clone = [...prev, ...newFiles];
      return clone;
    });
  }, []);

  const onDeleteFile = useCallback((fileIdx: number) => {
    setFileList((prev) => {
      const clone = [...prev];
      clone.splice(fileIdx, 1);
      return clone;
    });
  }, []);

  useEffect(() => {
    onUpdateDescriptionFile(fileList, idx);
  }, [fileList]);

  return (
    <ItemDescription
      idx={idx}
      description={itemDescription.description}
      onAddFile={onAddFile}
      onUpdateDescription={onUpdateDescription}
      onDeleteFile={onDeleteFile}
      onDeleteDescriptionClicked={onDeleteDescriptionClicked}
      imageUrlList={imageUrlList}
    />
  );
};

export default ItemDescriptionContainer;
