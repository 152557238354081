import CouponAddContainer from '@components/Coupon/containers/CouponAddContainer';
import AddCouponPopupContainer from '@components/Coupon/containers/AddCouponPopupContainer';
import usePopup from '@hooks/store/usePopup';
import useToken from '@hooks/store/useToken';
import { alertMessage } from '@libs/alertMessage';
import {
  apiRoute,
  API_ORIGIN,
  BasicListDataType,
  requestSecureDelete,
  requestSecureGet,
} from '@libs/api/api';
import { CouponTypes } from '@typedef/components/Coupon/coupon.types';
import { UserCouponTypes } from '@typedef/components/User/user.types';
import React, { useEffect, useState } from 'react';
import { useCallback } from 'react';
import UserCouponList from '../components/UserCouponList';

type Props = {
  userUid: string;
};

const itemCnt = 10;

const UserCouponListContainer = ({ userUid }: Props) => {
  const { getAccessToken } = useToken();
  const { __showPopupFromHooks, visible } = usePopup();

  const [couponList, setCouponList] = useState<UserCouponTypes[]>([]);
  const [page, setPage] = useState(0);
  const [maxPage, setMaxPage] = useState(1);

  const loadCouponList = useCallback(async () => {
    const {
      data,
      config: { status },
    } = await requestSecureGet<BasicListDataType<UserCouponTypes>>(
      apiRoute.user.getUserCouponList +
        userUid +
        `?page=${page}&perPage=${itemCnt}`,
      {},
      getAccessToken()!,
    );

    if (status !== 200) {
      alertMessage('쿠폰 목록을 불러오지 못했습니다.');
      return;
    }

    console.log(data);

    setMaxPage(data.maxPage);
    setCouponList(data.rows);
  }, [getAccessToken, page]);

  const onAddCouponClicked = useCallback(() => {
    __showPopupFromHooks(<AddCouponPopupContainer userUid={userUid} />);
  }, [userUid]);

  const onDeleteCouponClicked = useCallback(
    async (couponUid: number) => {
      const willDeleteCoupon = window.confirm('정말로 삭제하시겠습니까?');
      if (!willDeleteCoupon) return;

      const {
        data,
        config: { status },
      } = await requestSecureDelete<{}>(
        apiRoute.coupon.deleteCoupons + `${couponUid}/users/${userUid}`,
        {},
        getAccessToken()!,
      );

      if (status !== 202) {
        alertMessage('쿠폰 삭제에 실패했습니다.');
        return;
      }

      loadCouponList();
    },
    [userUid, couponList, loadCouponList],
  );

  useEffect(() => {
    loadCouponList();
  }, [visible, page]);

  return (
    <UserCouponList
      userUid={userUid}
      couponList={couponList}
      page={page}
      setPage={setPage}
      maxPage={maxPage}
      onAddCouponClicked={onAddCouponClicked}
      onDeleteCouponClicked={onDeleteCouponClicked}
    />
  );
};

export default UserCouponListContainer;
