import CMultiFileInputContainer from '@components/common/CFileInput/containers/CMultiFileInputContainer';
import { BanksObj } from '@components/common/CBankSelect/containers/CBankSelectContainer';
import CInputContainer from '@components/common/CInput/containers/CInputContainer';
import { formatDate } from '@libs/factory';
import {
  PartnersUpdateReqCategoryTypes,
  PartnersUpdateReqStatusTypes,
  StoreTypes,
} from '@typedef/components/Partners/partners.types';
import React from 'react';
import '../styles/update.request.detail.styles.css';

type Props = {
  status: PartnersUpdateReqStatusTypes;
  stores: StoreTypes;
  onUpdateBtnClicked: (state: string) => void;
  onBackBtnClicked: () => void;
  onImageClicked: (src: string) => void;
};

const UpdateRequestDetail = ({
  status,
  stores,
  onUpdateBtnClicked,
  onBackBtnClicked,
  onImageClicked,
}: Props) => {
  return (
    <div className='content-root update-request-detail-root'>
      <div className='title-container'>
        <div className='title-item'>
          <div className='title-label'>{stores?.partners?.name}</div>
          <div className='subtitle-label'>{stores?.partners?.email}</div>
          {status === 'PENDING' && (
            <div className='subtitle-label'>{`수정 요청입니다.`}</div>
          )}
          {status === 'FULFILLED' && (
            <div className='subtitle-label'>{`승인된 수정 요청입니다.`}</div>
          )}
          {status === 'REJECTED' && (
            <div className='subtitle-label'>{`거절된 수정 요청입니다.`}</div>
          )}
        </div>
      </div>
      <div className='button-container update'>
        {status === 'PENDING' && (
          <>
            <button
              className='delete-btn'
              onClick={() => onUpdateBtnClicked('REJECTED')}>
              거절하기
            </button>
            <button
              className='primary-btn'
              onClick={() => onUpdateBtnClicked('FULFILLED')}>
              승인하기
            </button>
          </>
        )}
      </div>
      <div className={`content-root `}>
        <div className='table-container '>
          <span className='table-title'>기본정보</span>

          <div className={`body-section`}>
            <div className='row image'>
              {stores.storeProfile.map((item) => {
                return (
                  <img
                    src={item}
                    alt='사진'
                    onClick={() => onImageClicked(item)}
                  />
                );
              })}
            </div>
            <div className='row'>
              <div className='item only-view'>
                <span className='label'>이름</span>
                <span className=''>{stores.partners?.name}</span>
              </div>
            </div>
            <div className='row'>
              <div className='item only-view'>
                <span className='label'>전화번호</span>
                <span>{stores.phone}</span>
              </div>
            </div>
            <div className='row'>
              <div className='item only-view'>
                <span className='label'>아이디</span>
                <span>{stores.partners?.partnersName}</span>
              </div>
            </div>
            <div className='row'>
              <div className='item only-view'>
                <span className='label'>은행명</span>
                <span>
                  {stores.partners
                    ? BanksObj[stores.partners?.accountBank]
                    : ''}
                </span>
              </div>
            </div>
            <div className='row'>
              <div className='item only-view'>
                <span className='label'>예금주명</span>
                <span>{stores.partners?.accountName}</span>
              </div>
            </div>
            <div className='row'>
              <div className='item only-view'>
                <span className='label'>계좌번호</span>
                <span>{stores.partners?.accountNumber}</span>
              </div>
            </div>

            <div className='row'>
              <div className='item only-view'>
                <span className='label'>이메일</span>
                <span>{stores.partners?.email}</span>
              </div>
            </div>
            <div className='row'>
              <div className='item only-view'>
                <span className='label'>가입일</span>
                <span>
                  {stores.partners?.createDate &&
                    formatDate(stores.partners?.createDate)}
                </span>
              </div>
            </div>
            <div className='row'>
              <div className='item only-view'>
                <span className='label'>삭제일</span>
                <span>{stores.partners?.deleteDate}</span>
              </div>
            </div>
          </div>
        </div>
        <div className='table-container store'>
          <span className='table-title'>가게정보</span>
          <div className={`store-file-section view`}>
            <div className='rest-day-section'>
              <span>휴점일</span>
              {['첫째주', '둘째주', '셋째주', '넷째주', '다섯째주'].map(
                (week, weekIdx) => {
                  return (
                    <div className='week-container' key={weekIdx}>
                      <div className={`week item`}>{week}</div>
                      {['월', '화', '수', '목', '금', '토', '일'].map(
                        (day, dayIdx) => {
                          return (
                            <div
                              className={`day item  ${
                                stores.storeRests?.some(
                                  (item) =>
                                    item.week === weekIdx + 1 &&
                                    item.day === dayIdx + 1,
                                ) && 'selected'
                              }`}
                              key={dayIdx}>
                              {day}
                            </div>
                          );
                        },
                      )}
                    </div>
                  );
                },
              )}
              <div
                className={`holidays item ${
                  Boolean(stores.isRestHoliday) && 'selected'
                }`}>
                공휴일
              </div>
            </div>
            <div className='business-file-section'>
              {stores.businessRegistrationFile && (
                <a
                  className='file-download-btn'
                  href={stores.businessRegistrationFile[0]}>
                  {'사업자등록증을 확인해주세요'}
                </a>
              )}
              {stores.businessReportFile && (
                <a
                  className='file-download-btn'
                  href={stores.businessReportFile[0]}>
                  {'통신판매업 신고증을 확인해주세요'}
                </a>
              )}
            </div>
          </div>
          <div className={`body-section `}>
            <div className='row'>
              <div className='item only-view'>
                <span className='label'>대표자명</span>
                <span>{stores.ownerName}</span>
              </div>
            </div>
            <div className='row'>
              <div className='item only-view'>
                <span className='label'>사업자 번호</span>
                <span>{stores.businessNumber}</span>
              </div>
            </div>
            <div className='row'>
              <div className='item only-view'>
                <span className='label'>사업장 주소</span>
                <span>{stores.location}</span>
              </div>
            </div>
            <div className='row'>
              <div className='item only-view'>
                <span className='label'>사업장 상세주소</span>
                <span>{stores.locationDetail}</span>
              </div>
            </div>
            <div className='row'>
              <div className='item only-view'>
                <span className='label'>가게명</span>
                <span>{stores.name}</span>
              </div>
            </div>
            <div className='row'>
              <div className='item only-view'>
                <span className='label'>가게 전화번호</span>
                <span>{stores.phone}</span>
              </div>
            </div>
            <div className='row'>
              <div className='item only-view'>
                <span className='label'>가게 설명</span>
                <span>{stores.description}</span>
              </div>
            </div>
            <div className='row'>
              <div className='item only-view'>
                <span className='label'>샵종류</span>
                <span>{stores.targetTag.name}</span>
              </div>
            </div>
            <div className='row'>
              <div className='item only-view'>
                <span className='label'>개점 시간</span>
                <span>{stores.startDate}</span>
              </div>
            </div>
            <div className='row'>
              <div className='item only-view'>
                <span className='label'>폐점 시간</span>
                <span>{stores.endDate}</span>
              </div>
            </div>
            <div className='row'>
              <div className='item only-view'>
                <span className='label'>무료 배송(원 이상)</span>
                <span>{stores.freeDelivery}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateRequestDetail;
