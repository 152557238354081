import useToken from '@hooks/store/useToken';
import { alertMessage } from '@libs/alertMessage';
import {
  apiRoute,
  BasicApiResponse,
  requestGet,
  requestSecureDelete,
  requestSecurePatch,
  requestSecurePost,
} from '@libs/api/api';
import { FaqCategoryListItemTypes } from '@typedef/components/FAQ/faq.types';
import React, { useCallback, useEffect, useState } from 'react';
import FAQCategory from '../components/FAQCategory';

const FAQCategoryContainer = () => {
  const { getAccessToken } = useToken();
  const [list, setList] = useState<FaqCategoryListItemTypes[]>([]);

  const [updateIdx, setUpdateIdx] = useState(-1);
  const [name, setName] = useState('');
  const [newName, setNewName] = useState('');
  const [addPopupVisible, setAddPopupVisible] = useState(false);

  const onAddClicked = useCallback(() => {
    setAddPopupVisible(true);
  }, []);

  const loadList = useCallback(async () => {
    const { data, config } = await requestGet<FaqCategoryListItemTypes[]>(
      apiRoute.faq.category.getCategory,
      {},
    );

    if (config.status === 200) {
      setList(data);
    } else {
      alertMessage('목록을 불러오지 못했습니다');
    }
  }, []);

  const onChangeNameDoneBtnClicked = useCallback(
    async (item: FaqCategoryListItemTypes) => {
      const {
        data,
        config: { status },
      } = await requestSecurePatch<{}>(
        apiRoute.faq.category.getCategory + `/${item.faqCategoryUid}`,
        {},
        { name },
        getAccessToken()!,
      );

      if (status !== 202) {
        alertMessage('카테고리 수정에 실패했습니다.');
        return;
      }

      if (status === 202) {
        alertMessage('카테고리 수정에 성공했습니다.');
      }

      setList((prev) => {
        const clone = [...prev];
        clone[updateIdx] = { ...item, name };
        return clone;
      });
      setUpdateIdx(-1);
    },
    [getAccessToken, name, updateIdx],
  );

  const onAddNewCategoryClicked = useCallback(async () => {
    const { data, config } = await requestSecurePost<BasicApiResponse<{}>>(
      apiRoute.faq.category.getCategory,
      {},
      { name: newName },
      getAccessToken()!,
    );

    console.log(data, config);
    if (config.status !== 201) return;
    setNewName('');
    setAddPopupVisible(false);
    loadList();
  }, [newName]);

  const onDeleteClicked = useCallback(
    async (item: FaqCategoryListItemTypes, idx: number) => {
      const {
        data,
        config: { status },
      } = await requestSecureDelete<BasicApiResponse<{}>>(
        apiRoute.faq.category.getCategory + `/${item.faqCategoryUid}`,
        {},
        getAccessToken()!,
      );

      if (status !== 202) {
        alertMessage('샵 카테고리 삭제에 실패했습니다.');
        return;
      }

      if (status === 202) {
        alertMessage('삭제되었습니다.');
      }

      setList((prev) => {
        const clone = [...prev];
        clone.splice(idx, 1);
        return clone;
      });
    },
    [],
  );

  useEffect(() => {
    loadList();
  }, []);

  return (
    <FAQCategory
      list={list}
      addPopupVisible={addPopupVisible}
      onAddClicked={onAddClicked}
      updateIdx={updateIdx}
      setUpdateIdx={setUpdateIdx}
      setName={setName}
      setNewName={setNewName}
      onChangeNameDoneBtnClicked={onChangeNameDoneBtnClicked}
      onAddNewCategoryClicked={onAddNewCategoryClicked}
      onDeleteClicked={onDeleteClicked}
    />
  );
};

export default FAQCategoryContainer;
