import React, { useCallback, useEffect, useMemo } from 'react';
import CPaging from '../CPaging';

type Props = {
  maxPage: number;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
};

const NUMBERS_OF_PAGE = 10;

const CPagingContainer = ({ maxPage, page, setPage }: Props) => {
  const pageIdx = useMemo(() => Math.floor(page / NUMBERS_OF_PAGE), [page]);

  const endPage = useMemo(
    () => Math.min(maxPage + 1, pageIdx * NUMBERS_OF_PAGE + NUMBERS_OF_PAGE),
    [maxPage, pageIdx],
  );

  const pageList = useMemo(
    () =>
      Array.from(new Array(endPage - pageIdx * NUMBERS_OF_PAGE).keys()).map(
        (item) => {
          return pageIdx * NUMBERS_OF_PAGE + item;
        },
      ),
    [pageIdx, endPage],
  );

  const onFirstPageClicked = useCallback(() => {
    setPage(0);
  }, [setPage]);

  const onPrevPageClicked = useCallback(() => {
    setPage(Math.max(0, page - 1));
  }, [page]);

  const onPageClicked = useCallback(
    (page: number) => {
      setPage(page);
    },
    [setPage],
  );

  const onNextPageClicked = useCallback(() => {
    setPage(Math.min(page + 1, maxPage));
  }, [page, maxPage]);

  const onLastPageClicked = useCallback(() => {
    setPage(maxPage);
  }, [maxPage]);

  return (
    <CPaging
      page={page}
      pageList={pageList}
      onFirstPageClicked={onFirstPageClicked}
      onPrevPageClicked={onPrevPageClicked}
      onPageClicked={onPageClicked}
      onNextPageClicked={onNextPageClicked}
      onLastPageClicked={onLastPageClicked}
    />
  );
};

export default CPagingContainer;
