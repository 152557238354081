import image from '@components/common/assets/image';

import { formatDate } from '@libs/factory';
import { MainBannerListItemTypes } from '@typedef/components/MainBanner/main.banner.types';
import React from 'react';
import './styles/main.banner.styles.css';

type Props = {
  mainBannerList: MainBannerListItemTypes[];
  onBannerAddClicked: () => void;
  onPrioritySaveClicked: () => void;
  onBannerItemClicked: (item: MainBannerListItemTypes) => void;
  onBannerUpClicked: (idx: number) => void;
  onBannerDownClicked: (idx: number) => void;
};

const MainBanner = ({
  mainBannerList,
  onBannerAddClicked,
  onPrioritySaveClicked,
  onBannerItemClicked,
  onBannerDownClicked,
  onBannerUpClicked,
}: Props) => {
  return (
    <div className='main-banner-root content-root'>
      <div className='title-container'>
        <div className='title-item'>
          <div className='title-label'>{'메인배너관리'}</div>
          <div className='subtitle-label'>{'메인배너관리 페이지 입니다'}</div>
        </div>
      </div>
      <div className='button-container'>
        <button className='primary-btn' onClick={onBannerAddClicked}>
          생성하기
        </button>
        <button className='primary-btn' onClick={onPrioritySaveClicked}>
          순서저장
        </button>
      </div>
      <div className='table-container'>
        <div className='header-section'>
          <div className='item'>{'썸네일 미리보기'}</div>
          <div className='item'>{'배너 제목'}</div>
          <div className='item'>{'배너 게시일'}</div>
          <div className='item'>{'노출 순서'}</div>
        </div>
        <div className='body-section'>
          {mainBannerList.map((item, idx) => {
            return (
              <div
                className='row'
                key={idx}
                onClick={(e) => {
                  onBannerItemClicked(item);
                }}>
                <div className='main-banner-image item'>
                  <img
                    src={item.thumbnail ?? ''}
                    alt={item.title}
                    onError={(e) =>
                      (e.currentTarget.src = image.placeholderImg)
                    }
                  />
                </div>
                <div className='item'>{item.title}</div>
                <div className='item'>{formatDate(item.createDate)}</div>
                <div className='item'>
                  {idx !== 0 && (
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        onBannerUpClicked(idx);
                      }}
                      style={{
                        width: '40px',
                        height: '40px',
                        margin: '4px',
                        fontSize: '16px',
                      }}>
                      {'⇑'}
                    </button>
                  )}
                  {mainBannerList.length !== idx + 1 && (
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        onBannerDownClicked(idx);
                      }}
                      style={{
                        width: '40px',
                        height: '40px',
                        margin: '4px',
                        fontSize: '16px',
                      }}>
                      {'⇓'}
                    </button>
                  )}
                </div>
              </div>
            );
          })}
        </div>
        <div className='footer-section'></div>
      </div>
    </div>
  );
};

export default MainBanner;
