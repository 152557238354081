import { GNBItemTypes } from '@typedef/components/common/GNB/gnb.types';
import React from 'react';

import './styles/gnb.styles.css';

const data = [
  {
    label: '사용자',
    tab: 'user',
  },
  {
    label: '파트너스',
    tab: 'partners',
  },
  {
    label: '상품 관리',
    tab: 'item',
  },
  {
    label: '특가상품 관리',
    tab: 'saleItem',
  },
  {
    label: '주문내역',
    tab: 'order',
  },
  {
    label: '정산관리',
    tab: 'calculate',
  },
  {
    label: 'QR포인트 관리',
    tab: 'qrPoint',
  },
  {
    label: '관리자 쿠폰 관리',
    tab: 'adminCoupon',
  },
  {
    label: '포인트 관리',
    tab: 'point',
  },
  {
    label: '메인배너 관리',
    tab: 'mainBanner',
  },
  {
    label: '공지 / 이벤트 관리',
    tab: 'noticeEvent',
  },
  {
    label: '상품 카테고리 관리',
    tab: 'itemCategory',
  },
  {
    label: 'FAQ관리',
    tab: 'faq',
  },
] as { label: string; tab: GNBItemTypes }[];

type Props = {
  selectedTab: GNBItemTypes;
  onTabClicked: (tab: GNBItemTypes) => void;
  onLogoutClicked: () => void;
};

const GNB = ({ selectedTab, onTabClicked, onLogoutClicked }: Props) => {
  return (
    <div className='gnb-root'>
      <div className='logo-container'>
        <img src={'/assets/images/common/ic-logo.png'} alt='로고' />
        <span className='logo-desc'>{'Admin'}</span>
      </div>
      {data.map((item, idx) => (
        <div
          key={idx}
          className={`gnb-item-container ${
            selectedTab === item.tab && 'selected'
          }`}
          onClick={() => onTabClicked(item.tab)}>
          <span className='gnb-item-label'>{item.label}</span>
        </div>
      ))}
      <button onClick={onLogoutClicked} className='logout-btn'>
        로그아웃
      </button>
    </div>
  );
};

export default GNB;
