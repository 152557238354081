import image from '@components/common/assets/image';

import CPagingContainer from '@components/common/CPaging/containers/CPagingContainer';
import CSearchContainer from '@components/common/CSearch/containers/CSearchContainer';
import CSelectContainer from '@components/common/CSelect/containers/CSelectContainer';
import {
  PartnersCategoryListItemTypes,
  PartnersListItemTypes,
} from '@typedef/components/Partners/partners.types';
import React from 'react';
import '../styles/partners.list.styles.css';

type Props = {
  selectedCategory: PartnersCategoryListItemTypes | null;
  searchInput: string;
  onSearchInputChanged: (value: string) => void;
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  partners: PartnersListItemTypes[];
  maxPage: number;
  onPageChanged: (page: number) => void;
  onPartnersItemClicked: (item: PartnersListItemTypes) => void;
  shopCategory: PartnersCategoryListItemTypes[];
  onSearchBtnClicked: () => void;
  setSelectedCategory: React.Dispatch<
    React.SetStateAction<PartnersCategoryListItemTypes | null>
  >;
};

const PartnersList = ({
  selectedCategory,
  currentPage,
  setCurrentPage,
  searchInput,
  onSearchInputChanged,
  partners,
  maxPage,
  onPageChanged,
  onPartnersItemClicked,
  shopCategory,
  onSearchBtnClicked,
  setSelectedCategory,
}: Props) => {
  return (
    <div className='partners-list-root table-container'>
      <div className='search-section'>
        <form>
          <div className='input-container'>
            <label
              htmlFor='input-partners-search'
              className='input-label'
              hidden>
              {'검색'}
            </label>
            <input
              className='input-box search'
              type='text'
              value={searchInput}
              onChange={(e) => onSearchInputChanged(e.target.value)}
              id='input-partners-search'
              placeholder={
                '아이디, 이름, 전화번호, 가게명, 사업장 주소로 검색해주세요'
              }
            />
            <button
              className='input-button'
              onClick={(e) => {
                e.preventDefault();
                onSearchBtnClicked();
              }}>
              <img src={image.searchBtn} alt='search-btn' />
            </button>
          </div>
        </form>
        <div className='select-container'>
          <label htmlFor='gender-select' hidden>
            {'가게 종류'}
          </label>
          <select
            className={'select-box'}
            placeholder='가게 종류'
            name='select-gender'
            id='select-gender'
            value={selectedCategory?.targetTagUid}
            onChange={(e) => {
              const idx = Number(e.target.selectedOptions[0].dataset.idx);
              setCurrentPage(0);
              setSelectedCategory(shopCategory[idx]);
            }}
            style={{ background: `url(${image.selectArrow}) no-repeat 90%` }}>
            {shopCategory.map((item, idx) => {
              return (
                <option
                  key={idx}
                  data-idx={idx}
                  value={item.targetTagUid}
                  className={'option-gender'}>
                  {item.name}
                </option>
              );
            })}
          </select>
        </div>
      </div>
      <div className='header-section'>
        <div className='item'>아이디</div>
        <div className='item'>이름</div>
        <div className='item'>전화번호</div>
        <div className='item'>가게명</div>
        <div className='item'>샵종류</div>
        <div className='item'>사업장 주소</div>
      </div>
      <div className='body-section'>
        {partners.map((item, index) => (
          <div
            key={index}
            className='row'
            onClick={() => onPartnersItemClicked(item)}>
            <div className='item'>{item.partnersName}</div>
            <div className='item'>{item.name}</div>
            <div className='item'>{item.phone}</div>
            <div className='item'>{item.store.name}</div>
            <div className='item'>{item.store.targetTag.name}</div>
            <div className='item'>{item.store.location}</div>
          </div>
        ))}
      </div>
      <div className='footer-section'>
        <CPagingContainer
          maxPage={maxPage}
          page={currentPage}
          setPage={setCurrentPage}
        />
      </div>
    </div>
  );
};

export default PartnersList;
