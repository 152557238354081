import image from '@components/common/assets/image';
import CPagingContainer from '@components/common/CPaging/containers/CPagingContainer';

import CSearchContainer from '@components/common/CSearch/containers/CSearchContainer';
import { ProductTypes } from '@typedef/components/Item/item.types';
import React from 'react';
import '../styles/cody.popup.styles.css';

type Props = {
  productList: ProductTypes[];
  maxPage: number;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  onSearchClicked: (keyword: string) => void;
  onSelectCodyItemClicked: (idx: number) => void;
};

const CodyPopup = ({
  productList,
  maxPage,
  page,
  setPage,
  onSearchClicked,
  onSelectCodyItemClicked,
}: Props) => {
  return (
    <div className='content-root cody-popup-root'>
      <CSearchContainer
        placeholder='상품명으로 검색하세요'
        onSearchEntered={onSearchClicked}
        inputId={'cody-popup-input'}
      />
      <div className='table-container cody'>
        <div className='header-section'>
          <div className='item'>{'상품사진'}</div>
          <div className='item'>{'상품명'}</div>
          <div className='item'>{'가격'}</div>
          <div className='item'> {'특가상품여부'}</div>
          <div className='item'> {'선택하기'}</div>
        </div>
        <div className='body-section'>
          {productList.map((item, idx) => {
            return (
              <div
                className='row'
                key={idx}
                onClick={() => {
                  onSelectCodyItemClicked(idx);
                }}>
                <div className='item'>
                  <img
                    className='product-image'
                    src={item.productFile[0] ?? image.placeholderImg}
                    alt='상품 사진'
                  />
                </div>
                <div className='item'>{item.name}</div>
                <div className='item'>{item.price.toLocaleString()}</div>
                <div className='item'>
                  {item.discountedPrice ? '특가상품' : '일반상품'}
                </div>
                <div className='item'>
                  <button className='primary-btn select-cody'>선택하기</button>
                </div>
              </div>
            );
          })}
        </div>
        <div className='footer-section'>
          <CPagingContainer maxPage={maxPage} page={page} setPage={setPage} />
        </div>
      </div>
    </div>
  );
};

export default CodyPopup;
