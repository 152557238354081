import CPagingContainer from '@components/common/CPaging/containers/CPagingContainer';
import { formatDate } from '@libs/factory';
import {
  DeliveryFeeObjTypes,
  PaymentDetailItemTypes,
  PaymentListItemTypes,
} from '@typedef/components/Order/order.types';
import React from 'react';
import '../styles/user.payment.list.styles.css';

type Props = {
  paymentList: PaymentListItemTypes[];
  paymentListMaxPage: number;
  paymentListPage: number;
  setPaymentListPage: React.Dispatch<React.SetStateAction<number>>;
};

const UserPaymentList = ({
  paymentList,
  paymentListMaxPage,
  paymentListPage,
  setPaymentListPage,
}: Props) => {
  return (
    <div className='content-root user-payment'>
      <div className='table-container'>
        <div className='header-section'>
          <div className='item'>결제일시</div>
          <div className='item'>가게명</div>
          <div className='item'>상품명</div>
          <div className='item'>수량</div>
          <div className='item'>상품별 결제 금액</div>
          <div className='item'>사용 쿠폰</div>
          <div className='item'>사용 포인트</div>
        </div>
        <div className='body-section'>
          {paymentList.map((paymentItem, idx) => {
            const detailTmpObj: PaymentDetailItemTypes = {};
            paymentItem.orders.forEach((item) => {
              detailTmpObj[item.store.storeUid] = [];
            });
            paymentItem.orders.map((item) => {
              detailTmpObj[item.store.storeUid].push(item);
            });

            const deliveryTmpObj: DeliveryFeeObjTypes = {};
            paymentItem.deliveryFees?.forEach((item) => {
              deliveryTmpObj[item.storeUid] = item;
            });

            return (
              <div className='payment-item' key={idx}>
                {Object.values(detailTmpObj).map((storeItem, storeIdx) => {
                  return storeItem.map((item, itemIdx) => {
                    return (
                      <>
                        <div className='row' key={itemIdx}>
                          <div className='item date'>
                            {itemIdx === 0 &&
                              `${formatDate(item.createDate, true)}`}
                          </div>
                          <div className='item'>
                            {itemIdx === 0 ? item.store.name : ''}
                          </div>
                          <div className='item'>{item.product.name}</div>
                          <div className='item count'>{item.count}</div>
                          <div className='item'>
                            {item.amount.toLocaleString()}
                          </div>
                          <div className='item'>
                            {item.couponDiscount.toLocaleString()}
                          </div>
                          <div className='item'>
                            {item.pointDiscount.toLocaleString()}
                          </div>
                        </div>
                        {storeItem.length - 1 === itemIdx && (
                          <div
                            className='row account'
                            key={'account' + itemIdx}>
                            <div className='item'>{`배송비 : ${
                              deliveryTmpObj[item.store.storeUid]
                                ? deliveryTmpObj[item.store.storeUid].amount
                                : 0
                            }`}</div>
                          </div>
                        )}
                      </>
                    );
                  });
                })}
              </div>
            );
          })}
        </div>
        <div className='footer-section'>
          <CPagingContainer
            maxPage={paymentListMaxPage}
            page={paymentListPage}
            setPage={setPaymentListPage}
          />
        </div>
      </div>
    </div>
  );
};

export default UserPaymentList;
