import image from '@components/common/assets/image';

import CPagingContainer from '@components/common/CPaging/containers/CPagingContainer';
import CSearchContainer from '@components/common/CSearch/containers/CSearchContainer';
import CSelectContainer from '@components/common/CSelect/containers/CSelectContainer';
import { formatDate } from '@libs/factory';
import { SettlementListItemTypes } from '@typedef/components/Calc/calc.types';
import React from 'react';
import './styles/calc.styles.css';

const selectOptionList: { label: string; idx: number }[] = [
  { label: '전체보기', idx: -1 },
  { label: '정산완료', idx: 1 },
  { label: '정산가능', idx: 0 },
];

type Props = {
  rate: number;
  settlementList: SettlementListItemTypes[];
  page: number;
  maxPage: number;
  searchInput: string;
  paymentFrom: string;
  paymentTo: string;
  selectedOption: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  setPaymentFrom: React.Dispatch<React.SetStateAction<string>>;
  setPaymentTo: React.Dispatch<React.SetStateAction<string>>;
  onSearchInputChanged: (value: string) => void;
  setSelectedOption: React.Dispatch<React.SetStateAction<number>>;
  onSearchBtnClicked: () => void;
  onListItemClicked: (paymentUid: number) => void;
  onRateChange: (value: number) => void;
  onRateButtonClicked: () => void;
};

const Calc = ({
  rate,
  settlementList,
  page,
  maxPage,
  searchInput,
  paymentFrom,
  paymentTo,
  selectedOption,
  setPage,
  setPaymentFrom,
  setPaymentTo,
  setSelectedOption,
  onSearchInputChanged,
  onSearchBtnClicked,
  onListItemClicked,
  onRateChange,
  onRateButtonClicked,
}: Props) => {
  return (
    <div className='content-root calc-root'>
      <div className='title-container'>
        <div className='title-item'>
          <div className='title-label'>{'정산관리'}</div>
          <div className='subtitle-label'>{'정산 관리입니다'}</div>
        </div>
      </div>

      <div className='table-container'>
        <div className='setting-section'>
          <label htmlFor='sale-item-size-input'>{'수수료 설정'}</label>
          <div className='input-container'>
            <input
              type='number'
              min={0}
              id='sale-item-size-input'
              value={rate ? rate : ''}
              onChange={(e) => onRateChange(Number(e.target.value))}
            />
            <span>{'%'}</span>
          </div>
          <button className='primary-btn' onClick={onRateButtonClicked}>
            저장하기
          </button>
        </div>
        <div className='search-section'>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              onSearchBtnClicked();
            }}>
            <div className='input-container'>
              <label htmlFor='input-calc-search' className='input-label' hidden>
                {'검색'}
              </label>
              <input
                className='input-box search'
                type='text'
                value={searchInput}
                onChange={(e) => onSearchInputChanged(e.target.value)}
                id='input-calc-search'
                placeholder='주문번호, 가게명, 구매자명으로 검색해주세요'
              />
              <button className='input-button'>
                <img src={image.searchBtn} alt='search-btn' />
              </button>
            </div>
          </form>
          <div className='duration-search-container'>
            <div className='input-wrapper'>
              <input
                type='date'
                className='date-input'
                onChange={(e) => {
                  setPaymentFrom(e.target.value);
                }}
              />
              <span>~</span>
              <input
                type='date'
                className='date-input'
                onChange={(e) => {
                  setPaymentTo(e.target.value);
                }}
              />
            </div>
          </div>
          <div className='select-container'>
            <label htmlFor='item-select' hidden>
              {'정산상태'}
            </label>
            <select
              className={'select-box'}
              placeholder='정산상태'
              name='select-item'
              id='select-item'
              value={selectedOption}
              onChange={(e) => {
                setPage(0);
                setSelectedOption(Number(e.target.value));
              }}
              style={{ background: `url(${image.selectArrow}) no-repeat 90%` }}>
              {selectOptionList.map((item, idx) => {
                return (
                  <option key={idx} value={item.idx} className={'option-store'}>
                    {item.label}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        <div className='header-section'>
          <div className='item'>{'주문번호'}</div>
          <div className='item'>{'결제일시'}</div>
          <div className='item'>{'가게명'}</div>
          <div className='item'>{'구매자'}</div>
          <div className='item'>{'총 상품금액'}</div>
          <div className='item'> {'처리완료여부'}</div>
          <div className='item'> {'품목 수'}</div>
          <div className='item'> {'완료 수'}</div>
          <div className='item'> {'미완료 수'}</div>
        </div>
        <div className='body-section'>
          {settlementList.map((item, idx) => {
            return (
              <div
                className='row'
                key={idx}
                onClick={() => onListItemClicked(item.paymentUid)}>
                <div className='item'>{item.merchantUid}</div>
                <div className='item'>{formatDate(item.createDate)}</div>
                <div className='item'>{item.storeName}</div>
                <div className='item'>{item.buyerName}</div>
                <div className='item'>
                  {(
                    item.totalAmount +
                    item.discountedByPoint +
                    item.discountedByCoupon
                  ).toLocaleString()}
                </div>
                <div
                  className={`item ${item.isDone && 'done'} ${
                    !item.isDone && item.hasSettleable
                      ? 'settleable'
                      : 'disable'
                  }`}>
                  {item.isDone
                    ? '정산완료'
                    : item.hasSettleable
                    ? '정산가능'
                    : '정산불가'}
                </div>
                <div className='item'>{item.itemCount}</div>
                <div className='item'>
                  {Number(item.settledItemCount) +
                    Number(item.canceledItemCount)}
                </div>
                <div className='item'>
                  {Number(item.itemCount) -
                    Number(item.settledItemCount) -
                    Number(item.canceledItemCount)}
                </div>
              </div>
            );
          })}
        </div>
        <div className='footer-section'>
          <CPagingContainer maxPage={maxPage} page={page} setPage={setPage} />
        </div>
      </div>
    </div>
  );
};

export default Calc;
