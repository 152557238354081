import usePopup from '@hooks/store/usePopup';
import useToken from '@hooks/store/useToken';
import { alertMessage } from '@libs/alertMessage';
import {
  apiRoute,
  BasicApiResponse,
  BasicListDataType,
  requestSecurePatch,
  requestSecurePost,
} from '@libs/api/api';
import { getEndOfDate } from '@libs/factory';
import { CouponTypes } from '@typedef/components/Coupon/coupon.types';
import React, { useCallback, useState } from 'react';
import { useEffect } from 'react';
import AddCouponPopup from '../components/AddCouponPopup';

type Props = {
  storeUid?: number;
  userUid?: string;
  coupon?: CouponTypes;
};

const AddCouponPopupContainer = ({ storeUid, userUid, coupon }: Props) => {
  const { visible, __hidePopupFromHooks } = usePopup();
  const { getAccessToken } = useToken();
  const [name, setName] = useState('');
  const [amount, setAmount] = useState(0);
  const [condition, setCondition] = useState(0);
  const [limitDate, setLimitDate] = useState<string | null>(null);
  const [isInfinite, setIsInfinite] = useState(false);

  const onEditCouponClicked = useCallback(async () => {
    if (!isInfinite && !limitDate) {
      alertMessage('기간을 입력해주세요');
      return;
    }

    const bodyObj: any = {
      amount: amount,
      condition: condition,
      name,
    };
    if (!isInfinite) bodyObj['limitDate'] = getEndOfDate(limitDate!);

    const {
      data,
      config: { status },
    } = await requestSecurePatch<CouponTypes>(
      apiRoute.coupon.editStoreCoupons + coupon?.couponUid,
      {},
      bodyObj,
      getAccessToken()!,
    );

    if (status !== 202) {
      alertMessage('쿠폰 수정에 실패했습니다.');
      __hidePopupFromHooks();
      return;
    }

    __hidePopupFromHooks();
  }, [
    coupon,
    limitDate,
    name,
    amount,
    condition,
    isInfinite,
    getAccessToken,
    __hidePopupFromHooks,
  ]);

  const onAddCouponClicked = useCallback(async () => {
    if (!isInfinite && !limitDate) {
      alertMessage('기간을 입력해주세요');
      return;
    }

    const url = storeUid
      ? apiRoute.coupon.addStoreCoupons + storeUid
      : userUid
      ? apiRoute.coupon.addUsersCoupons + userUid
      : apiRoute.coupon.addCoupon;

    const bodyObj: any = {
      amount: amount,
      condition: condition,
      name,
    };
    if (!isInfinite) bodyObj['limitDate'] = getEndOfDate(limitDate!);

    const {
      data,
      config: { status },
    } = await requestSecurePost<CouponTypes>(
      url,
      {},
      bodyObj,
      getAccessToken()!,
    );

    if (status !== 201) {
      alertMessage('쿠폰 저장에 실패했습니다.');
      __hidePopupFromHooks();
      return;
    }

    __hidePopupFromHooks();
  }, [
    userUid,
    storeUid,
    limitDate,
    name,
    amount,
    condition,
    isInfinite,
    getAccessToken,
    __hidePopupFromHooks,
  ]);

  const onCancelClicked = useCallback(() => {
    __hidePopupFromHooks();
  }, [__hidePopupFromHooks]);

  return (
    <AddCouponPopup
      coupon={coupon}
      setName={setName}
      setAmount={setAmount}
      setCondition={setCondition}
      setLimitDate={setLimitDate}
      isInfinit={isInfinite}
      setIsInfinite={setIsInfinite}
      onEditCouponClicked={onEditCouponClicked}
      onAddCouponClicked={onAddCouponClicked}
      onCancelClicked={onCancelClicked}
    />
  );
};

export default AddCouponPopupContainer;
