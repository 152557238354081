import CalcContainer from '@components/Calc/containers/CalcContainer';
import CalcDetailContainer from '@components/Calc/containers/CalcDetailContainer';
import CategoryContainer from '@components/Category/containers/CategoryContainer';
import GNBContainer from '@components/common/GNB/containers/GNBContainer';
import CouponAddContainer from '@components/Coupon/containers/CouponAddContainer';
import CouponContainer from '@components/Coupon/containers/CouponContainer';
import CouponDetailContainer from '@components/Coupon/containers/CouponDetailContainer';
import FAQAddContainer from '@components/FAQ/containers/FAQAddContainer';
import FAQContainer from '@components/FAQ/containers/FAQContainer';
import FAQDetailContainer from '@components/FAQ/containers/FAQDetailContainer';
import ItemContainer from '@components/Item/containers/ItemContainer';
import ItemDetailContainer from '@components/Item/containers/ItemDetailContainer';
import MainBannerAddContainer from '@components/MainBanner/containers/MainBannerAddContainer';
import MainBannerContainer from '@components/MainBanner/containers/MainBannerContainer';
import MainBannerDetailContainer from '@components/MainBanner/containers/MainBannerDetailContainer';
import NotFound from '@components/NotFound/NotFound';
import EventAddContainer from '@components/NoticeEvent/containers/EventAddContainer';
import EventDetailContainer from '@components/NoticeEvent/containers/EventDetailContainer';
import NoticeAddContainer from '@components/NoticeEvent/containers/NoticeAddContainer';
import NoticeDetailContainer from '@components/NoticeEvent/containers/NoticeDetailContainer';
import NoticeEventContainer from '@components/NoticeEvent/containers/NoticeEventContainer';
import OrderContainer from '@components/Order/containers/OrderContainer';
import OrderDetailContainer from '@components/Order/containers/OrderDetailContainer';
import PartnersContainer from '@components/Partners/containers/PartnersContainer';
import PartnersDetailContainer from '@components/Partners/containers/PartnersDetailContainer';
import SignupRequestDetailContainer from '@components/Partners/containers/SignupRequestDetailContainer';
import UpdateRequestDetailContainer from '@components/Partners/containers/UpdateRequestDetailContainer';
import PointContainer from '@components/Point/containers/PointContainer';
import QrPointContainer from '@components/QrPoint/containers/QrPointContainer';
import SaleItemContainer from '@components/SaleItem/containers/SaleItemContainer';
import SaleItemDetailContainer from '@components/SaleItem/containers/SaleItemDetailContainer';
import UserContainer from '@components/User/containers/UserConatiner';
import UserDetailContainer from '@components/User/containers/UserDetailContainer';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import '../styles/main.navigation.styles.css';

type Props = {
  visible: boolean;
  child: React.ReactNode;
  onBackdropClicked: () => void;
};

const MainNavigation = ({ visible, child, onBackdropClicked }: Props) => {
  return (
    <div className='main-navitaion-root'>
      <GNBContainer />
      <div className='content'>
        <Routes>
          <Route>
            <Route path='/user' element={<UserContainer />} />
            <Route path='/user/detail' element={<UserDetailContainer />} />
          </Route>
          <Route>
            <Route path='/item' element={<ItemContainer />} />
            <Route path='/item/detail' element={<ItemDetailContainer />} />
          </Route>
          <Route>
            <Route path='/saleItem' element={<SaleItemContainer />} />
            <Route
              path='/saleItem/detail'
              element={<SaleItemDetailContainer />}
            />
          </Route>
          <Route>
            <Route path='/itemCategory' element={<CategoryContainer />} />
          </Route>
          <Route>
            <Route path='/partners' element={<PartnersContainer />} />
            <Route
              path='/partners/detail'
              element={<PartnersDetailContainer />}
            />
            <Route
              path='/partners/signupDetail'
              element={<SignupRequestDetailContainer />}
            />
            <Route
              path='/partners/updateDetail'
              element={<UpdateRequestDetailContainer />}
            />
          </Route>
          <Route>
            <Route path='/order' element={<OrderContainer />} />
            <Route path='/order/detail' element={<OrderDetailContainer />} />
          </Route>
          <Route>
            <Route path='/calculate' element={<CalcContainer />} />
            <Route path='/calculate/detail' element={<CalcDetailContainer />} />
          </Route>
          <Route>
            <Route path='qrPoint' element={<QrPointContainer />} />
          </Route>
          <Route>
            <Route path='/adminCoupon' element={<CouponContainer />} />
            <Route
              path='/adminCoupon/detail'
              element={<CouponDetailContainer />}
            />
            <Route path='/adminCoupon/add' element={<CouponAddContainer />} />
          </Route>
          <Route>
            <Route path='/point' element={<PointContainer />} />
          </Route>
          <Route>
            <Route path='/mainBanner' element={<MainBannerContainer />} />
            <Route
              path='/mainBanner/add'
              element={<MainBannerAddContainer />}
            />
            <Route
              path='/mainBanner/detail'
              element={<MainBannerDetailContainer />}
            />
          </Route>
          <Route>
            <Route path='/faq' element={<FAQContainer />} />
            <Route path='/faq/add' element={<FAQAddContainer />} />
            <Route path='/faq/detail' element={<FAQDetailContainer />} />
          </Route>
          <Route>
            <Route path='/noticeEvent' element={<NoticeEventContainer />} />
            <Route
              path='/noticeEvent/noticeAdd'
              element={<NoticeAddContainer />}
            />
            <Route
              path='/noticeEvent/noticeDetail'
              element={<NoticeDetailContainer />}
            />
            <Route
              path='/noticeEvent/eventAdd'
              element={<EventAddContainer />}
            />
            <Route
              path='/noticeEvent/eventDetail'
              element={<EventDetailContainer />}
            />
          </Route>
          <Route path='*' element={<NotFound />} />
        </Routes>
      </div>
      {visible && (
        <div
          className='popup-backdrop'
          onClick={(e) => {
            if (e.target !== e.currentTarget) return;
            onBackdropClicked();
          }}>
          {child}
        </div>
      )}
    </div>
  );
};

export default MainNavigation;
