import useToken from '@hooks/store/useToken';
import { useTab } from '@hooks/useTab';
import { alertMessage } from '@libs/alertMessage';
import { apiRoute, requestSecureGet } from '@libs/api/api';

import {
  GetPartnersListResponseTypes,
  PartnersCategoryListItemTypes,
  PartnersListItemTypes,
  PartnersQueryParamsTypes,
} from '@typedef/components/Partners/partners.types';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PartnersList from '../components/PartnersList';

const PartnersListContainer = () => {
  const navigate = useNavigate();
  const { tab, queryParams, updateQueryParamsFromSession } =
    useTab<PartnersQueryParamsTypes>('partners');

  const { getAccessToken } = useToken();

  const [partners, setPartners] = useState<PartnersListItemTypes[]>([]);
  const [maxPage, setMaxPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(
    (tab === 'partners' && queryParams.page) || 0,
  );
  const [shopCategory, setShopCategory] = useState<
    PartnersCategoryListItemTypes[]
  >([]);
  const [selectedCategory, setSelectedCategory] =
    useState<PartnersCategoryListItemTypes | null>(
      (tab === 'partners' && queryParams.selectedCategory) || null,
    );
  const [searchInput, setSearchInput] = useState(
    (tab === 'partners' && queryParams.searchInput) || '',
  );
  const [isLoaded, setIsLoaded] = useState(false);

  const onSearchInputChanged = useCallback((value: string) => {
    setSearchInput(value);
  }, []);

  const loadPartners = useCallback(async () => {
    const { data, config } =
      await requestSecureGet<GetPartnersListResponseTypes>(
        apiRoute.partners.getPartners +
          `?page=${currentPage}&perPage=${10}&allowSignIn=1` +
          `${searchInput ? `&q=${searchInput}` : ''}` +
          `${
            selectedCategory && selectedCategory.targetTagUid !== -1
              ? `&targetTagUid=${selectedCategory.targetTagUid}`
              : ''
          }`,
        {},
        getAccessToken()!,
      );

    if (config.status === 200) {
      setMaxPage(data.maxPage);
      setPartners(data.rows);
    } else {
      alertMessage('목록을 불러오지 못했습니다');
    }
  }, [getAccessToken, currentPage, searchInput, selectedCategory]);

  const onPageChanged = useCallback((page: number) => {
    setCurrentPage(page);
  }, []);

  const onPartnersItemClicked = useCallback(
    (item: PartnersListItemTypes) => {
      navigate('detail', {
        state: {
          partnersUid: item.partnersUid,
          isEditable: true,
          type: 'detail',
        },
      });
    },
    [navigate, searchInput, selectedCategory, currentPage],
  );

  const loadCategory = useCallback(async () => {
    const { data, config } = await requestSecureGet<
      PartnersCategoryListItemTypes[]
    >(apiRoute.partners.tags.getTags, {}, getAccessToken()!);

    if (config.status === 200) {
      setShopCategory([
        {
          targetTagUid: -1,
          name: '전체보기',
          createDate: '',
          updateDate: '',
          deleteDate: null,
        } as PartnersCategoryListItemTypes,
        ...data,
      ]);
    } else {
      alertMessage('목록을 불러오지 못했습니다');
    }
  }, []);

  const onSearchBtnClicked = useCallback(() => {
    currentPage === 0 ? loadPartners() : setCurrentPage(0);
  }, [loadPartners, currentPage]);

  useEffect(() => {
    loadCategory();
  }, []);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (isLoaded) {
      timeout = setTimeout(() => {
        onSearchBtnClicked();
      }, 1000);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [searchInput]);

  useEffect(() => {
    loadPartners();
  }, [selectedCategory, currentPage]);

  useEffect(() => {
    updateQueryParamsFromSession('partners', {
      page: currentPage,
      searchInput,
      selectedCategory,
    });
  }, [currentPage, searchInput, selectedCategory]);

  return (
    <PartnersList
      selectedCategory={selectedCategory}
      searchInput={searchInput}
      onSearchInputChanged={onSearchInputChanged}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      partners={partners}
      maxPage={maxPage}
      onPageChanged={onPageChanged}
      onPartnersItemClicked={onPartnersItemClicked}
      shopCategory={shopCategory}
      onSearchBtnClicked={onSearchBtnClicked}
      setSelectedCategory={setSelectedCategory}
    />
  );
};

export default PartnersListContainer;
