import usePopup from '@hooks/store/usePopup';
import MainNavigation from '@routes/components/MainNavigation';
import React, { useEffect } from 'react';
import { useCallback } from 'react';

const id = 'daum-postcode';
const src = '//t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js';

const MainNavigationContainer = () => {
  const { visible, child, __hidePopupFromHooks } = usePopup();

  const onBackdropClicked = useCallback(() => {
    __hidePopupFromHooks();
  }, [__hidePopupFromHooks]);

  useEffect(() => {
    const isAlready = document.getElementById(id);

    if (!isAlready) {
      const script = document.createElement('script');
      script.src = src;
      script.id = id;
      document.body.append(script);
    }
  }, []);

  return (
    <MainNavigation
      visible={visible}
      child={child}
      onBackdropClicked={onBackdropClicked}
    />
  );
};

export default MainNavigationContainer;
