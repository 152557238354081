import useToken from '@hooks/store/useToken';
import { alertMessage } from '@libs/alertMessage';
import {
  apiRoute,
  BasicApiResponse,
  BasicListDataType,
  requestSecureDelete,
  requestSecureGet,
  requestSecurePatch,
  requestSecurePost,
} from '@libs/api/api';
import { stringToInputDate } from '@libs/factory';
import {
  UserDetailTabTypes,
  UserDetailTypes,
  UserGenderOptionTypes,
  UserGenderTypes,
  UserListItemTypes,
  UserPaymentListItemTypes,
} from '@typedef/components/User/user.types';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import UserDetail from '../components/UserDetail';

const UserDetailContainer = () => {
  const navigate = useNavigate();
  const { item } = useLocation().state as {
    item: UserListItemTypes;
  };

  const { getAccessToken } = useToken();

  const [userDetail, setUserDetail] = useState<UserDetailTypes | null>(null);
  const [tab, setTab] = useState<UserDetailTabTypes>('payment');
  const [tmpPoint, setTmpPoint] = useState(0);

  const loadDetail = useCallback(async () => {
    const { data, config } = await requestSecureGet<UserDetailTypes>(
      apiRoute.user.detail + item.userUid,
      {},
      getAccessToken()!,
    );

    if (config.status === 200) {
      setUserDetail({
        ...data,
        gender: data.gender ? data.gender : 'none',
        birth: data.birth ? stringToInputDate(data.birth) : '',
      });
    } else {
      alertMessage('유저 상세 정보를 불러오지 못했습니다');
    }
  }, []);

  const onTabCahnged = (value: UserDetailTabTypes) => {
    setTab(value);
  };

  const onInputChanged = useCallback(
    (key: keyof UserDetailTypes, value: string | number | UserGenderTypes) => {
      setUserDetail((prev) => {
        return prev ? { ...prev, [key]: value } : null;
      });
    },
    [],
  );

  const onTmpPointChanged = useCallback((value: number) => {
    setTmpPoint(value);
  }, []);

  const onPointAddBtnClicked = useCallback(async () => {
    if (userDetail?.point! + tmpPoint < 0) {
      alertMessage('발행 결과의 포인트는 0보다 작을 수 없습니다');
      return;
    }

    const { config } = await requestSecurePost(
      apiRoute.user.addPoint,
      {},
      {
        userUid: item.userUid,
        point: tmpPoint,
      },
      getAccessToken()!,
    );

    if (config.status === 201) {
      alertMessage('포인트가 발행 되었습니다');
      setUserDetail((prev) => {
        return prev ? { ...prev, point: prev.point + tmpPoint } : null;
      });
      setTmpPoint(0);
    } else {
      alertMessage('포인트 발행에 실패 했습니다');
    }
  }, [tmpPoint, item, userDetail]);

  const onSaveBtnClicked = useCallback(async () => {
    if (!userDetail) return;

    const {
      data,
      config: { status },
    } = await requestSecurePatch<{}>(
      apiRoute.user.detail + item.userUid,
      {},
      {
        name: userDetail.name,
        phone: userDetail.phone,
        ...(userDetail.gender !== 'none' && { gender: userDetail.gender }),
        ...(userDetail.birth !== '' && {
          birth: userDetail.birth.split('-').join(''),
        }),
        email: userDetail.email,
      },
      getAccessToken()!,
    );

    if (status !== 202) {
      alertMessage('유저 정보 저장에 실패했습니다.');
    }

    navigate(-1);
  }, [userDetail, navigate]);

  const onDeleteUserBtnClicked = useCallback(async () => {
    const willBeDeleted = window.confirm('정말로 삭제하시겠습니까?');

    if (!willBeDeleted) return;

    const {
      data,
      config: { status },
    } = await requestSecureDelete<BasicApiResponse<{}>>(
      apiRoute.user.deleteUser + item.userUid,
      {},
      getAccessToken()!,
    );

    if (status !== 202) {
      alertMessage('사용자 삭제에 실패했습니다.');
      return;
    }

    navigate(-1);
  }, [item]);

  useEffect(() => {
    loadDetail();
  }, [loadDetail]);

  return userDetail ? (
    <UserDetail
      item={item}
      userDetail={userDetail}
      tab={tab}
      tmpPoint={tmpPoint}
      onInputChanged={onInputChanged}
      onTabCahnged={onTabCahnged}
      onTmpPointChanged={onTmpPointChanged}
      onPointAddBtnClicked={onPointAddBtnClicked}
      onSaveBtnClicked={onSaveBtnClicked}
      onDeleteUserBtnClicked={onDeleteUserBtnClicked}
    />
  ) : (
    <div className='content-root'></div>
  );
};

export default UserDetailContainer;
