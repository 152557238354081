import CPagingContainer from '@components/common/CPaging/containers/CPagingContainer';
import { formatDate } from '@libs/factory';
import { PaymentListItemTypes } from '@typedef/components/Order/order.types';
import { PartnersPaymentsTypes } from '@typedef/components/Partners/partners.types';
import React from 'react';
import '../styles/partners.detail.payment.styles.css';

type Props = {
  paymentList: PaymentListItemTypes[];
  maxPage: number;
  currentPage: number;
  onPageChanged: (page: number) => void;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
};

const PartnersDetailPayment = ({
  paymentList,
  maxPage,
  onPageChanged,
  currentPage,
  setCurrentPage,
}: Props) => {
  return (
    <div className='content-root detail-payment'>
      <div className='table-container'>
        <span className='table-title'>결제내역</span>
        <div className='header-section'>
          <div className='item payment-uid'>주문번호</div>
          <div className='item'>결제일시</div>
          <div className='item'>구매자</div>
          <div className='item'>상품명</div>
          <div className='item'>결제금액</div>
        </div>
        <div className='body-section'>
          {paymentList.map((item, idx) => {
            const deliveryFee =
              item.deliveryFees?.filter(
                (fee) => fee.storeUid === item.orders[0].store.storeUid,
              ) ?? [];

            return (
              <div key={idx} className='row'>
                <div className='item payment-uid'>{item.merchantUid}</div>
                <div className='item'>{`${formatDate(
                  item.createDate,
                )} ${formatDate(item.createDate)}`}</div>
                <div className='item'>{item.orders[0].orderUid}</div>
                <div className='item'>{`${item.orders[0].product.name} ${
                  item.orders.length > 1 ? `외 ${item.orders.length - 1}건` : ``
                }`}</div>
                <div className='item'>
                  {(
                    item.orders.reduce((prev, current) => {
                      return (prev += current.amount);
                    }, 0) + (deliveryFee.length > 0 ? deliveryFee[0].amount : 0)
                  ).toLocaleString()}
                </div>
              </div>
            );
          })}
        </div>
        <div className='footer-section'>
          <CPagingContainer
            maxPage={maxPage}
            page={currentPage}
            setPage={setCurrentPage}
          />
        </div>
      </div>
    </div>
  );
};

export default PartnersDetailPayment;
