import useToken from '@hooks/store/useToken';
import { useTab } from '@hooks/useTab';
import { alertMessage } from '@libs/alertMessage';
import { apiRoute, BasicListDataType, requestSecureGet } from '@libs/api/api';

import {
  GetPartnersListResponseTypes,
  PartnersCategoryListItemTypes,
  PartnersListItemTypes,
  PartnersReqCategoryTypes,
  PartnersUpdateQueryParamsTypes,
  PartnersUpdateReqCategoryTypes,
  StoreTypes,
} from '@typedef/components/Partners/partners.types';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import UpdateRequestList from '../components/UpdateRequestList';

const UpdateRequestListContainer = () => {
  const navigate = useNavigate();
  const { tab, queryParams, updateQueryParamsFromSession } =
    useTab<PartnersUpdateQueryParamsTypes>('partners');

  const { getAccessToken } = useToken();

  const [stores, setStores] = useState<StoreTypes[]>([]);
  const [maxPage, setMaxPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(
    (tab === 'update-req' && queryParams.page) || 0,
  );
  const [shopCategory, setShopCategory] = useState<
    PartnersCategoryListItemTypes[]
  >([]);
  const [selectedCategory, setSelectedCategory] =
    useState<PartnersCategoryListItemTypes | null>(
      (tab === 'update-req' && queryParams.selectedCategory) || null,
    );
  const [selectedReqCategory, setSelectedReqCategory] = useState<string>(
    (tab === 'update-req' && queryParams.selectedReqCategory) || 'PENDING',
  );
  const [requestCategory, setRequestCategory] = useState<
    PartnersUpdateReqCategoryTypes[]
  >([
    { label: '수정요청', state: 'PENDING' },
    { label: '수정승인', state: 'FULFILLED' },
    { label: '수정거절', state: 'REJECTED' },
  ]);
  const [searchInput, setSearchInput] = useState(
    (tab === 'update-req' && queryParams.searchInput) || '',
  );

  const onSearchInputChanged = useCallback((value: string) => {
    setSearchInput(value);
  }, []);

  const [isLoaded, setIsLoaded] = useState(false);

  const loadPartners = useCallback(async () => {
    const {
      data,
      config: { status },
    } = await requestSecureGet<BasicListDataType<StoreTypes>>(
      apiRoute.store.getUpdateStoreList +
        `?page=${currentPage}&perPage=${10}` +
        `${searchInput ? `&q=${searchInput}` : ''}` +
        `${'&state=' + selectedReqCategory}` +
        `${
          selectedCategory && selectedCategory.targetTagUid !== -1
            ? '&targetTagUid=' + selectedCategory.targetTagUid
            : ''
        }`,
      {},
      getAccessToken()!,
    );

    if (status !== 200) {
      alertMessage('목록 조회에 실패했습니다.');
      return;
    }

    setMaxPage(data.maxPage);
    setStores(data.rows);
  }, [selectedReqCategory, selectedCategory, currentPage, searchInput]);

  const onPageChanged = useCallback((page: number) => {
    setCurrentPage(page);
  }, []);

  const onPartnersItemClicked = useCallback(
    (item: StoreTypes) => {
      navigate('updateDetail', {
        state: {
          stores: item,
          status: selectedReqCategory,
        },
      });
    },
    [navigate, , selectedReqCategory, ,],
  );

  const loadCategory = useCallback(async () => {
    const { data, config } = await requestSecureGet<
      PartnersCategoryListItemTypes[]
    >(apiRoute.partners.tags.getTags, {}, getAccessToken()!);

    if (config.status === 200) {
      setShopCategory([
        {
          targetTagUid: -1,
          name: '전체보기',
          createDate: '',
          updateDate: '',
          deleteDate: null,
        } as PartnersCategoryListItemTypes,
        ...data,
      ]);
    } else {
      alertMessage('목록을 불러오지 못했습니다');
    }
  }, []);

  const onSearchBtnClicked = useCallback(() => {
    currentPage === 0 ? loadPartners() : setCurrentPage(0);
  }, [currentPage, loadPartners]);

  useEffect(() => {
    loadCategory();
  }, []);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (isLoaded) {
      timeout = setTimeout(() => {
        onSearchBtnClicked();
      }, 1000);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [searchInput]);

  useEffect(() => {
    loadPartners();
  }, [selectedCategory, currentPage, selectedReqCategory]);

  useEffect(() => {
    updateQueryParamsFromSession('update-req', {
      page: currentPage,
      searchInput,
      selectedCategory,
      selectedReqCategory,
    });
  }, [currentPage, searchInput, selectedCategory, selectedReqCategory]);

  return (
    <UpdateRequestList
      stores={stores}
      maxPage={maxPage}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      onPageChanged={onPageChanged}
      searchInput={searchInput}
      onSearchInputChanged={onSearchInputChanged}
      onPartnersItemClicked={onPartnersItemClicked}
      shopCategory={shopCategory}
      onSearchBtnClicked={onSearchBtnClicked}
      setSelectedCategory={setSelectedCategory}
      requestCategory={requestCategory}
      selectedCategory={selectedCategory}
      selectedReqCategory={selectedReqCategory}
      setSelectedReqCategory={setSelectedReqCategory}
    />
  );
};

export default UpdateRequestListContainer;
