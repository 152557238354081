import useRootRoute from '@hooks/routes/useRootRoute';
import useToken from '@hooks/store/useToken';
import { alertMessage } from '@libs/alertMessage';
import { apiRoute, requestPost } from '@libs/api/api';
import { AuthTypes } from '@typedef/components/common/auth.types';
import React, { useCallback, useState } from 'react';
import Login from '../Login';

const LoginContainer = () => {
  const { setAccessToken, setRefreshToken } = useToken();
  const { setRootRoute } = useRootRoute();

  const [id, setId] = useState('');
  const [pw, setPw] = useState('');

  const onLoginClicked = useCallback(async () => {
    const { config, data } = await requestPost<AuthTypes>(
      apiRoute.auth.login,
      {},
      {
        adminName: id,
        password: pw,
      },
    );

    if (config.status === 200) {
      setAccessToken(data.accessToken);
      setRefreshToken(data.refreshToken);

      setRootRoute('main');

      window.location.reload();
    } else {
      alertMessage('로그인에 실패했습니다');
    }
  }, [id, pw]);

  const onCheckEnter = useCallback((e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      onLoginClicked();
    }
  }, []);

  return (
    <Login
      id={id}
      pw={pw}
      setId={setId}
      setPw={setPw}
      onLoginClicked={onLoginClicked}
      onCheckEnter={onCheckEnter}
    />
  );
};

export default LoginContainer;
