import useToken from '@hooks/store/useToken';
import { useTab } from '@hooks/useTab';
import { alertMessage } from '@libs/alertMessage';
import {
  apiRoute,
  BasicApiResponse,
  BasicListDataType,
  requestSecureGet,
} from '@libs/api/api';
import { getEndOfDate, getStartOfDate } from '@libs/factory';
import { TabItemTypes } from '@typedef/components/common/GNB/gnb.types';
import {
  UserGenderOptionTypes,
  UserGenderTypes,
  UserListItemTypes,
  UserQueryParamsTypes,
} from '@typedef/components/User/user.types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { RefObject } from 'react';
import { useNavigate } from 'react-router-dom';
import User from '../User';

const UserContainer = () => {
  const { queryParams, updateQueryParamsFromSession } =
    useTab<UserQueryParamsTypes>('user');
  const navigate = useNavigate();
  const { getAccessToken } = useToken();

  const [userList, setUserList] = useState<UserListItemTypes[]>([]);
  const [maxPage, setMaxPage] = useState(1);
  const [searchInput, setSearchInput] = useState(queryParams.searchInput || '');
  const [createDate, setCreateDate] = useState(
    queryParams.searchCreateDate || '',
  );
  const [genderOptions, setGenderOptions] = useState<UserGenderOptionTypes>([
    { label: '전체보기', value: 'none' },
    { label: '여성', value: 'F' },
    { label: '남성', value: 'M' },
  ]);
  const [searchGender, setSearchGender] = useState<UserGenderTypes>(
    queryParams.searchGender || 'none',
  );
  const [currentPage, setCurrentPage] = useState(queryParams.page || 0);
  const [isLoaded, setIsLoaded] = useState(false);

  const loadList = useCallback(async () => {
    const { data, config } = await requestSecureGet<
      BasicListDataType<UserListItemTypes>
    >(
      apiRoute.user.getUser +
        `?page=${currentPage}&perPage=${10}${
          searchGender !== 'none' ? `&gender=${searchGender}` : ''
        }${
          createDate
            ? `&from=${getStartOfDate(createDate)}&to=${getEndOfDate(
                createDate,
              )}`
            : ''
        }${searchInput ? `&q=${searchInput}` : ''}`,
      {},
      getAccessToken()!,
    );

    if (config.status === 200) {
      setUserList(data.rows);
      setMaxPage(data.maxPage);
      setIsLoaded(true);
    } else {
      alertMessage('목록을 불러오지 못했습니다');
    }
  }, [getAccessToken, currentPage, searchInput, searchGender, createDate]);

  const onSearchBtnClicked = useCallback(() => {
    currentPage === 0 ? loadList() : setCurrentPage(0);
  }, [currentPage, loadList]);

  const onListItemClicked = useCallback(
    (item: UserListItemTypes) => {
      navigate('detail', {
        state: {
          item,
        },
      });
    },
    [navigate],
  );

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (isLoaded) {
      timeout = setTimeout(() => {
        onSearchBtnClicked();
      }, 1000);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [searchInput]);

  useEffect(() => {
    loadList();
  }, [createDate, searchGender, currentPage]);

  useEffect(() => {
    updateQueryParamsFromSession('', {
      page: currentPage,
      searchGender,
      searchCreateDate: createDate,
      searchInput,
    });
  }, [
    updateQueryParamsFromSession,
    currentPage,
    createDate,
    searchGender,
    searchInput,
  ]);

  return (
    <User
      userList={userList}
      maxPage={maxPage}
      searchInput={searchInput}
      createDate={createDate}
      searchGender={searchGender}
      genderOptions={genderOptions}
      setSearchInput={setSearchInput}
      setCreateDate={setCreateDate}
      setSearchGender={setSearchGender}
      onListItemClicked={onListItemClicked}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      onSearchBtnClicked={onSearchBtnClicked}
    />
  );
};

export default UserContainer;
