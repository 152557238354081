import useToken from '@hooks/store/useToken';
import { alertMessage } from '@libs/alertMessage';
import { apiRoute, requestSecurePost } from '@libs/api/api';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MainBannerAdd from '../components/MainBannerAdd';

const MainBannerAddContainer = () => {
  const navigate = useNavigate();
  const { getAccessToken } = useToken();

  const [title, setTitle] = useState('');
  const [bannerFile, setBannerFile] = useState<File | null>(null);
  const [thumbnail, setThumbnail] = useState<File | null>(null);
  const [desc, setDesc] = useState('');
  const [preview, setPreview] = useState('');

  const thumbnailImage = useMemo(() => {
    return thumbnail ? URL.createObjectURL(thumbnail as Blob) : '';
  }, [thumbnail]);

  const bannerFileImage = useMemo(() => {
    return bannerFile ? URL.createObjectURL(bannerFile as Blob) : '';
  }, [bannerFile]);

  const onPreviewBtnClicked = useCallback(
    (type: 'thumbnail' | 'banner') => {
      if (type === 'thumbnail') {
        setPreview(thumbnailImage);
      } else {
        setPreview(bannerFileImage);
      }
    },
    [thumbnailImage, bannerFileImage],
  );

  const onThumbnailChanged = useCallback((file: File) => {
    setThumbnail(file);
  }, []);

  const onBannerFileChanged = useCallback((file: File) => {
    setBannerFile(file);
  }, []);

  const onTitleChanged = useCallback((value: string) => {
    setTitle(value);
  }, []);

  const onDescChanged = useCallback((value: string) => {
    setDesc(value);
  }, []);

  const onCancelClicked = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const onAddBtnClicked = useCallback(async () => {
    if (!thumbnail || !bannerFile) {
      alertMessage('이미지를 선택해주세요');
      return;
    }

    const form = new FormData();

    form.append('title', title);
    form.append('description', desc);

    form.append('thumbnail', thumbnail as Blob);
    form.append('bannerFile', bannerFile as Blob);

    const { config } = await requestSecurePost(
      apiRoute.banners.addBanners,
      {
        'Content-Type': 'multipart/form-data',
      },
      form,
      getAccessToken()!,
    );

    if (config.status === 201) {
      navigate(-1);
    } else {
      alertMessage('배너등록에 실패 했습니다');
    }
  }, [title, thumbnail, bannerFile, desc, navigate]);

  useEffect(() => {
    setPreview(thumbnailImage);
  }, [thumbnailImage]);

  useEffect(() => {
    setPreview(bannerFileImage);
  }, [bannerFileImage]);

  return (
    <MainBannerAdd
      title={title}
      desc={desc}
      preview={preview}
      thumbnail={thumbnail}
      thumbnailImage={thumbnailImage}
      bannerFile={bannerFile}
      bannerFileImage={bannerFileImage}
      onThumbnailChanged={onThumbnailChanged}
      onBannerFileChanged={onBannerFileChanged}
      onTitleChanged={onTitleChanged}
      onDescChanged={onDescChanged}
      onPreviewBtnClicked={onPreviewBtnClicked}
      onCancelClicked={onCancelClicked}
      onAddBtnClicked={onAddBtnClicked}
    />
  );
};

export default MainBannerAddContainer;
