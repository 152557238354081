import useToken from '@hooks/store/useToken';
import { useTab } from '@hooks/useTab';
import { alertMessage } from '@libs/alertMessage';
import {
  apiRoute,
  BasicListDataType,
  requestGet,
  requestSecureGet,
} from '@libs/api/api';

import {
  ItemQueryParamsTypes,
  ProductTypes,
} from '@typedef/components/Item/item.types';
import React from 'react';
import { useMemo } from 'react';
import { useEffect } from 'react';
import { useCallback } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Item from '../Item';

const itemCnt = 10;

const ItemContainer = () => {
  const { getAccessToken } = useToken();
  const navigate = useNavigate();
  const { queryParams, updateQueryParamsFromSession } =
    useTab<ItemQueryParamsTypes>('item');

  const [productList, setProductList] = useState<ProductTypes[]>([]);
  const [page, setPage] = useState(queryParams.page || 0);
  const [maxPage, setMaxPage] = useState(1);
  const [searchInput, setSearchInput] = useState(queryParams.searchInput || '');

  const [selectedOption, setSelectedOption] = useState(
    queryParams.selectedOption || 0,
  );

  const [isLoaded, setIsLoaded] = useState(false);

  const onSearchInputChanged = useCallback((value: string) => {
    setSearchInput(value);
  }, []);

  const onSelectedOptionChanged = useCallback((value: number) => {
    setSelectedOption(value);
  }, []);

  const loadProductList = useCallback(async () => {
    const {
      data,
      config: { status },
    } = await requestSecureGet<BasicListDataType<ProductTypes>>(
      apiRoute.product.getProducts +
        `?page=${page}&perPage=${itemCnt}&isDiscountedProduct=${selectedOption}&isRecommendedProduct=0&orderProperty=createDate&orderValue=DESC${
          searchInput ? `&q=${searchInput}` : ''
        }`,
      {},
      getAccessToken()!,
    );

    if (status !== 200) {
      alertMessage('상품 목록을 불러오지 못했습니다.');
      return;
    }
    setIsLoaded(true);
    setProductList(data.rows);
    setMaxPage(data.maxPage);
  }, [page, selectedOption, searchInput]);

  const onSearchBtnClicked = useCallback(() => {
    page === 0 ? loadProductList() : setPage(0);
  }, [loadProductList, page]);

  const onItemClicked = useCallback(
    (productUid: number, idx: number) => {
      navigate('detail', {
        state: { productUid },
      });
    },
    [navigate, productList, page, searchInput, selectedOption],
  );

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (isLoaded) {
      timeout = setTimeout(() => {
        onSearchBtnClicked();
      }, 1000);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [searchInput]);

  useEffect(() => {
    loadProductList();
  }, [selectedOption, page]);

  useEffect(() => {
    updateQueryParamsFromSession('', {
      page,
      searchInput,
      selectedOption,
    });
  }, [page, searchInput, selectedOption]);

  return (
    <Item
      searchInput={searchInput}
      onSearchInputChanged={onSearchInputChanged}
      productList={productList}
      selectedOption={selectedOption}
      onSelectedOptionChanged={onSelectedOptionChanged}
      maxPage={maxPage}
      page={page}
      setPage={setPage}
      onSearchBtnClicked={onSearchBtnClicked}
      onItemClicked={onItemClicked}
    />
  );
};

export default ItemContainer;
