import image from '@components/common/assets/image';
import CInputContainer from '@components/common/CInput/containers/CInputContainer';
import Item from '@components/Item/Item';
import { StoreListItemTypes } from '@typedef/components/SaleItem/sale.item.types';
import React from 'react';
import '../styles/sale.item.detail.styles.css';

type Props = {
  store: StoreListItemTypes;
  discount: number;
  setDiscount: React.Dispatch<React.SetStateAction<number>>;
  onSaveBtnClicked: () => void;
  onBackBtnClicked: () => void;
};

const SaleItemDetail = ({
  store,
  discount,
  setDiscount,
  onSaveBtnClicked,
  onBackBtnClicked,
}: Props) => {
  return (
    <div className='content-root sale-item-detail-root'>
      <div className='title-container'>
        <div className='title-item'>
          <div className='title-label'>{'특가상품 관리 상세'}</div>
          <div className='subtitle-label'>
            {'특가 상품 관리 상세페이지 입니다'}
          </div>
        </div>
      </div>

      <div className='body-section'>
        <div className='row store-info'>
          <div className='item image'>
            <span className='item-title'>가게 사진</span>
            <img
              className='store-image'
              src={
                store.storeProfile
                  ? store.storeProfile[0]
                  : image.placeholderImg
              }
              alt='가게 사진'
              onError={(e) => {
                e.currentTarget.src = image.placeholderImg;
              }}
            />
          </div>
          <div className='item'>
            <span className='item-title'>가게명</span>
            {store.name}
          </div>
          <div className='item'>
            <span className='item-title'> 주소</span>
            {store.location}
          </div>
          <div className='item'>
            <span className='item-title'>특가 상품 수량</span>
            {store.discountedProductsCount}
          </div>
          <div className='item'>
            <span className='item-title'> 특가 상품 최대 수량 </span>
            {store.maxDiscountProductCount}
          </div>
        </div>

        <div className='row discount'>
          <div className='item'>
            <span className='item-title'>특가 상품 최대 수량 개별 설정</span>
            <input
              className='discount-input'
              type='text'
              value={discount || ''}
              onChange={(e) => {
                if (/[^0-9]/.test(e.target.value)) return;
                setDiscount(Number(e.target.value));
              }}
            />
          </div>
          <button className='primary-btn save-btn' onClick={onSaveBtnClicked}>
            저장하기
          </button>
        </div>
      </div>
    </div>
  );
};

export default SaleItemDetail;
