import CInputContainer from '@components/common/CInput/containers/CInputContainer';
import CPagingContainer from '@components/common/CPaging/containers/CPagingContainer';
import { formatDate } from '@libs/factory';
import {
  FaqListItemTypes,
  FqaTabTypes,
} from '@typedef/components/FAQ/faq.types';
import React from 'react';

type Props = {
  currentPage: number;
  onAddBtnClicked: () => void;
  setCategory: React.Dispatch<React.SetStateAction<string>>;
  onCategoryAddBtnClicked: () => void;
  maxPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  onPageChanged: (page: number) => void;
  faqList: FaqListItemTypes[];
  onListItemClicked: (item: FaqListItemTypes) => void;
  tab: FqaTabTypes;
  onTabClicked: (tab: FqaTabTypes) => void;
};

const FAQList = ({
  currentPage,
  onAddBtnClicked,
  setCategory,
  onCategoryAddBtnClicked,
  maxPage,
  onPageChanged,
  setCurrentPage,
  faqList,
  onListItemClicked,
  onTabClicked,
  tab,
}: Props) => {
  return (
    <div className='fqa-list-root table-container'>
      <div className='button-container'>
        <button className='primary-btn' onClick={onAddBtnClicked}>
          작성하기
        </button>
      </div>
      <div className='table-container'>
        <div className='header-section'>
          <div className='item'>카테고리</div>
          <div className='item'>제목</div>
          <div className='item'>게시일자</div>
        </div>
        <div className='body-section'>
          {faqList.map((item, idx) => (
            <div
              key={idx}
              className='row'
              onClick={() => onListItemClicked(item)}>
              <div className='item'>{item.faqCategory.name}</div>
              <div className='item title'>{item.title}</div>
              <div className='item'>{formatDate(item.createDate)}</div>
            </div>
          ))}
        </div>
        <div className='footer-section'>
          <CPagingContainer
            maxPage={maxPage}
            page={currentPage}
            setPage={setCurrentPage}
          />
        </div>
      </div>
    </div>
  );
};

export default FAQList;
