import useToken from '@hooks/store/useToken';
import { alertMessage } from '@libs/alertMessage';
import React, { useCallback, useState } from 'react';
import QrPoint from '../QrPoint';

const QrPointContainer = () => {
  const [searchInput, setSearchInput] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [keyword, setKeyword] = useState('');

  const SearchButtonClicked = useCallback(() => {
    setKeyword(searchInput);
  }, [searchInput]);

  return (
    <QrPoint
      startDate={startDate}
      endDate={endDate}
      setStartDate={setStartDate}
      setEndDate={setEndDate}
      searchInput={searchInput}
      setSearchInput={setSearchInput}
      keyword={keyword}
      SearchButtonClicked={SearchButtonClicked}
    />
  );
};

export default QrPointContainer;
