import useToken from '@hooks/store/useToken';
import { alertMessage } from '@libs/alertMessage';
import {
  apiRoute,
  requestGet,
  requestSecureDelete,
  requestSecurePatch,
} from '@libs/api/api';
import { formatDate, getEndOfDate, getStartOfDate } from '@libs/factory';
import { uriToFile } from '@libs/trans';
import { EventListItemTypes } from '@typedef/components/NoticeEvent/notice.event.types';
import React, { useCallback, useEffect, useState } from 'react';
import { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import EventDetail from '../components/EventDetail';

const EventDetailContainer = () => {
  const navigate = useNavigate();

  const { getAccessToken } = useToken();

  const { item } = useLocation().state as {
    item: EventListItemTypes;
  };

  const [eventFile, setEventFile] = useState<File | null>(null);
  const [referenceUrl, setReferenceUrl] = useState(item.referenceUrl ?? '');
  const [startDate, setStartDate] = useState(formatDate(item.startDate));
  const [endDate, setEndDate] = useState(formatDate(item.endDate));

  const eventFileUrl = useMemo(() => {
    return eventFile ? URL.createObjectURL(eventFile as Blob) : '';
  }, [eventFile]);

  const loadEventFile = useCallback(async () => {
    setEventFile(
      await uriToFile(item.eventFile ? item.eventFile[0] : '', 'image/*'),
    );
  }, [item]);

  const onEventFileChanged = useCallback((file: File) => {
    setEventFile(file);
  }, []);

  const onDeleteBtnClicked = useCallback(async () => {
    if (!window.confirm('정말로 삭제하시겠습니까?')) {
      return;
    }
    const { config } = await requestSecureDelete(
      apiRoute.events.deleteEvents + item.eventUid,
      {},
      getAccessToken()!,
    );

    if (config.status === 202) {
      alertMessage('이벤트가 삭제되었습니다');
      navigate(-1);
    } else {
      alertMessage('이벤트 삭제에 실패했습니다');
    }
  }, [item, navigate]);

  const onUpdateBtnClicked = useCallback(async () => {
    if (!startDate || !endDate) {
      alertMessage('게시기간을 입력해주세요');
      return;
    }

    const form = new FormData();

    form.append('startDate', getStartOfDate(startDate).toString());
    form.append('endDate', getEndOfDate(endDate).toString());
    referenceUrl && form.append('referenceUrl', referenceUrl);
    eventFile && form.append('eventFile', eventFile);

    const { config } = await requestSecurePatch(
      apiRoute.events.updateEvents + item.eventUid,
      {},
      form,
      getAccessToken()!,
    );

    if (config.status === 202) {
      navigate(-1);
    } else {
      alertMessage('공지사항 수정에 실패 했습니다');
    }
  }, [startDate, endDate, referenceUrl, eventFile, navigate]);

  useEffect(() => {
    loadEventFile();
  }, []);

  return (
    <EventDetail
      startDate={startDate}
      endDate={endDate}
      eventFile={eventFile}
      eventFileUrl={eventFileUrl}
      referenceUrl={referenceUrl}
      setStartDate={setStartDate}
      setEndDate={setEndDate}
      setEventFile={setEventFile}
      setReferenceUrl={setReferenceUrl}
      onEventFileChanged={onEventFileChanged}
      onDeleteBtnClicked={onDeleteBtnClicked}
      onUpdateBtnClicked={onUpdateBtnClicked}
    />
  );
};

export default EventDetailContainer;
