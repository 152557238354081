import CFileInputContainer from '@components/common/CFileInput/containers/CFileInputContainer';
import image from '@components/common/assets/image';
import CInputContainer from '@components/common/CInput/containers/CInputContainer';
import { MainBannerListItemTypes } from '@typedef/components/MainBanner/main.banner.types';
import React from 'react';
import '../styles/main.banner.detail.styles.css';
import MainBannerFileInput from './MainBannerFileInput';

type Props = {
  preview: string;
  data: MainBannerListItemTypes;
  title: string;
  desc: string;
  thumbnail: File | null;
  thumbnailImage: string;
  bannerFile: File | null;
  bannerFileImage: string;
  onTitleChanged: (value: string) => void;
  onDescChanged: (value: string) => void;
  onDeleteBtnClicked: () => void;
  onEditBtnClicked: () => void;
  onPreviewBtnClicked: (type: 'thumbnail' | 'banner') => void;
  onThumbnailChanged: (file: File) => void;
  onBannerFileChanged: (file: File) => void;
};

const MainBannerDetail = ({
  data,
  thumbnail,
  title,
  desc,
  bannerFile,
  preview,
  onDeleteBtnClicked,
  onBannerFileChanged,
  onThumbnailChanged,
  onDescChanged,
  onTitleChanged,
  onEditBtnClicked,
  onPreviewBtnClicked,
}: Props) => {
  return (
    <div className='main-banner-detail-root content-root'>
      <div className='title-container'>
        <div className='title-item'>
          <div className='title-label'>{data.title}</div>
          <div className='subtitle-label'>{data.title}</div>
        </div>
      </div>
      <div className='main-banner-detail-button-container'>
        <button className='delete-btn' onClick={onDeleteBtnClicked}>
          삭제하기
        </button>
        <button className='primary-btn' onClick={onEditBtnClicked}>
          수정하기
        </button>
      </div>
      <div className='main-banner-detail-input-container'>
        <div className='image-preview-section'>
          {preview ? (
            <img
              id='image-preview'
              className='image-placeholder'
              src={preview ?? ''}
              alt={data.title}
            />
          ) : (
            <div className='image-placeholder' />
          )}
        </div>
        <div className='input-section'>
          <div className='input-item'>
            <label className='input-label' htmlFor='main-banner-detail-title'>
              {'제목'}
            </label>
            <input
              type='text'
              id='main-banner-detail-title'
              placeholder='제목을 입력해주세요'
              value={title}
              onChange={(e) => onTitleChanged(e.target.value)}
            />
          </div>
          <div className='input-item'>
            <label className='input-label' htmlFor='main-banner-detail-desc'>
              {'설명'}
            </label>
            <textarea
              id='main-banner-detail-desc'
              placeholder='설명을 입력해주세요'
              value={desc}
              onChange={(e) => onDescChanged(e.target.value)}
            />
          </div>
          <div className='file-input-item'>
            <MainBannerFileInput
              label='썸네일'
              onFileChanged={onThumbnailChanged}
              id='thumbnail'
              fileName={thumbnail?.name}
              onPreviewBtnClicked={() => onPreviewBtnClicked('thumbnail')}
            />
          </div>
          <div className='file-input-item'>
            <MainBannerFileInput
              label='상세페이지'
              onFileChanged={onBannerFileChanged}
              id='banner-file'
              fileName={bannerFile?.name}
              onPreviewBtnClicked={() => onPreviewBtnClicked('banner')}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainBannerDetail;
