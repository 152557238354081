export async function uriToFile(
  uri: string,
  type?: string,
  idx?: string,
): Promise<File | null> {
  const response = await fetch(uri, {
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
      Origin: process.env.NODE_ENV === 'development' ? 'localhost:3000' : '',
    },
  }).catch(console.error);

  if (response) {
    const data = await response.blob();

    return new File([data], `${new Date().getTime()}${idx ?? 0}.png`, {
      type: type ?? 'image/png',
    });
  }

  return null;
}
