import React from 'react';

type Props = {
  label: string;
  id: string;
  fileName?: string;
  onFileChanged: (file: File) => void;
  onPreviewBtnClicked: () => void;
};

const MainBannerFileInput = ({
  label,
  id,
  fileName,
  onFileChanged,
  onPreviewBtnClicked,
}: Props) => {
  return (
    <>
      <span className='input-label'>{label}</span>
      <div className='file-input'>
        <label className='file-input-label' htmlFor={`main-banner-${id}`}>
          <div className='file-text'>{fileName ?? '파일을 선택해주세요'}</div>
          <div className='primary-btn select-btn'>{'선택'}</div>
        </label>
        <input
          type='file'
          id={`main-banner-${id}`}
          hidden
          accept='image/*'
          onChange={(e) => onFileChanged(e.target.files?.item(0) as File)}
        />
        <button
          className=' primary-btn preview-btn'
          onClick={onPreviewBtnClicked}>
          {'미리보기'}
        </button>
      </div>
    </>
  );
};

export default MainBannerFileInput;
