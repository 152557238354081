import usePopup from '@hooks/store/usePopup';
import useToken from '@hooks/store/useToken';
import { alertMessage } from '@libs/alertMessage';
import { apiRoute, requestSecurePatch } from '@libs/api/api';
import { uriToFile } from '@libs/trans';
import {
  PartnersUpdateReqCategoryTypes,
  PartnersUpdateReqStatusTypes,
  StoreTypes,
} from '@typedef/components/Partners/partners.types';
import React, { useState } from 'react';
import { useCallback } from 'react';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import StoreImagePopup from '../components/StoreImagePopup';
import UpdateRequestDetail from '../components/UpdateRequestDetail';

const UpdateRequestDetailContainer = () => {
  const navigate = useNavigate();
  const { getAccessToken } = useToken();
  const { stores, status } = useLocation().state as {
    stores: StoreTypes;
    status: PartnersUpdateReqStatusTypes;
  };
  const { __showPopupFromHooks } = usePopup();

  const onBackBtnClicked = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const onUpdateBtnClicked = useCallback(
    async (state: string) => {
      const {
        data,
        config: { status },
      } = await requestSecurePatch<{}>(
        apiRoute.store.patchUpdateStore + stores?.storeUid,
        {},
        { state },
        getAccessToken()!,
      );

      if (status !== 202) {
        alertMessage('요청에 실패했습니다.');
        return;
      }

      navigate(-1);
    },
    [navigate, stores],
  );

  const onImageClicked = useCallback(
    (src: string) => {
      __showPopupFromHooks(<StoreImagePopup imageSrc={src} />);
    },
    [__showPopupFromHooks],
  );

  return (
    <UpdateRequestDetail
      status={status}
      stores={stores}
      onUpdateBtnClicked={onUpdateBtnClicked}
      onBackBtnClicked={onBackBtnClicked}
      onImageClicked={onImageClicked}
    />
  );
};

export default UpdateRequestDetailContainer;
