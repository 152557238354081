import CMultiFileInputContainer from '@components/common/CFileInput/containers/CMultiFileInputContainer';
import CInputContainer from '@components/common/CInput/containers/CInputContainer';
import CSelectContainer from '@components/common/CSelect/containers/CSelectContainer';
import { NoticeOptionItemTypes } from '@typedef/components/NoticeEvent/notice.event.types';
import React from 'react';
import '../styles/notice.styles.css';

type Props = {
  content: string;
  setTitle: React.Dispatch<React.SetStateAction<string>>;
  setContent: React.Dispatch<React.SetStateAction<string>>;
  setFiles: React.Dispatch<React.SetStateAction<File[]>>;
  setTarget: React.Dispatch<React.SetStateAction<NoticeOptionItemTypes>>;
  targetOption: [NoticeOptionItemTypes, NoticeOptionItemTypes];
  onBackBtnClicked: () => void;
  onAddBtnClicked: () => void;
};

const NoticeAdd = ({
  content,
  setTitle,
  setContent,
  setFiles,
  setTarget,
  targetOption,
  onBackBtnClicked,
  onAddBtnClicked,
}: Props) => {
  return (
    <div className='content-root notice-root'>
      <div className='title-container'>
        <div className='title-item'>
          <div className='title-label'>{'공지사항 추가'}</div>
          <div className='subtitle-label'>{'공지사항 추가 페이지 입니다'}</div>
        </div>
      </div>
      <div className='button-container'>
        <button className='primary-btn' onClick={onBackBtnClicked}>
          뒤로가기
        </button>
        <button className='primary-btn' onClick={onAddBtnClicked}>
          게시하기
        </button>
      </div>
      <div className='table-container'>
        <div className='body-section'>
          <div className='row'>
            <div className='item'>
              <CInputContainer
                containerStyle={{
                  width: '100%',
                }}
                containerType={{
                  type: 'outline',
                  adaptive: true,
                }}
                type='text'
                inputId='faq-add-title-input'
                onInputChanged={setTitle}
                label='제목'
                placeholder='제목을 입력해주세요'
              />
            </div>
            <div>
              <CSelectContainer
                options={targetOption}
                onSelected={setTarget}
                placeholder='클라이언트'
                labelSelector={(item) => item.label}
              />
            </div>
          </div>
          <div>
            <CMultiFileInputContainer onSelectdFilesChanged={setFiles} />
          </div>
          <div className='row content'>
            <textarea
              name='content'
              id='notice-detail-content'
              cols={30}
              rows={10}
              value={content}
              onChange={(e) => setContent(e.target.value)}
              placeholder='내용을 입력해주세요'
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoticeAdd;
