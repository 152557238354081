import useToken from '@hooks/store/useToken';
import { alertMessage } from '@libs/alertMessage';
import { apiRoute, requestSecureGet } from '@libs/api/api';
import { getEndOfDate, getStartOfDate } from '@libs/factory';
import {
  GetPointListResponseTypes,
  PointListItemTypes,
} from '@typedef/components/Point/point.types';
import { useCallback, useEffect, useState } from 'react';
import List from '../components/List';

type Props = {
  isAdminPoint: boolean;
  startDate: string;
  endDate: string;
  keyword: string;
};

const itemCnt = 13;

const ListContainer = ({
  isAdminPoint,
  startDate,
  endDate,
  keyword,
}: Props) => {
  const { getAccessToken } = useToken();

  const [points, setPoints] = useState<PointListItemTypes[]>([]);
  const [maxPage, setMaxPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);

  const loadPoints = useCallback(async () => {
    const { data, config } = await requestSecureGet<GetPointListResponseTypes>(
      apiRoute.point.getPoints +
        `?page=${currentPage}&perPage=${itemCnt}${
          keyword?.trim().length !== 0 ? '&q=' + keyword : ''
        }${startDate ? '&startDate=' + getStartOfDate(startDate) : ''}${
          endDate ? '&endDate=' + getEndOfDate(endDate) : ''
        }${isAdminPoint ? `&types=ADMIN` : ''}`,
      {},
      getAccessToken()!,
    );

    if (config.status === 200) {
      setMaxPage(data.maxPage);
      setPoints(data.rows);
    } else {
      alertMessage('목록을 불러오지 못했습니다');
    }
  }, [currentPage, keyword, startDate, endDate, getAccessToken, isAdminPoint]);

  const onPageChanged = useCallback((page: number) => {
    setCurrentPage(page);
  }, []);

  useEffect(() => {
    loadPoints();
  }, [loadPoints]);

  useEffect(() => {
    setCurrentPage(0);
  }, [keyword, startDate, endDate, isAdminPoint]);

  return (
    <List
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      points={points}
      maxPage={maxPage}
      onPageChanged={onPageChanged}
    />
  );
};

export default ListContainer;
