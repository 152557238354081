import CCheckboxContainer from '@components/common/CCheckbox/containers/CCheckboxContainer';
import CInputContainer from '@components/common/CInput/containers/CInputContainer';
import { formatDate } from '@libs/factory';
import { checkIncludeNotNumber } from '@libs/validation';
import { CouponTypes } from '@typedef/components/Coupon/coupon.types';
import React from 'react';
import '../styles/add.coupon.popup.styles.css';

type Props = {
  coupon?: CouponTypes;
  setName: React.Dispatch<React.SetStateAction<string>>;
  setAmount: React.Dispatch<React.SetStateAction<number>>;
  setCondition: React.Dispatch<React.SetStateAction<number>>;
  setLimitDate: React.Dispatch<React.SetStateAction<string | null>>;
  isInfinit: boolean;
  setIsInfinite: React.Dispatch<React.SetStateAction<boolean>>;
  onEditCouponClicked: () => void;
  onAddCouponClicked: () => void;
  onCancelClicked: () => void;
};

const AddCouponPopup = ({
  coupon,
  setName,
  setAmount,
  setCondition,
  setLimitDate,
  isInfinit,
  setIsInfinite,
  onEditCouponClicked,
  onAddCouponClicked,
  onCancelClicked,
}: Props) => {
  return (
    <div className='add-coupon-popup-root'>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          coupon ? onEditCouponClicked() : onAddCouponClicked();
        }}>
        <CInputContainer
          isRequired={true}
          type={'text'}
          inputId='add-coupon-name'
          containerType={{ type: 'outline', adaptive: false }}
          initialValue={coupon?.name}
          onInputChanged={setName}
          label='쿠폰 이름'
        />
        <div className='coupon-input-wrapper'>
          <CInputContainer
            isRequired={true}
            type='text'
            testValue={checkIncludeNotNumber}
            inputId='add-coupon-name'
            initialValue={coupon?.amount.toString()}
            containerType={{ type: 'outline', adaptive: false }}
            onInputChanged={(item) => {
              setAmount(Number(item));
            }}
            label='쿠폰 금액'
            placeholder='금액을 입력해주세요'
          />
          <span>원</span>
        </div>
        <div className='coupon-input-wrapper'>
          <CInputContainer
            isRequired={true}
            type='text'
            testValue={checkIncludeNotNumber}
            initialValue={coupon?.condition.toString()}
            inputId='add-coupon-name'
            containerType={{ type: 'outline', adaptive: false }}
            onInputChanged={(item) => {
              setCondition(Number(item));
            }}
            label='사용 조건'
            placeholder='금액을 입력해주세요'
          />
          <span>원 이상부터 사용 가능</span>
        </div>
        <div className='limit-date-wrapper'>
          {!isInfinit && (
            <CInputContainer
              type={'date'}
              inputId='add-coupon-name'
              initialValue={
                coupon?.limitDate ? formatDate(coupon.limitDate) : ''
              }
              containerType={{ type: 'outline', adaptive: false }}
              onInputChanged={(item) => {
                setLimitDate(item);
              }}
              label='사용 기한'
              disabled={isInfinit}
            />
          )}
          <div className='checkbox-wrapper'>
            <CCheckboxContainer
              onCheckChanged={(checked) => {
                if (checked) setLimitDate(null);
                setIsInfinite(checked);
              }}
              checkType='square'
              colorType='border'
              initialState={coupon?.limitDate ? false : true}
            />
            <span>기간 제한 없음</span>
          </div>
        </div>
        <div className='button-wrapper'>
          <button
            className='cancel-button button'
            type='button'
            onClick={onCancelClicked}>
            취소하기
          </button>
          {coupon ? (
            <button className='save-button button' type='submit'>
              수정하기
            </button>
          ) : (
            <button className='save-button button' type='submit'>
              저장하기
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

export default AddCouponPopup;
