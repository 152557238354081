import React from 'react';
import { PopupActionTypes } from './actions';
import { HIDE_POPUP_ACTION, SHOW_POPUP_ACITON } from './modules/actionTypes';

export type PopupStateTypes = {
  child: React.ReactNode | null;
  visible: boolean;
};

const init: PopupStateTypes = {
  child: null,
  visible: false,
};

const popupReducer = (
  prev: PopupStateTypes = init,
  { type, payload }: PopupActionTypes,
): PopupStateTypes => {
  switch (type) {
    case SHOW_POPUP_ACITON:
      return {
        child: payload,
        visible: true,
      };

    case HIDE_POPUP_ACTION:
      return init;

    default:
      return prev;
  }
};

export default popupReducer;
