import React, { useCallback, useEffect, useRef, useState } from 'react';
import CFileInput from '../CFileInput';

type Props = {
  onFileChanged: (file: File) => void;
  label: string;
};

const CFileInputContainer = ({ onFileChanged, label }: Props) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const inputRef = useRef<HTMLInputElement>(null);

  const onFileSelectClicked = useCallback(() => {
    inputRef?.current?.click();
  }, [inputRef]);

  const onFileSelected = useCallback((file: File) => {
    setSelectedFile(file);
  }, []);

  useEffect(() => {
    if (selectedFile) {
      onFileChanged(selectedFile);
    }
  }, [selectedFile]);

  return (
    <CFileInput
      inputRef={inputRef}
      onFileSelected={onFileSelected}
      selectedFile={selectedFile}
      onFileSelectClicked={onFileSelectClicked}
      label={label}
    />
  );
};

export default CFileInputContainer;
