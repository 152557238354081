import CodyPopupContainer from '@components/Item/containers/CodyPopupContainer';
import { hidePopupAction, showPopupAction } from '@store/popup/actions';
import { RootState } from '@store/rootReducer';
import React, { useCallback } from 'react';
import { render } from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';

export default function usePopup() {
  const { child, visible } = useSelector(
    (root: RootState) => root.popupReducer,
  );

  const dispatch = useDispatch();

  const __showPopupFromHooks = useCallback(
    (diff: React.ReactNode) => dispatch(showPopupAction(diff)),
    [dispatch],
  );

  const __hidePopupFromHooks = useCallback(
    () => dispatch(hidePopupAction()),
    [dispatch],
  );

  return { child, visible, __showPopupFromHooks, __hidePopupFromHooks };
}
