import React from 'react';

type Props = {
  label: string;
  id: string;
  fileName?: string;
  onFileChanged: (file: File) => void;
};

const EventFileInput = ({ label, id, fileName, onFileChanged }: Props) => {
  return (
    <>
      <span className='input-label'>{label}</span>
      <div className='file-input'>
        <label className='file-input-label' htmlFor={`event-${id}`}>
          <div className='file-text'>{fileName ?? '파일을 선택해주세요'}</div>
          <div className='primary-btn select-btn'>{'선택'}</div>
        </label>
        <input
          type='file'
          accept='image/*'
          id={`event-${id}`}
          hidden
          onChange={(e) => onFileChanged(e.target.files?.item(0) as File)}
        />
      </div>
    </>
  );
};

export default EventFileInput;
