import { NoticeEventTabTypes } from '@typedef/components/NoticeEvent/notice.event.types';
import React from 'react';
import EventListContainer from './containers/EventListContainer';
import NoticeListContainer from './containers/NoticeListContainer';
import './styles/notice.styles.css';

type Props = {
  tab: NoticeEventTabTypes;
  onTabChanged: (tab: NoticeEventTabTypes) => void;
};

const NoticeEvent = ({ tab, onTabChanged }: Props) => {
  return (
    <div className='notice-event-root content-root'>
      <div className='title-container'>
        <div
          onClick={() => onTabChanged('notice')}
          className={`title-item ${tab === 'notice' && 'selected'}`}>
          <div className='title-label'>{'공지사항 관리'}</div>
          <div className='subtitle-label'>{'공지사항관리 페이지 입니다'}</div>
        </div>
        <div
          onClick={() => onTabChanged('event')}
          className={`title-item ${tab === 'event' && 'selected'}`}>
          <div className='title-label'>{'이벤트 관리'}</div>
          <div className='subtitle-label'>{'이벤트관리 페이지 입니다'}</div>
        </div>
      </div>
      <div className='content-root'>
        {tab === 'notice' && <NoticeListContainer />}
        {tab === 'event' && <EventListContainer />}
      </div>
    </div>
  );
};

export default NoticeEvent;
