import CInputContainer from '@components/common/CInput/containers/CInputContainer';
import { formatDate } from '@libs/factory';
import { PartnersCategoryListItemTypes } from '@typedef/components/Partners/partners.types';
import React from 'react';
import '../styles/store.category.styles.css';

type Props = {
  list: PartnersCategoryListItemTypes[];
  addPopupVisible: boolean;
  onAddClicked: () => void;
  updateIdx: number;
  setUpdateIdx: React.Dispatch<React.SetStateAction<number>>;
  setName: React.Dispatch<React.SetStateAction<string>>;
  setNewName: React.Dispatch<React.SetStateAction<string>>;
  onChangeNameDoneBtnClicked: (item: PartnersCategoryListItemTypes) => void;
  onAddNewCategoryClicked: () => void;
  onDeleteClicked: (item: PartnersCategoryListItemTypes, idx: number) => void;
};

const StoreCategory = ({
  list,
  addPopupVisible,
  onAddClicked,
  updateIdx,
  setUpdateIdx,
  setName,
  setNewName,
  onChangeNameDoneBtnClicked,
  onAddNewCategoryClicked,
  onDeleteClicked,
}: Props) => {
  return (
    <div className='store-category-root content-root'>
      <div className='button-container'>
        {addPopupVisible && (
          <div className='popup-root'>
            <CInputContainer
              containerType={{
                type: 'outline',
                adaptive: true,
              }}
              onInputChanged={setNewName}
              type='text'
              inputId='new-category-name'
              label='카테고리 이름'
            />
            <button className='primary-btn' onClick={onAddNewCategoryClicked}>
              추가하기
            </button>
          </div>
        )}
        {!addPopupVisible && (
          <button className='primary-btn' onClick={onAddClicked}>
            추가하기
          </button>
        )}
      </div>
      <div className='table-container'>
        <div className='header-section'>
          <div className='item'>카테고리 이름</div>
          <div className='item'>생성일</div>
          <div className='item'>카테고리 이용자 수</div>
          <div className='item'>삭제</div>
        </div>
        <div className='body-section'>
          {list.map((item, idx) => (
            <div className='row' key={idx}>
              <div className='item' onClick={() => setUpdateIdx(idx)}>
                {updateIdx === idx ? (
                  <>
                    <CInputContainer
                      containerStyle={{
                        width: '200px',
                      }}
                      containerType={{
                        type: 'outline',
                        adaptive: true,
                      }}
                      inputId={`name-input-${idx}`}
                      initialValue={item.name}
                      type='text'
                      label='이름 입력'
                      onInputChanged={setName}
                    />
                    <button
                      className='primary-btn'
                      onClick={() => onChangeNameDoneBtnClicked(item)}>
                      저장
                    </button>
                  </>
                ) : (
                  <div className=''>{item.name} </div>
                )}
              </div>
              <div className='item'>{formatDate(item.createDate)}</div>
              <div className='item'>{item.useCount}</div>
              <div className='item'>
                <button
                  className='delete-btn'
                  onClick={() => onDeleteClicked(item, idx)}>
                  삭제하기
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default StoreCategory;
