import React from 'react';
import image from '@components/common/assets/image';
import '../styles/item.description.styles.css';

type Props = {
  idx: number;
  description: string;
  imageUrlList: string[];
  onAddFile: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onDeleteFile: (idx: number) => void;
  onUpdateDescription: (description: string, descriptionIdx: number) => void;
  onDeleteDescriptionClicked: (idx: number) => void;
};

const ItemDescription = ({
  idx,
  description,
  imageUrlList,
  onUpdateDescription,
  onAddFile,
  onDeleteFile,
  onDeleteDescriptionClicked,
}: Props) => {
  return (
    <div className={'description-item-wrapper'}>
      <div className={'file-wrapper'}>
        <label className={'label'} htmlFor={`description-file-input-${idx}`}>
          <div className={'add-file-input'}>{'+'}</div>
        </label>
        <input
          hidden
          id={`description-file-input-${idx}`}
          type='file'
          accept='image/*'
          multiple
          onChange={(e) => onAddFile(e)}
        />
        {imageUrlList.map((item, idx) => (
          <img
            key={item}
            id='image-preview'
            src={item}
            className={'description-file-image'}
            onError={(e) => {
              e.currentTarget.src = image.placeholderImg;
            }}
            onClick={(e) => onDeleteFile(idx)}
          />
        ))}
      </div>
      <div className={'input-wrapper'}>
        <textarea
          className={'description-input'}
          value={description}
          placeholder={'상세설명을 적어주세요.'}
          onChange={(e) => onUpdateDescription(e.target.value, idx)}
        />
      </div>
      <button
        className={'delete-description-btn'}
        onClick={(e) => {
          e.preventDefault();
          onDeleteDescriptionClicked(idx);
        }}>
        {'설명 삭제'}
      </button>
    </div>
  );
};

export default ItemDescription;
