import { BankTypes } from '@typedef/components/common/bank.types';
import React from 'react';
import images from '../CSelect/assets/images';
import cselectImages from './assets/cselect.images';
import { BanksObj } from './containers/CBankSelectContainer';
import styles from './styles/cselect.module.scss';

type Props = {
  selectedBank: keyof BankTypes | null;
  onBankSelected: (accountBank: keyof BankTypes) => void;
  show: boolean;
  nodeRef: React.RefObject<HTMLDivElement>;
  triggerRef: React.RefObject<HTMLDivElement>;
};

function CBankSelect({
  selectedBank,
  onBankSelected,
  show,
  nodeRef,
  triggerRef,
}: Props) {
  return (
    <div className={`${styles[`cselect-root`]} `}>
      <div
        ref={triggerRef}
        className={`${styles['cselect-input']} ${
          styles[show ? 'focus' : 'unfocus']
        } `}>
        <span className={styles[`cselect-label`]}>
          {selectedBank ? BanksObj[selectedBank] : '은행 선택'}
        </span>
        <div className={styles['cselect-under-arrow']}>
          <img src={images.underArrow} alt='cselect under arrow' />
        </div>
      </div>
      {show && (
        <div ref={nodeRef} className={`${styles[`cselect-option`]}  `}>
          <div className={styles['section']}>
            {Object.entries(BanksObj).map(([key, value], index) => (
              <div
                key={index}
                className={`${styles['cselect-option-item']} ${
                  styles[selectedBank === key ? 'selected' : '']
                }`}
                onClick={() => onBankSelected(key as keyof BankTypes)}>
                {value}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default CBankSelect;
