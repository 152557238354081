import { formatDate, settlementCalc } from '@libs/factory';
import {
  DeliveryFeeObjTypes,
  OrderListItemTypes,
  PaymentDetailItemTypes,
  PaymentListItemTypes,
} from '@typedef/components/Order/order.types';
import React from 'react';
import '../styles/calc.detail.styles.css';

type Props = {
  rate: number;
  settlementDetail: PaymentListItemTypes;
  settlementDetailObj: PaymentDetailItemTypes;
  deliveryFeeObj?: DeliveryFeeObjTypes;
  onSettlementBtnClicked: (item: OrderListItemTypes, idx: number) => void;
  onDeliverySettlementBtnClicked: (storeUid: number, orderUid: number) => void;
  onCancelBtnClicked: (item: OrderListItemTypes, idx: number) => void;
};

const CalcDetail = ({
  rate,
  settlementDetail,
  settlementDetailObj,
  deliveryFeeObj,
  onSettlementBtnClicked,
  onDeliverySettlementBtnClicked,
  onCancelBtnClicked,
}: Props) => {
  return (
    <div className='content-root calc-detail-root'>
      <div className='title-container'>
        <div className='title-item'>
          <div className='title-label'>{'정산관리'}</div>
          <div className='subtitle-label'>{'정산 관리입니다'}</div>
        </div>
      </div>
      <div className={`body-section basic`}>
        <div className='row'>
          <div className='item'>
            <span className='label'>구매자</span>
            <span>{settlementDetail.buyerName}</span>
          </div>
        </div>
        <div className='row'>
          <div className='item'>
            <span className='label'>전화번호</span>
            <span>{settlementDetail.buyerTel}</span>
          </div>
        </div>
        <div className='row'>
          <div className='item'>
            <span className='label'>이메일</span>
            <span>{settlementDetail.buyerEmail}</span>
          </div>
        </div>
        <div className='row'>
          <div className='item'>
            <span className='label'>주문번호</span>
            <span>{settlementDetail.merchantUid}</span>
          </div>
        </div>
        <div className='row'>
          <div className='item'>
            <span className='label'>결제일시</span>
            <span>{formatDate(settlementDetail.createDate)}</span>
          </div>
        </div>

        <div className='row'>
          <div className='item'>
            <span className='label'>전체 포인트</span>
            <span>
              {settlementDetail.orders
                .reduce((prev, current) => {
                  return (prev += current.pointDiscount);
                }, 0)
                .toLocaleString()}
            </span>
          </div>
        </div>
        <div className='row'>
          <div className='item'>
            <span className='label'>전체 쿠폰</span>
            <span>
              {settlementDetail.orders
                .reduce((prev, current) => {
                  return (prev += current.couponDiscount);
                }, 0)
                .toLocaleString()}
            </span>
          </div>
        </div>
      </div>
      <div className='table-container'>
        <div className='header-section'>
          <div className='item'>{'가게명'}</div>
          <div className='item'>{'상품명'}</div>
          <div className='item count'>{'수량'}</div>
          <div className='item '>{'주문상태'}</div>
          <div className='item'>{'총 상품금액'}</div>
          <div className='item'> {'사용 쿠폰'}</div>
          <div className='item'> {'사용 포인트'}</div>
          <div className='item'> {'정산금액'}</div>
          <div className='item'> {'정산상태'}</div>
          <div className='item'> {'결제취소'}</div>
        </div>
        <div className='body-section'>
          {Object.values(settlementDetailObj).map((storeItem, idx) => {
            return (
              <div className='store-row'>
                {storeItem.map((item, itemIdx) => {
                  return (
                    <>
                      <div className='row' key={itemIdx}>
                        <div className='item'>
                          {itemIdx === 0 ? item.store.name : ''}
                        </div>
                        <div className='item'>{item.product.name}</div>
                        <div className='item count'>{item.count}</div>
                        <div className='item'>{item.orderStatus}</div>
                        <div className='item'>
                          {`${(
                            (item.amount +
                              item.pointDiscount +
                              item.couponDiscount) /
                            item.count
                          ).toLocaleString()} 원`}
                        </div>
                        <div className='item'>
                          {item.couponDiscount.toLocaleString()}
                        </div>
                        <div className='item'>
                          {item.pointDiscount.toLocaleString()}
                        </div>
                        <div className='item settlement'>
                          {item.settlementAmount ? (
                            item.settlementAmount.toLocaleString()
                          ) : (
                            <input
                              className='input'
                              type={'text'}
                              value={settlementCalc(
                                item.amount +
                                  item.pointDiscount +
                                  (item.coupon?.storeUid === null
                                    ? item.couponDiscount
                                    : 0),
                                rate,
                              ).toLocaleString()}
                              disabled
                            />
                          )}
                        </div>
                        <div className={`item calc`}>
                          {item.settlementAmount ? (
                            '정산완료'
                          ) : (item.orderStatus === '배송완료' ||
                              item.orderStatus === '픽업완료' ||
                              item.orderStatus === '반품거절') &&
                            new Date(item.canSettlementAt).getTime() <=
                              new Date().getTime() ? (
                            <button
                              className='settlement-btn'
                              onClick={() =>
                                onSettlementBtnClicked(item, itemIdx)
                              }>
                              {'정산하기 >'}
                            </button>
                          ) : (
                            '-'
                          )}
                        </div>
                        <div className='item cancel'>
                          {item.orderStatus === '상품준비중' ? (
                            <button
                              className='cancel-btn'
                              onClick={() => onCancelBtnClicked(item, idx)}>
                              {'결제취소 >'}
                            </button>
                          ) : item.orderStatus === '취소완료' ? (
                            '취소완료'
                          ) : (
                            '-'
                          )}
                        </div>
                      </div>
                      {storeItem.length - 1 === itemIdx &&
                        deliveryFeeObj &&
                        deliveryFeeObj[item.store.storeUid] && (
                          <>
                            <div className='row account' key={itemIdx}>
                              <div className='item'>{''}</div>
                              <div className='item name'>{'배송비'}</div>
                              <div className='item count'>{''}</div>
                              <div className='item'>{''}</div>
                              <div className='item'>
                                {deliveryFeeObj[
                                  item.store.storeUid
                                ].amount.toLocaleString()}
                              </div>
                              <div className='item'>{''}</div>
                              <div className='item'>{''}</div>
                              <div className='item settlement'>
                                {deliveryFeeObj[item.store.storeUid]
                                  .settlementAmount ? (
                                  deliveryFeeObj[item.store.storeUid]
                                    .settlementAmount
                                ) : (
                                  <input
                                    className='input'
                                    type={'text'}
                                    value={settlementCalc(
                                      deliveryFeeObj[item.store.storeUid]
                                        .amount,
                                      rate,
                                    )}
                                    disabled
                                  />
                                )}
                              </div>
                              <div className={`item calc`}>
                                {deliveryFeeObj[item.store.storeUid]
                                  .settlementAmount !== null ? (
                                  '정산완료'
                                ) : (
                                  <button
                                    className='settlement-btn'
                                    onClick={() =>
                                      onDeliverySettlementBtnClicked(
                                        item.store.storeUid,
                                        item.orderUid,
                                      )
                                    }>
                                    {'정산하기 >'}
                                  </button>
                                )}
                              </div>
                              <div className='item cancel'>{''}</div>
                            </div>
                            <div className='line' />
                          </>
                        )}
                    </>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default CalcDetail;
