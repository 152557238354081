import { PartnersTabTypes } from '@typedef/components/Partners/partners.types';
import React from 'react';
import PartnersListContainer from './containers/PartnersListContainer';
import SignupRequestListContainer from './containers/SignupRequestListContainer';
import StoreCategoryContainer from './containers/StoreCategoryContainer';
import UpdateRequestListContainer from './containers/UpdateRequestListContainer';
import './styles/partners.styles.css';

type Props = {
  tab: PartnersTabTypes;
  onTabClicked: (tab: PartnersTabTypes) => void;
};

const Partners = ({ tab, onTabClicked }: Props) => {
  return (
    <div className='partners-root content-root'>
      <div className='title-container'>
        <div
          onClick={() => onTabClicked('partners')}
          className={`title-item ${tab === 'partners' && 'selected'}`}>
          <div className='title-label'>{'파트너스 목록'}</div>
          <div className='subtitle-label'>{'가입한 파트너스 목록 입니다'}</div>
        </div>
        <div
          onClick={() => onTabClicked('category')}
          className={`title-item ${tab === 'category' && 'selected'}`}>
          <div className='title-label'>{'샵 카테고리'}</div>
          <div className='subtitle-label'>{'샵 카테고리 목록 입니다'}</div>
        </div>
        <div
          onClick={() => onTabClicked('signup-req')}
          className={`title-item ${tab === 'signup-req' && 'selected'}`}>
          <div className='title-label'>{'가입 요청 목록'}</div>
          <div className='subtitle-label'>
            {'가입 요청한 파트너스 목록 입니다'}
          </div>
        </div>
        <div
          onClick={() => onTabClicked('update-req')}
          className={`title-item ${tab === 'update-req' && 'selected'}`}>
          <div className='title-label'>{'수정 요청 목록'}</div>
          <div className='subtitle-label'>
            {'수정 요청한 파트너스 목록 입니다'}
          </div>
        </div>
      </div>
      <div className='partners-content-container'>
        {tab === 'partners' && <PartnersListContainer />}
        {tab === 'category' && <StoreCategoryContainer />}
        {tab === 'signup-req' && <SignupRequestListContainer />}
        {tab === 'update-req' && <UpdateRequestListContainer />}
      </div>
    </div>
  );
};

export default Partners;
