import CMultiFileInputContainer from '@components/common/CFileInput/containers/CMultiFileInputContainer';
import CInputContainer from '@components/common/CInput/containers/CInputContainer';
import CSelectContainer from '@components/common/CSelect/containers/CSelectContainer';
import {
  NoticeDetailTypes,
  NoticeListItemTypes,
  NoticeOptionItemTypes,
} from '@typedef/components/NoticeEvent/notice.event.types';
import React from 'react';
import '../styles/notice.styles.css';

type Props = {
  content: string;
  notice: NoticeDetailTypes;
  images: (File | null)[];
  categoryOption: [NoticeOptionItemTypes, NoticeOptionItemTypes];
  onUpdateBtnClicked: () => void;
  onDeleteBtnClicked: () => void;
  setCategory: React.Dispatch<
    React.SetStateAction<NoticeOptionItemTypes | null>
  >;
  setImages: React.Dispatch<React.SetStateAction<(File | null)[]>>;
  setTitle: React.Dispatch<React.SetStateAction<string>>;
  setContent: React.Dispatch<React.SetStateAction<string>>;
};

const NoticeDetail = ({
  content,
  notice,
  images,
  categoryOption,
  onUpdateBtnClicked,
  onDeleteBtnClicked,
  setCategory,
  setImages,
  setTitle,
  setContent,
}: Props) => {
  return (
    <div className='notice-root content-root'>
      <div className='title-container'>
        <div className='title-item'>
          <div className='title-label'>{'공지 상세'}</div>
          <div className='subtitle-label'>{'공지 상세 페이지 입니다'}</div>
        </div>
      </div>
      <div className='button-container'>
        <button className='delete-btn' onClick={onDeleteBtnClicked}>
          삭제하기
        </button>
        <button className='primary-btn' onClick={onUpdateBtnClicked}>
          저장하기
        </button>
      </div>
      <div className='table-container'>
        <div className='body-section'>
          <div className='row'>
            <div className='item'>
              <CInputContainer
                initialValue={notice.title}
                containerStyle={{
                  width: '100%',
                }}
                containerType={{
                  type: 'outline',
                  adaptive: true,
                }}
                type='text'
                inputId='faq-add-title-input'
                onInputChanged={setTitle}
                label='제목'
                placeholder='제목을 입력해주세요'
              />
            </div>
            <div>
              <CSelectContainer
                options={categoryOption}
                onSelected={setCategory}
                placeholder={
                  notice.target === 'CLIENT' ? '클라이언트' : '파트너스'
                }
                labelSelector={(item) => item.label}
              />
            </div>
          </div>
          <div>
            <CMultiFileInputContainer
              onSelectdFilesChanged={setImages}
              initialValue={images as File[]}
            />
          </div>
          <div className='row content'>
            <textarea
              name='content'
              id='notice-detail-content'
              cols={30}
              rows={10}
              value={content}
              onChange={(e) => setContent(e.target.value)}
              placeholder='내용을 입력해주세요'
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoticeDetail;
