import CMultiFileInputContainer from '@components/common/CFileInput/containers/CMultiFileInputContainer';
import { BanksObj } from '@components/common/CBankSelect/containers/CBankSelectContainer';
import CInputContainer from '@components/common/CInput/containers/CInputContainer';
import { formatDate } from '@libs/factory';
import {
  PartnersCategoryListItemTypes,
  PartnersDetailTypes,
  PartnersReqCategoryTypes,
  StoreRestsTypes,
  StoreTypes,
} from '@typedef/components/Partners/partners.types';
import React from 'react';
import '../styles/signup.request.styles.css';

type Props = {
  partners: PartnersDetailTypes;
  onBackButtonClicked: () => void;
  onSignUpButtonClicked: (approve: number) => void;
  onImageClicked: (src: string) => void;
};

const SignupRequestDetail = ({
  partners,
  onBackButtonClicked,
  onSignUpButtonClicked,
  onImageClicked,
}: Props) => {
  return (
    <div className='content-root signup-request-detail-root'>
      <div className='title-container'>
        <div className='title-item'>
          <div className='title-label'>{partners.name}</div>
          <div className='subtitle-label'>{partners.email}</div>
          {partners.allowSignIn === 0 && (
            <div className='subtitle-label'>{`가입 요청입니다.`}</div>
          )}
          {partners.allowSignIn === 1 && (
            <div className='subtitle-label'>{`승인된 가입 요청입니다.`}</div>
          )}
          {partners.allowSignIn === -1 && (
            <div className='subtitle-label'>{`거절된 가입 요청입니다.`}</div>
          )}
        </div>
      </div>
      <div className='button-container signUp'>
        {partners.allowSignIn === 0 && (
          <>
            <button
              className='delete-btn'
              onClick={() => onSignUpButtonClicked(-1)}>
              거절하기
            </button>
            <button
              className='primary-btn'
              onClick={() => onSignUpButtonClicked(1)}>
              승인하기
            </button>
          </>
        )}
      </div>
      <div className={`content-root `}>
        <div className='table-container '>
          <span className='table-title'>기본정보</span>

          <div className={`body-section`}>
            <div className='row image'>
              {partners.store.storeProfile.map((img, idx) => {
                return (
                  <img
                    src={img}
                    alt='store-img'
                    key={idx}
                    onClick={() => {
                      console.log('click');

                      onImageClicked(img);
                    }}
                  />
                );
              })}
            </div>
            <div className='row'>
              <div className='item only-view'>
                <span className='label'>이름</span>
                <span className=''>{partners.name}</span>
              </div>
            </div>
            <div className='row'>
              <div className='item only-view'>
                <span className='label'>전화번호</span>
                <span>{partners.phone}</span>
              </div>
            </div>
            <div className='row'>
              <div className='item only-view'>
                <span className='label'>아이디</span>
                <span>{partners.partnersName}</span>
              </div>
            </div>
            <div className='row'>
              <div className='item only-view'>
                <span className='label'>은행명</span>
                <span>{BanksObj[partners.accountBank]}</span>
              </div>
            </div>
            <div className='row'>
              <div className='item only-view'>
                <span className='label'>예금주명</span>
                <span>{partners.accountName}</span>
              </div>
            </div>
            <div className='row'>
              <div className='item only-view'>
                <span className='label'>계좌번호</span>
                <span>{partners.accountNumber}</span>
              </div>
            </div>

            <div className='row'>
              <div className='item only-view'>
                <span className='label'>이메일</span>
                <span>{partners.email}</span>
              </div>
            </div>
            <div className='row'>
              <div className='item only-view'>
                <span className='label'>가입일</span>
                <span>{formatDate(partners.createDate)}</span>
              </div>
            </div>
            <div className='row'>
              <div className='item only-view'>
                <span className='label'>삭제일</span>
                <span>{partners.deleteDate}</span>
              </div>
            </div>
          </div>
        </div>
        <div className='table-container store'>
          <span className='table-title'>가게정보</span>
          <div className={`store-file-section view`}>
            <div className='rest-day-section'>
              <span>휴점일</span>
              {['첫째주', '둘째주', '셋째주', '넷째주', '다섯째주'].map(
                (week, weekIdx) => {
                  return (
                    <div className='week-container' key={weekIdx}>
                      <div className={`week item`}>{week}</div>
                      {['월', '화', '수', '목', '금', '토', '일'].map(
                        (day, dayIdx) => {
                          return (
                            <div
                              className={`day item  ${
                                partners.store.storeRests?.some(
                                  (item) =>
                                    item.week === weekIdx + 1 &&
                                    item.day === dayIdx + 1,
                                ) && 'selected'
                              }`}
                              key={dayIdx}>
                              {day}
                            </div>
                          );
                        },
                      )}
                    </div>
                  );
                },
              )}
              <div
                className={`holidays item ${
                  Boolean(partners.store.isRestHoliday) && 'selected'
                }`}>
                공휴일
              </div>
            </div>
            <div className='business-file-section'>
              {partners.store.businessRegistrationFile && (
                <a
                  className='file-download-btn'
                  href={partners.store.businessRegistrationFile[0]}>
                  {'사업자등록증을 확인해주세요'}
                </a>
              )}
              {partners.store.businessReportFile && (
                <a
                  className='file-download-btn'
                  href={partners.store.businessReportFile[0]}>
                  {'통신판매업 신고증을 확인해주세요'}
                </a>
              )}
            </div>
          </div>
          <div className={`body-section `}>
            <div className='row'>
              <div className='item only-view'>
                <span className='label'>대표자명</span>
                <span>{partners.store.ownerName}</span>
              </div>
            </div>
            <div className='row'>
              <div className='item only-view'>
                <span className='label'>사업자 번호</span>
                <span>{partners.store.businessNumber}</span>
              </div>
            </div>
            <div className='row'>
              <div className='item only-view'>
                <span className='label'>통신판매업 번호</span>
                <span>{partners.store.telSalesBusinessReportNumber}</span>
              </div>
            </div>
            <div className='row'>
              <div className='item only-view'>
                <span className='label'>사업장 주소</span>
                <span>{partners.store.location}</span>
              </div>
            </div>
            <div className='row'>
              <div className='item only-view'>
                <span className='label'>사업장 상세주소</span>
                <span>{partners.store.locationDetail}</span>
              </div>
            </div>
            <div className='row'>
              <div className='item only-view'>
                <span className='label'>가게명</span>
                <span>{partners.store.name}</span>
              </div>
            </div>
            <div className='row'>
              <div className='item only-view'>
                <span className='label'>가게 전화번호</span>
                <span>{partners.store.phone}</span>
              </div>
            </div>
            <div className='row'>
              <div className='item only-view'>
                <span className='label'>가게 설명</span>
                <span>{partners.store.description}</span>
              </div>
            </div>
            <div className='row'>
              <div className='item only-view'>
                <span className='label'>샵종류</span>
                <span>{partners.store.targetTag.name}</span>
              </div>
            </div>
            <div className='row'>
              <div className='item only-view'>
                <span className='label'>개점 시간</span>
                <span>{partners.store.startDate}</span>
              </div>
            </div>
            <div className='row'>
              <div className='item only-view'>
                <span className='label'>폐점 시간</span>
                <span>{partners.store.endDate}</span>
              </div>
            </div>
            <div className='row'>
              <div className='item only-view'>
                <span className='label'>무료 배송(원 이상)</span>
                <span>{partners.store.freeDelivery}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignupRequestDetail;
