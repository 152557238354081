import CCheckboxContainer from '@components/common/CCheckbox/containers/CCheckboxContainer';
import CInputContainer from '@components/common/CInput/containers/CInputContainer';
import { formatDate } from '@libs/factory';
import { checkIncludeNotNumber } from '@libs/validation';
import { CouponTypes } from '@typedef/components/Coupon/coupon.types';
import React from 'react';
import '../styles/coupon.detail.styles.css';

type Props = {
  name: string;
  amount: number;
  condition: number;
  limitDate: string | null;
  isInfinite: boolean;
  setName: React.Dispatch<React.SetStateAction<string>>;
  setAmount: React.Dispatch<React.SetStateAction<number>>;
  setCondition: React.Dispatch<React.SetStateAction<number>>;
  setLimitDate: React.Dispatch<React.SetStateAction<string | null>>;
  setIsInfinite: React.Dispatch<React.SetStateAction<boolean>>;
  coupon: CouponTypes;
  onDeleteButtonClicked: () => void;
  onEditButtonClicked: () => void;
};

const CouponDetail = ({
  name,
  amount,
  condition,
  limitDate,
  setName,
  setAmount,
  setCondition,
  setLimitDate,
  isInfinite,
  setIsInfinite,
  coupon,
  onDeleteButtonClicked,
  onEditButtonClicked,
}: Props) => {
  return (
    <div className='content-root coupon-detail'>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onEditButtonClicked();
        }}>
        <div className='title-container'>
          <div className='title-item'>
            <div className='title-label'>{'쿠폰 관리'}</div>
            <div className='subtitle-label'>{'쿠폰 상세페이지입니다'}</div>
          </div>
          <div className='button-wrapper'>
            <button type='submit' className='primary-btn'>
              수정하기
            </button>
            <button
              type='button'
              onClick={onDeleteButtonClicked}
              className='delete-btn'>
              삭제하기
            </button>
          </div>
        </div>

        <div className='body-section'>
          <CInputContainer
            type={'text'}
            inputId='issued-by'
            containerType={{ type: 'outline', adaptive: false }}
            onInputChanged={() => {}}
            label='쿠폰 발급처'
            disabled={true}
            initialValue={coupon.storeUid ? coupon.store?.name : '관리자쿠폰'}
          />
          <CInputContainer
            isRequired={true}
            type={'text'}
            inputId='add-coupon-name'
            containerType={{ type: 'outline', adaptive: false }}
            onInputChanged={setName}
            label='쿠폰 이름'
            initialValue={coupon.name}
          />
          <div className='coupon-input-wrapper'>
            <CInputContainer
              isRequired={true}
              type='text'
              inputId='add-coupon-name'
              containerType={{ type: 'outline', adaptive: false }}
              testValue={checkIncludeNotNumber}
              onInputChanged={(item) => {
                setAmount(Number(item));
              }}
              label='쿠폰 금액'
              placeholder='금액을 입력해주세요'
              initialValue={String(coupon.amount)}
            />
            <span>원</span>
          </div>
          <div className='coupon-input-wrapper'>
            <CInputContainer
              isRequired={true}
              type='text'
              testValue={checkIncludeNotNumber}
              inputId='add-coupon-name'
              containerType={{ type: 'outline', adaptive: false }}
              onInputChanged={(item) => {
                setCondition(Number(item));
              }}
              label='사용 조건'
              placeholder='금액을 입력해주세요'
              initialValue={String(coupon.condition)}
            />
            <span>원 이상부터 사용 가능</span>
          </div>
          <div className='limit-date-wrapper'>
            {!isInfinite && (
              <CInputContainer
                type={'date'}
                inputId='add-coupon-name'
                containerType={{ type: 'outline', adaptive: false }}
                onInputChanged={setLimitDate}
                label='사용 기한'
                disabled={isInfinite}
                initialValue={
                  coupon.limitDate ? formatDate(coupon.limitDate) : ''
                }
              />
            )}
            <div className='checkbox-wrapper'>
              <CCheckboxContainer
                onCheckChanged={(checked) => {
                  console.log(checked);
                  if (checked) setLimitDate(null);
                  setIsInfinite(checked);
                }}
                checkType='square'
                colorType='border'
                initialState={coupon.limitDate ? false : true}
              />
              <span>기간 제한 없음</span>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default CouponDetail;
