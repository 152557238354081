import React from 'react';
import { HIDE_POPUP_ACTION, SHOW_POPUP_ACITON } from './modules/actionTypes';

export const showPopupAction = (children: React.ReactNode) => ({
  type: SHOW_POPUP_ACITON,
  payload: children,
});

export const hidePopupAction = () => ({
  type: HIDE_POPUP_ACTION,
  payload: {},
});

export type PopupActionTypes =
  | ReturnType<typeof showPopupAction>
  | ReturnType<typeof hidePopupAction>;
