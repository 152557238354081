import CoutlineContainedInput from '@components/common/CInput/components/COutlineContainedInput';
import usePopup from '@hooks/store/usePopup';
import useToken from '@hooks/store/useToken';
import { alertMessage } from '@libs/alertMessage';
import {
  apiRoute,
  BasicApiResponse,
  BasicListDataType,
  requestMultipartPatch,
  requestSecureDelete,
  requestSecureGet,
  requestSecurePatch,
  requestSecurePost,
} from '@libs/api/api';
import { uriToFile } from '@libs/trans';
import {
  CategoryTypes,
  CodyProductsTypes,
  ItemDescriptionTypes,
  OptionNameTypes,
  OptionTypes,
  ParsedOptionsTypes,
  ProductDescriptionTypes,
  ProductDetailTypes,
  ProductOptionsTypes,
  ProductTypes,
  ReqProductOptionsTypes,
  SubCategoryTypes,
} from '@typedef/components/Item/item.types';
import React from 'react';
import { useMemo } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useCallback } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import ItemDetail from '../components/ItemDetail';
import CodyPopupContainer from './CodyPopupContainer';
import ImagePreviewPopupContainer from './ImagePreviewPopupContainer';
import OptionPopupContainer from './OptionPopupContainer';

const ItemDetailContainer = () => {
  const navigate = useNavigate();
  const { __showPopupFromHooks } = usePopup();
  const { getAccessToken } = useToken();
  const { productUid } = useLocation().state as {
    productUid: number;
  };

  const [productDetail, setProductDetail] = useState<ProductDetailTypes>();
  const [images, setImages] = useState<(File | null)[]>([]);
  const [productName, setProductName] = useState('');
  const [price, setPrice] = useState(0);
  const [todayGet, setTodayGet] = useState(0);
  const [modelHeight, setModelHeight] = useState('');
  const [modelWeight, setModelWeight] = useState('');
  const [modelNormalSize, setModelNormalSize] = useState('');
  const [modelSize, setModelSize] = useState('');
  const [category, setCategory] = useState<CategoryTypes[]>([]);
  const [subCategory, setSubCategory] = useState<SubCategoryTypes[]>([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedSubCategory, setSelectedSubCategory] = useState('');
  const [parsedOptions, setParsedOptions] = useState<ParsedOptionsTypes[]>([]);
  const [productOptions, setProductOptions] = useState<ProductOptionsTypes[]>(
    [],
  );
  const [newOptionsStock, setNewOptionsStock] = useState<string[]>([]);
  const [productOptionsStock, setProductOptionsStock] = useState<{
    [key: string]: string;
  }>({});
  const [originalParsedOptions, setOriginalParsedOptions] = useState<
    ParsedOptionsTypes[]
  >([]);
  const [originalProductOptions, setOriginalProductOptions] = useState<
    ProductOptionsTypes[]
  >([]);

  const [originalOptionsStock, setOriginalOptionsStock] = useState<{
    [key: string]: string;
  }>({});

  const [descriptionList, setDescriptionList] = useState<
    ItemDescriptionTypes[]
  >([{ description: '', descriptionFile: [] }]);

  const [codyList, setCodyList] = useState<CodyProductsTypes[]>([]);

  const loadCategory = useCallback(async () => {
    const {
      data,
      config: { status },
    } = await requestSecureGet<BasicListDataType<CategoryTypes>>(
      apiRoute.category.getCategory,
      {},
      getAccessToken()!,
    );

    if (status !== 200) {
      alertMessage('상품 대분류 조회에 실패했습니다');
      return;
    }

    setCategory(data.rows);

    return data.rows;
  }, []);

  const loadSubCategory = useCallback(async () => {
    const {
      data,
      config: { status },
    } = await requestSecureGet<BasicListDataType<SubCategoryTypes>>(
      apiRoute.category.getSubCategory + selectedCategory,
      {},
      getAccessToken()!,
    );

    if (status !== 200) {
      alertMessage('상품 중분류 조회에 실패했습니다');
      return;
    }
    setSubCategory(data.rows);
  }, [selectedCategory]);

  const loadDescriptionsImages = useCallback(
    async (descriptions: ProductDescriptionTypes[]) => {
      const imageUriList = descriptions.map((item) => {
        if (!item.descriptionFile) return [];
        return item.descriptionFile;
      });

      const fileList = await Promise.all(
        imageUriList.map((list, listIdx) => {
          const files = Promise.all(
            list.map((item, imageIdx) =>
              uriToFile(item, 'image/*', `${listIdx}${imageIdx}`),
            ),
          );
          return files;
        }),
      );

      console.log(fileList);

      const descriptionList = descriptions.map((item, idx) => {
        return {
          description: item.description,
          descriptionFile: fileList[idx],
        };
      });
      setDescriptionList(descriptionList);
    },
    [],
  );

  const loadProduectDetail = useCallback(async () => {
    const {
      data,
      config: { status },
    } = await requestSecureGet<ProductDetailTypes>(
      apiRoute.product.getProductDetail + productUid,
      {},
      getAccessToken()!,
    );

    if (status !== 200) {
      alertMessage('상품 상세 조회에 실패했습니다');
      return;
    }

    setProductName(data.productName);
    setPrice(data.price);
    setTodayGet(data.todayGet);
    setModelHeight(data.modelHeight);
    setModelWeight(data.modelWeight);
    setModelNormalSize(data.modelNormalSize);
    setModelSize(data.modelSize);
    setParsedOptions(data.parsedOptions === null ? [] : data.parsedOptions);
    setProductOptions(data.productOptions === null ? [] : data.productOptions);
    setSelectedCategory(data.categoryDetail.productCategoryUid);
    setSelectedSubCategory(data.productCategoryDetailUid);
    setParsedOptions(data.parsedOptions ?? []);
    setOriginalParsedOptions(data.parsedOptions ?? []);
    setOriginalProductOptions(data.productOptions ?? []);
    loadOriginalOptionsStock(data.productOptions, data.parsedOptions);

    const descriptions = data.productDescriptions;
    if (descriptions) {
      const uriList = descriptions.map((item) =>
        item.descriptionFile ? item.descriptionFile[0] : null,
      );

      data.productDescriptions &&
        loadDescriptionsImages(data.productDescriptions);
    }
    data.productFile &&
      setImages(
        await Promise.all(
          data.productFile.map((item) => uriToFile(item, 'image/*')),
        ),
      );

    setCodyList(data.codyProducts ?? []);
    setProductDetail(data);
  }, [productUid, getAccessToken]);

  const loadOriginalOptionsStock = useCallback(
    (
      originalProductOptions: ProductOptionsTypes[],
      originalParsedOptions: ParsedOptionsTypes[],
    ) => {
      const optionsStock: { [key: string]: string } = {};
      originalProductOptions.forEach((originalProduct) => {
        const optionArr: any[] = [];
        const optionNameCnt = originalParsedOptions.length;
        for (let i = 1; i <= optionNameCnt; i++) {
          optionArr.push(originalProduct[`opt${i}` as OptionTypes]);
        }

        optionsStock[optionArr.join('/')] = String(originalProduct.stock);
      });

      setOriginalOptionsStock(optionsStock);
    },
    [],
  );

  /**
   * 기존 옵션과 사용자가 수정한 옵션이 변화했는지 체크,
   * 옵션과 재고를 새로 저장해야 할 경우 return true
   * */
  const checkOptionChange = useCallback(() => {
    let isChanged = false;

    if (originalParsedOptions.length !== parsedOptions.length) {
      return true;
    }

    originalParsedOptions.forEach((originalOption, idx) => {
      if (originalOption.optName !== parsedOptions[idx].optName) {
        isChanged = true;
      }

      originalOption.opt.forEach((item) => {
        if (!parsedOptions[idx].opt.includes(item)) isChanged = true;
      });
    });

    return isChanged;
  }, [originalParsedOptions, parsedOptions]);

  /**
   * 1. 오리지널 옵션을 재고 오브젝트 키값처럼 '/'로 조인하여 스트링으로 변환
   * 2. 변환된 오리지널 옵션값을 재고 오브젝트 키값과 비교함
   * 3. 재고 오브젝트 키값과 같은 경우 pachArr에 푸쉬함
   * 4. patchOptionsArr에 들어간 값들은 수정 요청 보냄
   */

  const patchStocks = useCallback(async () => {
    if (!productOptionsStock) return;

    const patchOptionsArr: {
      productOptionGroupUid: number;
      stock: string;
    }[] = [];

    const optNumber = originalParsedOptions.length;

    originalProductOptions.forEach((item, idx) => {
      console.log(item);

      const optArr = [];

      for (let i = 1; i <= optNumber; i++) {
        optArr.push(item[`opt${i}` as OptionTypes]);
      }

      const originalOptString = optArr.join('/');

      console.log('optArr', optArr, originalOptString);

      if (!Object.keys(productOptionsStock).includes(originalOptString)) {
        return;
      }

      patchOptionsArr.push({
        productOptionGroupUid: item.productOptionGroupUid,
        stock: productOptionsStock[originalOptString],
      });
    });

    const {
      data,
      config: { status },
    } = await requestSecurePatch(
      apiRoute.product.getProductDetail + `${productUid}/options`,
      {},
      {
        options: patchOptionsArr,
      },
      getAccessToken()!,
    );

    if (status !== 202) {
      alertMessage('재고 수정을 실패했습니다.');
      return;
    }
  }, [productUid, originalParsedOptions, productOptionsStock]);

  /**
   * 1. 새로 추가된 하위 옵션은 newOptionsStock에 재고 오브젝트의 키값이 저장되어있음
   * 2. newOptionsStock을 돌면서 각각 재고 오브젝트 생성 후 createProductOptions Array에 푸쉬
   * 3. 옵션 추가 요청
   */
  const postStocks = useCallback(async () => {
    const createProductOptions: ReqProductOptionsTypes[] = [];

    newOptionsStock.forEach((newOption) => {
      const optArr = newOption.split('/');
      const optObj: ReqProductOptionsTypes = {};
      originalParsedOptions.forEach((item, idx) => {
        optObj[`opt${idx + 1}Name` as OptionNameTypes] = item.optName;
        optObj[`opt${idx + 1}` as OptionTypes] = optArr[idx];
        optObj.stock = productOptionsStock[newOption];

        createProductOptions.push(optObj);
      });
    });

    if (createProductOptions.length === 0) return;

    const {
      data,
      config: { status },
    } = await requestSecurePost(
      apiRoute.product.getProductDetail + `${productUid}/options`,
      {},
      {
        createProductOptions,
      },
      getAccessToken()!,
    );

    if (status !== 200) {
      alertMessage('재고 추가를 실패했습니다.');
      return;
    }
  }, [productUid, newOptionsStock, originalParsedOptions, productOptionsStock]);

  const checkFormRequirement = useCallback(() => {
    if (!selectedSubCategory) {
      alertMessage('중분류 카테고리를 선택해주세요');
      return false;
    }

    if (images.length === 0) {
      alertMessage('대표이미지를 설정해주세요');
      return false;
    }

    if (Object.keys(productOptionsStock).length === 0) {
      alertMessage('옵션과 재고를 설정해주세요');
      return false;
    }

    if (Object.values(productOptionsStock).includes('')) {
      alertMessage('재고를 설정해주세요');
      return false;
    }

    if (descriptionList.length === 0) {
      alertMessage('상세설명을 추가해주세요');
      return false;
    }

    if (
      descriptionList.length === 1 &&
      descriptionList[0].descriptionFile.length === 0 &&
      descriptionList[0].description === ''
    ) {
      alertMessage('상세설명에 하나 이상의 사진 또는 설명을 추가해주세요');
      return false;
    }

    return true;
  }, [images, productOptionsStock, descriptionList, selectedSubCategory]);

  const onSubmitClicked = useCallback(async () => {
    if (!checkFormRequirement()) return;

    const formData = new FormData();

    const isOptionGroupChanged = checkOptionChange();

    if (isOptionGroupChanged) {
      productOptionsStock &&
        Object.keys(productOptionsStock).forEach((item, idx) => {
          const optArr = item.split('/');
          const optObj: ReqProductOptionsTypes = {};
          parsedOptions.forEach((item, idx) => {
            optObj[`opt${idx + 1}Name` as OptionNameTypes] = item.optName;
            optObj[`opt${idx + 1}` as OptionTypes] = optArr[idx];
          });
          optObj['stock'] = productOptionsStock[item];
          formData.append('createProductOptions', JSON.stringify(optObj));
        });
    } else {
      patchStocks();
      postStocks();
    }

    descriptionList &&
      descriptionList.forEach((item) => {
        const descriptionObj: {
          description: string;
          originalNames?: string[];
        } = { description: item.description };

        descriptionObj['originalNames'] = item.descriptionFile
          ? item.descriptionFile
              .map((file) => {
                if (!file) {
                  return '';
                }
                return file.name;
              })
              .filter((item) => item !== '')
          : [];

        formData.append(
          'createProductDescriptionDto',
          JSON.stringify(descriptionObj),
        );
      });

    formData.append('productCategoryDetailUid', selectedSubCategory);
    codyList &&
      codyList.forEach((item, idx) => {
        formData.append('codyProductsUid', String(item.productUid));
      });

    formData.append('name', productName);
    formData.append('modelHeight', modelHeight);
    formData.append('modelWeight', modelWeight);
    formData.append('modelNormalSize', modelNormalSize);
    formData.append('modelSize', modelSize);
    formData.append('todayGet', String(todayGet));
    formData.append('price', String(price));

    images &&
      images.forEach((item, idx) => {
        formData.append('productFile', item as File);
      });
    if (descriptionList) {
      descriptionList.forEach((list) => {
        list.descriptionFile.forEach((file) => {
          if (!file) return;
          formData.append('descriptionFile', file);
        });
      });
    }

    const {
      data,
      config: { status },
    } = await requestMultipartPatch<{}>(
      apiRoute.product.updateProductDetail +
        `${productDetail?.productUid}/detail`,
      {},
      formData,
      getAccessToken()!,
    );

    if (status !== 202) {
      alertMessage('상품 수정에 실패했습니다.');
      return;
    }
    navigate(-1);
  }, [
    navigate,
    productOptionsStock,
    parsedOptions,
    descriptionList,
    productDetail,
    codyList,
    productName,
    modelHeight,
    modelWeight,
    modelNormalSize,
    modelSize,
    todayGet,
    price,
    images,
    productUid,
    selectedSubCategory,
    checkFormRequirement,
    checkOptionChange,
    patchStocks,
    postStocks,
  ]);

  const onBackBtnClicked = useCallback(() => {}, []);

  const onUpdateDescriptionFile = useCallback(
    (fileList: (File | null)[], descriptionIdx: number) => {
      setDescriptionList((prev) => {
        const clone = [...prev];
        clone[descriptionIdx].descriptionFile = fileList;
        return clone;
      });
    },
    [],
  );

  const onUpdateDescription = useCallback(
    (description: string, descriptionIdx: number) => {
      setDescriptionList((prev) => {
        const clone = [...prev];
        clone[descriptionIdx].description = description;
        return clone;
      });
    },
    [],
  );

  const onAddDescriptionClicked = useCallback(() => {
    setDescriptionList((prev) => {
      const clone = [...prev];
      clone.push({ description: '', descriptionFile: [] });
      return clone;
    });
  }, []);

  const onDeleteDescriptionClicked = useCallback((idx: number) => {
    setDescriptionList((prev) => {
      const clone = [...prev];
      clone.splice(idx, 1);
      return clone;
    });
  }, []);

  const onDeleteItemClicked = useCallback(async () => {
    const {
      data,
      config: { status },
    } = await requestSecureDelete<BasicApiResponse<{}>>(
      apiRoute.product.deleteProductDetail + productUid,
      {},
      getAccessToken()!,
    );

    if (status !== 202) {
      alertMessage('상품 삭제에 실패했습니다.');
      return;
    }

    navigate(-1);
  }, [productUid, navigate]);

  const onDeleteCodyItemClicked = useCallback(
    async (idx: number) => {
      const clone = [...codyList];
      clone.splice(idx, 1);
      const {
        data,
        config: { status },
      } = await requestSecurePatch<{}>(
        apiRoute.product.updateProductDetail + `${productUid}/detail`,
        {},
        { codyProductsUid: clone.map((item) => item.productUid) },
        getAccessToken()!,
      );

      if (status !== 202) {
        alertMessage('코디 아이템 삭제에 실패했습니다.');
        return;
      }

      setCodyList(clone);
    },
    [codyList],
  );

  const loadProductStockList = useCallback(() => {
    if (parsedOptions === null) return;

    // parsedOptions 데카르트 곱으로 string[][] 만듦
    const productStockList =
      parsedOptions.length > 0
        ? parsedOptions
            .map((item) => item.opt)
            .reduce((a, b: any) =>
              a.flatMap((d: any) => b.map((e: any) => [d, e].flat())),
            )
        : ([] as string[][]);

    // 옵션 리스트들 재고 0으로 optionsStock 오브젝트에 넣어줌, 키값은 join해서 string으로 ex) {'블랙/S':0}
    const optionsStock: { [key: string]: string } = {};
    productStockList.forEach((item) => {
      if (typeof item === 'object')
        optionsStock[(item as string[]).join('/')] = '';
      else optionsStock[item] = '';
    });

    // optionsStock 키 돌면서 originalOptionsStock 있는 옵션상품은 재고 재설정, 새로 생긴 옵션은 newOptionsStock에 추가
    const newStockArr: string[] = [];
    Object.keys(optionsStock).forEach((key, idx) => {
      if (Object.keys(originalOptionsStock).includes(key)) {
        optionsStock[key] = originalOptionsStock[key];
      } else {
        newStockArr.push(key);
      }
    });

    setNewOptionsStock(newStockArr);

    setProductOptionsStock(optionsStock);
  }, [parsedOptions, originalProductOptions, originalOptionsStock]);

  const onOptionPopupClicked = useCallback(
    (idx: number) => {
      __showPopupFromHooks(
        <OptionPopupContainer
          parsedOptions={parsedOptions}
          productOptions={productOptions}
          setParsedOptions={setParsedOptions}
          setProductOptions={setProductOptions}
          idx={idx}
        />,
      );
    },
    [__showPopupFromHooks, parsedOptions, productOptions],
  );

  const onDeleteOptionClicked = useCallback((idx: number) => {
    setParsedOptions((prev) => {
      const clone = [...prev];
      clone.splice(idx, 1);
      return clone;
    });
  }, []);

  const onAddCodyItemClicked = useCallback(() => {
    if (productDetail)
      __showPopupFromHooks(
        <CodyPopupContainer
          storeUid={productDetail.storeUid}
          productUid={productUid}
          codyList={codyList}
          setCodyList={setCodyList}
        />,
      );
  }, [__showPopupFromHooks, productDetail, productUid, codyList]);

  useEffect(() => {
    loadCategory();
  }, []);

  useEffect(() => {
    loadSubCategory();
  }, [loadSubCategory]);

  useEffect(() => {
    loadProduectDetail();
  }, [loadProduectDetail]);

  useEffect(() => {
    loadProductStockList();
  }, [loadProductStockList]);

  return productDetail ? (
    <ItemDetail
      todayGet={todayGet}
      productDetail={productDetail}
      images={images}
      parsedOptions={parsedOptions}
      productOptions={productOptions}
      codyList={codyList}
      descriptionList={descriptionList}
      productOptionsStock={productOptionsStock}
      category={category}
      subCategory={subCategory}
      selectedCategory={selectedCategory}
      selectedSubCategory={selectedSubCategory}
      setImages={setImages}
      setProductName={setProductName}
      setPrice={setPrice}
      setTodayGet={setTodayGet}
      setModelHeight={setModelHeight}
      setModelWeight={setModelWeight}
      setModelNormalSize={setModelNormalSize}
      setModelSize={setModelSize}
      setParsedOptions={setParsedOptions}
      setProductOptions={setProductOptions}
      setCodyList={setCodyList}
      setSelectedCategory={setSelectedCategory}
      setSelectedSubCategory={setSelectedSubCategory}
      onDeleteCodyItemClicked={onDeleteCodyItemClicked}
      onAddCodyItemClicked={onAddCodyItemClicked}
      onAddDescriptionClicked={onAddDescriptionClicked}
      onUpdateDescriptionFile={onUpdateDescriptionFile}
      onUpdateDescription={onUpdateDescription}
      onDeleteDescriptionClicked={onDeleteDescriptionClicked}
      onOptionPopupClicked={onOptionPopupClicked}
      setProductOptionsStock={setProductOptionsStock}
      onDeleteOptionClicked={onDeleteOptionClicked}
      onSubmitClicked={onSubmitClicked}
      onDeleteItemClicked={onDeleteItemClicked}
    />
  ) : (
    <div className='content-root'></div>
  );
};

export default ItemDetailContainer;
