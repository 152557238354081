import {
  GNBItemTypes,
  TabItemTypes,
} from '@typedef/components/common/GNB/gnb.types';
import { useState } from 'react';
import { useMemo } from 'react';
import { useCallback } from 'react';

export function useTab<T>(gnb: GNBItemTypes) {
  const { queryParams, tab }: TabItemTypes<T> = useMemo(() => {
    const item = JSON.parse(
      sessionStorage.getItem(`@gnb`) ||
        `{"gnb":"${gnb}","tab":"", "queryParams":""}`,
    ) as TabItemTypes<T>;

    return item;
  }, [gnb]);

  const updateQueryParamsFromSession = useCallback(
    (tab: string, value: T) => {
      window.sessionStorage.setItem(
        `@gnb`,
        JSON.stringify({ gnb, tab, queryParams: value }),
      );
    },
    [gnb],
  );

  const removeTabFromSession = useCallback(() => {
    window.sessionStorage.removeItem(`@gnb`);
  }, [gnb]);

  return {
    queryParams,
    tab,
    updateQueryParamsFromSession,
    removeTabFromSession,
  };
}
