import image from '@components/common/assets/image';
import React, { RefObject } from 'react';
import '../styles/cmulti.file.input.styles.css';

type Props = {
  inputRef: RefObject<HTMLInputElement>;
  files: File[];
  onFileSelectClicked: () => void;
  onFileSelected: (selected: File[]) => void;
  onDeleteClicked: (idx: number) => void;
};

const CMultiFileInput = ({
  inputRef,
  files,
  onFileSelectClicked,
  onFileSelected,
  onDeleteClicked,
}: Props) => {
  return (
    <div className='cmulti-file-input-root'>
      <label htmlFor='cmulti-file-input' hidden>
        {'다중 파일 선택'}
      </label>
      <input
        id='cmulti-file-input'
        ref={inputRef}
        type='file'
        multiple
        accept='image/*'
        onChange={(e) => {
          console.log('onChange');
          const selectedFiles = e.target.files;

          if (selectedFiles) {
            onFileSelected(Array.from(selectedFiles));
          }
        }}
        hidden
      />
      <button
        className='cmulti-file-select-btn'
        onClick={(e) => {
          e.preventDefault();
          onFileSelectClicked();
        }}>
        {'+'}
      </button>
      {files.map((_, idx) => (
        <div
          key={idx}
          className='image-preview-container'
          onClick={() => onDeleteClicked(idx)}>
          <img
            id={`image-preview-${idx}`}
            onError={(e) => (e.currentTarget.src = image.placeholderImg)}
          />
        </div>
      ))}
    </div>
  );
};

export default CMultiFileInput;
