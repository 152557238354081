import { useTab } from '@hooks/useTab';
import { alertMessage } from '@libs/alertMessage';
import { apiRoute, BasicListDataType, requestGet } from '@libs/api/api';
import { EventListItemTypes } from '@typedef/components/NoticeEvent/notice.event.types';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import EventList from '../components/EventList';

const EventListContainer = () => {
  const navigate = useNavigate();
  const { queryParams, tab, updateQueryParamsFromSession } = useTab<{
    page: number;
  }>('noticeEvent');

  const [eventList, setEventList] = useState<EventListItemTypes[]>([]);

  const [maxPage, setMaxPage] = useState(0);
  const [page, setPage] = useState((tab === 'event' && queryParams.page) || 0);

  const loadList = useCallback(async () => {
    const { data, config } = await requestGet<
      BasicListDataType<EventListItemTypes>
    >(apiRoute.events.getEvents + `?page=${page}&perPage=10`, {});

    if (config.status === 200) {
      setEventList(data.rows);
      setMaxPage(data.maxPage);
    } else {
      alertMessage('목록을 불러오지 못했습니다');
    }
  }, [page]);

  const onAddEventBtnClicked = useCallback(() => {
    navigate('eventAdd');
  }, []);

  const onItemClicked = useCallback(
    (item: EventListItemTypes) => {
      navigate('eventDetail', {
        state: {
          item,
        },
      });
    },
    [navigate],
  );

  useEffect(() => {
    loadList();
  }, [loadList]);

  useEffect(() => {
    updateQueryParamsFromSession('event', { page });
  }, [page]);

  return (
    <EventList
      page={page}
      maxPage={maxPage}
      eventList={eventList}
      onAddEventBtnClicked={onAddEventBtnClicked}
      setPage={setPage}
      onItemClicked={onItemClicked}
    />
  );
};

export default EventListContainer;
