import useToken from '@hooks/store/useToken';
import { alertMessage } from '@libs/alertMessage';
import { apiRoute, requestSecurePost } from '@libs/api/api';
import { getEndOfDate, getStartOfDate } from '@libs/factory';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import EventAdd from '../components/EventAdd';

const EventAddContainer = () => {
  const navigate = useNavigate();

  const { getAccessToken } = useToken();

  const [eventFile, setEventFile] = useState<File | null>(null);
  const [referenceUrl, setReferenceUrl] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const eventFileUrl = useMemo(() => {
    return eventFile ? URL.createObjectURL(eventFile as Blob) : '';
  }, [eventFile]);

  const onEventFileChanged = useCallback((file: File) => {
    setEventFile(file);
  }, []);

  const onAddBtnClicked = useCallback(async () => {
    if (!startDate || !endDate) {
      alertMessage('게시기간을 입력해주세요');
      return;
    }

    const form = new FormData();

    form.append('startDate', getStartOfDate(startDate).toString());
    form.append('endDate', getEndOfDate(endDate).toString());
    referenceUrl && form.append('referenceUrl', referenceUrl);
    eventFile && form.append('eventFile', eventFile);

    const { config } = await requestSecurePost(
      apiRoute.events.addEvents,
      {
        'Content-Type': 'multipart/form-data',
      },
      form,
      getAccessToken()!,
    );

    if (config.status === 201) {
      navigate(-1);
    }
  }, [startDate, endDate, referenceUrl, eventFile, navigate]);

  return (
    <EventAdd
      startDate={startDate}
      endDate={endDate}
      eventFile={eventFile}
      eventFileUrl={eventFileUrl}
      referenceUrl={referenceUrl}
      setStartDate={setStartDate}
      setEndDate={setEndDate}
      setEventFile={setEventFile}
      setReferenceUrl={setReferenceUrl}
      onEventFileChanged={onEventFileChanged}
      onAddBtnClicked={onAddBtnClicked}
    />
  );
};

export default EventAddContainer;
