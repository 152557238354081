import useToken from '@hooks/store/useToken';
import { alertMessage } from '@libs/alertMessage';
import {
  apiRoute,
  requestGet,
  requestSecureDelete,
  requestSecurePatch,
} from '@libs/api/api';
import { uriToFile } from '@libs/trans';
import {
  FaqCategoryListItemTypes,
  FaqDetailTypes,
  FaqListItemTypes,
} from '@typedef/components/FAQ/faq.types';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import FAQDetail from '../components/FAQDetail';

const FAQDetailContainer = () => {
  const navigate = useNavigate();

  const { getAccessToken } = useToken();

  const { item } = useLocation().state as {
    item: FaqListItemTypes;
  };

  const [faq, setFaq] = useState<FaqDetailTypes | null>(null);
  const [images, setImages] = useState<(File | null)[]>([]);

  const [categoryOption, setCategoryOption] = useState<
    FaqCategoryListItemTypes[]
  >([]);

  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [category, setCategory] = useState<FaqCategoryListItemTypes | null>(
    null,
  );

  const loadDetail = useCallback(async () => {
    const { data, config } = await requestGet<FaqDetailTypes>(
      apiRoute.faq.detail + item.faqUid,
      {},
    );

    if (config.status === 200) {
      setTitle(data.title);
      setContent(data.contents);
      setCategory(data.faqCategory);
      data.faqFile &&
        setImages(
          await Promise.all(
            data.faqFile.map((item) => uriToFile(item, 'image/*')),
          ),
        );

      setFaq(data);
    } else {
      alertMessage('상세정보를 불러오지 못했습니다');
    }
  }, []);

  const loadCategories = useCallback(async () => {
    const { data, config } = await requestGet<FaqCategoryListItemTypes[]>(
      apiRoute.faq.category.getCategory,
      {},
    );

    if (config.status === 200) {
      setCategoryOption(data);
    } else {
      alertMessage('카테고리를 불러오지 못했습니다');
    }
  }, []);

  const onUpdateBtnClicked = useCallback(async () => {
    const form = new FormData();

    if (category?.faqCategoryUid) {
      form.append('faqCategoryUid', category.faqCategoryUid + '');
    }

    form.append('title', title);
    form.append('contents', content);

    images?.forEach((item) => {
      form.append('faqFile', item as File);
    });

    const { config } = await requestSecurePatch(
      apiRoute.faq.updateFags + faq?.faqUid,
      {},
      form,
      getAccessToken()!,
    );

    if (config.status === 202) {
      navigate(-1);
    } else {
      alertMessage('FAQ 수정에 실패 했습니다');
    }
  }, [faq, category, title, content, images, navigate]);

  const onDeleteBtnClicked = useCallback(async () => {
    const { config } = await requestSecureDelete(
      apiRoute.faq.deleteFags + faq?.faqUid,
      {},
      getAccessToken()!,
    );

    if (config.status === 202) {
      alertMessage('삭제가 완료되었습니다.');
      navigate(-1);
    } else {
      alertMessage('삭제에 실패 했습니다.');
    }
  }, [faq, getAccessToken, navigate]);

  const onBackBtnClicked = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  useEffect(() => {
    loadCategories();
  }, [loadCategories]);

  useEffect(() => {
    loadDetail();
  }, [loadDetail]);

  return faq ? (
    <FAQDetail
      content={content}
      faq={faq}
      images={images}
      categoryOption={categoryOption}
      onUpdateBtnClicked={onUpdateBtnClicked}
      onDeleteBtnClicked={onDeleteBtnClicked}
      onBackBtnClicked={onBackBtnClicked}
      setCategory={setCategory}
      setImages={setImages}
      setTitle={setTitle}
      setContent={setContent}
    />
  ) : (
    <div className='content-root'></div>
  );
};

export default FAQDetailContainer;
