import CMultiFileInputContainer from '@components/common/CFileInput/containers/CMultiFileInputContainer';
import CCheckboxContainer from '@components/common/CCheckbox/containers/CCheckboxContainer';
import CInputContainer from '@components/common/CInput/containers/CInputContainer';

import {
  CategoryTypes,
  CodyProductsTypes,
  ItemDescriptionTypes,
  OptionNameTypes,
  OptionTypes,
  ParsedOptionsTypes,
  ProductDescriptionTypes,
  ProductDetailTypes,
  ProductOptionsTypes,
  SubCategoryTypes,
} from '@typedef/components/Item/item.types';
import React from 'react';
import OptionPopupContainer from '../containers/OptionPopupContainer';
import '../styles/item.detail.styles.css';
import image from '@components/common/assets/image';
import ItemDescriptionContainer from '../containers/ItemDescriptionContainer';
import { checkIncludeNotNumber } from '@libs/validation';

type Props = {
  todayGet: number;
  productDetail?: ProductDetailTypes;
  images: (File | null)[];
  parsedOptions: ParsedOptionsTypes[];
  productOptions: ProductOptionsTypes[];
  codyList: CodyProductsTypes[];
  descriptionList: ItemDescriptionTypes[];
  productOptionsStock: { [key: string]: string };
  category: CategoryTypes[];
  subCategory: SubCategoryTypes[];
  selectedCategory: string;
  selectedSubCategory: string;
  setImages: React.Dispatch<React.SetStateAction<(File | null)[]>>;
  setProductName: React.Dispatch<React.SetStateAction<string>>;
  setPrice: React.Dispatch<React.SetStateAction<number>>;
  setTodayGet: React.Dispatch<React.SetStateAction<number>>;
  setModelHeight: React.Dispatch<React.SetStateAction<string>>;
  setModelWeight: React.Dispatch<React.SetStateAction<string>>;
  setModelNormalSize: React.Dispatch<React.SetStateAction<string>>;
  setModelSize: React.Dispatch<React.SetStateAction<string>>;
  setParsedOptions: React.Dispatch<React.SetStateAction<ParsedOptionsTypes[]>>;
  setProductOptions: React.Dispatch<
    React.SetStateAction<ProductOptionsTypes[]>
  >;
  setCodyList: React.Dispatch<React.SetStateAction<CodyProductsTypes[]>>;
  setProductOptionsStock: React.Dispatch<
    React.SetStateAction<{ [key: string]: string }>
  >;
  setSelectedCategory: React.Dispatch<React.SetStateAction<string>>;
  setSelectedSubCategory: React.Dispatch<React.SetStateAction<string>>;
  onDeleteCodyItemClicked: (idx: number) => void;
  onAddCodyItemClicked: () => void;
  onOptionPopupClicked: (idx: number) => void;
  onDeleteOptionClicked: (idx: number) => void;
  onSubmitClicked: () => void;
  onDeleteItemClicked: () => void;
  onUpdateDescriptionFile: (
    fileList: (File | null)[],
    descriptionIdx: number,
  ) => void;
  onUpdateDescription: (description: string, descriptionIdx: number) => void;
  onAddDescriptionClicked: (idx: number) => void;
  onDeleteDescriptionClicked: (idx: number) => void;
};

const ItemDetail = ({
  category,
  subCategory,
  selectedCategory,
  selectedSubCategory,
  todayGet,
  productDetail,
  images,
  parsedOptions,
  codyList,
  descriptionList,
  productOptionsStock,
  setImages,
  setProductName,
  setPrice,
  setTodayGet,
  setModelHeight,
  setModelWeight,
  setModelNormalSize,
  setModelSize,
  setProductOptionsStock,
  setSelectedCategory,
  setSelectedSubCategory,
  onDeleteCodyItemClicked,
  onAddCodyItemClicked,
  onOptionPopupClicked,
  onDeleteOptionClicked,
  onSubmitClicked,
  onDeleteItemClicked,
  onAddDescriptionClicked,
  onUpdateDescriptionFile,
  onUpdateDescription,
  onDeleteDescriptionClicked,
}: Props) => {
  return (
    <div className='content-root item-detail'>
      <div className='title-container'>
        <div className='title-item'>
          <div className='title-label'>{'상품 관리'}</div>
          <div className='subtitle-label'>{'상품 관리페이지입니다.'}</div>
        </div>
      </div>
      <div className='button-container'>
        <button
          className='delete-btn'
          type='button'
          onClick={onDeleteItemClicked}>
          삭제하기
        </button>
        <button className='primary-btn' type='submit' form='item-detail-form'>
          저장하기
        </button>
      </div>
      <div className={`content-root `}>
        <form
          className='table-container basic'
          id='item-detail-form'
          onSubmit={(e) => {
            e.preventDefault();
            onSubmitClicked();
          }}>
          <div className={`body-section`}>
            <CMultiFileInputContainer
              onSelectdFilesChanged={setImages}
              initialValue={images as File[]}
            />
            <div className='input-section'>
              <div className='column'>
                <div className='row'>
                  <div className='item'>
                    <CInputContainer
                      isRequired={true}
                      containerStyle={{
                        width: 'calc((100vw - 400px) / 3)',
                      }}
                      containerType={{
                        type: 'outline',
                        adaptive: false,
                      }}
                      type='text'
                      initialValue={productDetail?.productName}
                      onInputChanged={setProductName}
                      inputId='product-detail-name-input'
                      label='상품이름'
                    />
                  </div>
                </div>
                <div className='row'>
                  <div className='item'>
                    <CInputContainer
                      isRequired={true}
                      containerStyle={{
                        width: 'calc((100vw - 400px) / 3)',
                      }}
                      containerType={{
                        type: 'outline',
                        adaptive: false,
                      }}
                      type='text'
                      testValue={checkIncludeNotNumber}
                      initialValue={String(productDetail?.price)}
                      onInputChanged={(item) => setPrice(Number(item))}
                      inputId='product-detail-price-input'
                      label='상품가격'
                    />
                  </div>
                </div>
                <div className='row'>
                  <div className='item today-get'>
                    <CCheckboxContainer
                      label='오늘겟 여부'
                      colorType='fill'
                      checkType='square'
                      initialState={Boolean(todayGet)}
                      onCheckChanged={(checked) => setTodayGet(Number(checked))}
                    />
                  </div>
                </div>
                <div className={'row'}>
                  <select
                    className={'category'}
                    placeholder='대분류'
                    name='category-select'
                    id='category-select'
                    value={selectedCategory}
                    onChange={(e) => {
                      setSelectedCategory(e.target.value);
                    }}
                    style={{
                      background: `url(${image.selectArrow}) no-repeat 95%`,
                    }}>
                    <option value='category' disabled>
                      {'대분류'}
                    </option>
                    {category.map((item, idx) => {
                      return (
                        <option
                          key={idx}
                          data-category={item.name}
                          value={item.productCategoryUid}
                          className={'category-option'}>
                          {item.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className='row'>
                  <select
                    className={'category'}
                    placeholder='중분류'
                    name='sub-category-select'
                    id='sub-category-select'
                    onChange={(e) => {
                      setSelectedSubCategory(e.target.value);
                    }}
                    style={{
                      background: `url(${image.selectArrow}) no-repeat 95%`,
                    }}
                    value={selectedSubCategory}>
                    <option value='category' disabled>
                      {'중분류'}
                    </option>
                    {subCategory.map((item, idx) => {
                      return (
                        <option
                          key={idx}
                          value={item.productCategoryDetailUid}
                          className={'category-option'}>
                          {item.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className='column'>
                <div className='row'>
                  <div className='item'>
                    <CInputContainer
                      isRequired={true}
                      containerStyle={{
                        width: 'calc((100vw - 400px) / 3)',
                      }}
                      containerType={{
                        type: 'outline',
                        adaptive: false,
                      }}
                      type='text'
                      testValue={checkIncludeNotNumber}
                      initialValue={productDetail?.modelHeight}
                      onInputChanged={setModelHeight}
                      inputId='product-detail-model-height-input'
                      label='키'
                    />
                  </div>
                </div>
                <div className='row'>
                  <div className='item'>
                    <CInputContainer
                      isRequired={true}
                      containerStyle={{
                        width: 'calc((100vw - 400px) / 3)',
                      }}
                      containerType={{
                        type: 'outline',
                        adaptive: false,
                      }}
                      type='text'
                      testValue={checkIncludeNotNumber}
                      initialValue={productDetail?.modelWeight}
                      onInputChanged={setModelWeight}
                      inputId='product-detail-model-weight-input'
                      label='몸무게'
                    />
                  </div>
                </div>
                <div className='row'>
                  <div className='item'>
                    <CInputContainer
                      isRequired={true}
                      containerStyle={{
                        width: 'calc((100vw - 400px) / 3)',
                      }}
                      containerType={{
                        type: 'outline',
                        adaptive: false,
                      }}
                      type='text'
                      initialValue={productDetail?.modelNormalSize}
                      onInputChanged={setModelNormalSize}
                      inputId='product-detail-model-nomal-input'
                      label='평소 사이즈'
                    />
                  </div>
                </div>
                <div className='row'>
                  <div className='item'>
                    <CInputContainer
                      isRequired={true}
                      containerStyle={{
                        width: 'calc((100vw - 400px) / 3)',
                      }}
                      containerType={{
                        type: 'outline',
                        adaptive: false,
                      }}
                      type='text'
                      initialValue={productDetail?.modelSize}
                      onInputChanged={setModelSize}
                      inputId='product-detail-model-size-input'
                      label='착용사이즈'
                    />
                  </div>
                </div>
              </div>
              <div className='column'>
                <div className='row'>
                  <div className='item option'>
                    <span className='item-label'>{'옵션종류'}</span>
                    <div className='option-list'>
                      {parsedOptions &&
                        parsedOptions.map((item, idx) => {
                          return (
                            <div className='option-item' key={idx}>
                              <span className='opt-name'>{item.optName}</span>
                              <span className='opt'>
                                {item.opt.join(' , ')}
                              </span>
                              <button
                                className='update-option-btn'
                                onClick={(e) => {
                                  e.preventDefault();
                                  onOptionPopupClicked(idx);
                                }}>
                                {'수정'}
                              </button>
                              <button
                                className='delete-option-btn'
                                onClick={(e) => {
                                  e.preventDefault();
                                  onDeleteOptionClicked(idx);
                                }}>
                                {'삭제'}
                              </button>
                            </div>
                          );
                        })}
                    </div>
                    <button
                      className='add-option-btn'
                      onClick={(e) => {
                        e.preventDefault();
                        onOptionPopupClicked(parsedOptions.length);
                      }}>
                      {'옵션추가'}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
        <div className='table-container stock'>
          <div className='title-wrapper'>
            <span className='table-title'>{'재고 설정'}</span>
          </div>
          <div className='header-section'>
            <div className='item'>{'옵션명'}</div>
            <div className='item'>{'재고'}</div>
          </div>
          <div className='body-section'>
            {Object.keys(productOptionsStock).map((key, idx) => {
              return (
                <div className=' row' key={idx}>
                  <div className='item'>{key}</div>
                  <div className='item'>
                    <input
                      className='stock-input'
                      type='text'
                      value={productOptionsStock[key]}
                      onChange={(e) => {
                        if (/[^0-9]/.test(e.target.value)) {
                          return;
                        }

                        if (/^0/.test(e.target.value)) {
                          setProductOptionsStock((prev) => {
                            const clone = { ...prev };
                            clone[key] = '0';
                            return clone;
                          });
                          return;
                        }
                        setProductOptionsStock((prev) => {
                          const clone = { ...prev };
                          clone[key] = e.target.value;
                          return clone;
                        });
                      }}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className='table-container'>
          <div className='title-wrapper'>
            <span className='table-title'>{'상세설명'}</span>
          </div>
          <div className={'body-section description'}>
            <div className={`${'input-container'} ${'description'}`}>
              {descriptionList.map((item, descriptionIdx) => {
                return (
                  <ItemDescriptionContainer
                    key={`${descriptionIdx}${item.descriptionFile?.length}`}
                    idx={descriptionIdx}
                    itemDescription={item}
                    onUpdateDescriptionFile={onUpdateDescriptionFile}
                    onUpdateDescription={onUpdateDescription}
                    onDeleteDescriptionClicked={onDeleteDescriptionClicked}
                  />
                );
              })}
              <button
                className={'add-btn'}
                onClick={(e) => {
                  e.preventDefault();
                  onAddDescriptionClicked(descriptionList.length);
                }}>
                {'설명 추가 +'}
              </button>
            </div>
          </div>
        </div>
        <div className='table-container cody'>
          <div className='title-wrapper'>
            <span className='table-title'>{'코디 상품'}</span>
            <button
              className='primary-btn add-cody'
              onClick={onAddCodyItemClicked}>
              {'추가하기'}
            </button>
          </div>
          <div className='header-section'>
            <div className='item'>{'상품사진'}</div>
            <div className='item'>{'상품명'}</div>
            <div className='item'>{'가격'}</div>
            <div className='item'> {'특가상품여부'}</div>
            <div className='item'> {'삭제하기'}</div>
          </div>
          <div className='body-section'>
            {codyList.map((item, idx) => {
              return (
                <div className='row' key={idx} onClick={() => {}}>
                  <div className='item'>
                    <img
                      className='image'
                      src={item.productFile[0] ?? image.placeholderImg}
                      alt='상품 사진'
                      onError={(e) => {
                        e.currentTarget.src = image.placeholderImg;
                      }}
                    />
                  </div>
                  <div className='item'>{item.name}</div>
                  <div className='item'>{item.price.toLocaleString()}</div>
                  <div className='item'>
                    {item.discountedPrice ? '특가상품' : '일반상품'}
                  </div>
                  <div className='item'>
                    <button
                      onClick={() => onDeleteCodyItemClicked(idx)}
                      className='delete-btn delete-cody'>
                      삭제하기
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ItemDetail;
