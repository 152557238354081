import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import CSearch from '../CSearch';

type Props = {
  onSearchEntered: (keyword: string) => void;
  inputId: string;
  placeholder?: string;
};

const CSearchContainer = ({
  onSearchEntered,
  inputId,
  placeholder = '',
}: Props) => {
  const [input, setInput] = useState('');
  const [focused, setFocused] = useState(false);

  const formRef = useRef<HTMLFormElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const deleteBtnVisible = useMemo(() => {
    return input !== '';
  }, [input]);

  const onSearchBtnClicked = useCallback(() => {
    onSearchEntered(input);
  }, [onSearchEntered, input]);

  const onDeleteBtnClicked = useCallback(() => {
    if (inputRef?.current) {
      inputRef.current.value = '';
      setInput('');
    }
  }, [inputRef]);

  const onFocused = useCallback(() => {
    setFocused(true);
  }, []);

  const onBlured = useCallback(() => {
    setFocused(false);
  }, []);

  useEffect(() => {
    if (formRef.current) {
      if (focused || deleteBtnVisible) {
        formRef.current.style.border = '1px solid #1379D8';
      } else {
        formRef.current.style.border = '1px solid #E5E5EC';
      }
    }
  }, [formRef, focused, deleteBtnVisible]);

  return (
    <CSearch
      formRef={formRef}
      inputRef={inputRef}
      deleteBtnVisible={deleteBtnVisible}
      setInput={setInput}
      onSearchBtnClicked={onSearchBtnClicked}
      onDeleteBtnClicked={onDeleteBtnClicked}
      inputId={inputId}
      placeholder={placeholder}
      onFocused={onFocused}
      onBlured={onBlured}
    />
  );
};

export default CSearchContainer;
