import useToken from '@hooks/store/useToken';
import { alertMessage } from '@libs/alertMessage';
import { apiRoute, BasicApiResponse, requestSecurePost } from '@libs/api/api';
import { getEndOfDate } from '@libs/factory';
import React from 'react';
import { useCallback } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CouponAdd from '../components/CouponAdd';

const CouponAddContainer = () => {
  const navigate = useNavigate();
  const { getAccessToken } = useToken();
  const [name, setName] = useState('');
  const [amount, setAmount] = useState(0);
  const [condition, setCondition] = useState(0);
  const [limitDate, setLimitDate] = useState<string | null>(null);
  const [isInfinite, setIsInfinite] = useState(false);

  const onAddCouponClicked = useCallback(async () => {
    if (!isInfinite && !limitDate) {
      alertMessage('기간을 입력해주세요');
      return;
    }

    const bodyObj: any = {
      amount: amount,
      condition: condition,
      name,
    };
    if (!isInfinite) bodyObj['limitDate'] = getEndOfDate(limitDate!);

    const {
      data,
      config: { status },
    } = await requestSecurePost<BasicApiResponse<{}>>(
      apiRoute.coupon.addCoupon,
      {},
      bodyObj,
      getAccessToken()!,
    );

    if (status !== 201) return;
    navigate(-1);
  }, [
    limitDate,
    name,
    amount,
    condition,
    isInfinite,
    getAccessToken,
    navigate,
  ]);

  return (
    <CouponAdd
      name={name}
      setName={setName}
      amount={amount}
      setAmount={setAmount}
      condition={condition}
      setCondition={setCondition}
      limitDate={limitDate}
      setLimitDate={setLimitDate}
      isInfinit={isInfinite}
      setIsInfinite={setIsInfinite}
      onAddCouponClicked={onAddCouponClicked}
    />
  );
};

export default CouponAddContainer;
