import useToken from '@hooks/store/useToken';
import { alertMessage } from '@libs/alertMessage';
import {
  apiRoute,
  requestSecureDelete,
  requestSecurePatch,
} from '@libs/api/api';
import { MainBannerListItemTypes } from '@typedef/components/MainBanner/main.banner.types';
import React, { useCallback, useEffect, useState } from 'react';
import { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import MainBannerDetail from '../components/MainBannerDetail';

const MainBannerDetailContainer = () => {
  const { getAccessToken } = useToken();
  const navigate = useNavigate();
  const state = useLocation().state as { data: MainBannerListItemTypes };

  const [title, setTitle] = useState(state.data.title);
  const [bannerFile, setBannerFile] = useState<File | null>(null);
  const [thumbnail, setThumbnail] = useState<File | null>(null);
  const [desc, setDesc] = useState(state.data.description ?? '');
  const [preview, setPreview] = useState(state.data.thumbnail);

  const thumbnailImage = useMemo(() => {
    return thumbnail
      ? URL.createObjectURL(thumbnail as Blob)
      : state.data.thumbnail;
  }, [thumbnail]);

  const bannerFileImage = useMemo(() => {
    return bannerFile
      ? URL.createObjectURL(bannerFile as Blob)
      : state.data.bannerFile;
  }, [bannerFile]);

  const onPreviewBtnClicked = useCallback(
    (type: 'thumbnail' | 'banner') => {
      if (type === 'thumbnail') {
        setPreview(thumbnailImage);
      } else {
        setPreview(bannerFileImage);
      }
    },
    [thumbnailImage, bannerFileImage],
  );

  const onThumbnailChanged = useCallback((file: File) => {
    setThumbnail(file);
  }, []);

  const onBannerFileChanged = useCallback((file: File) => {
    setBannerFile(file);
  }, []);

  const onTitleChanged = useCallback((value: string) => {
    setTitle(value);
  }, []);

  const onDescChanged = useCallback((value: string) => {
    setDesc(value);
  }, []);

  const onEditBtnClicked = useCallback(async () => {
    const form = new FormData();

    form.append('title', title);
    thumbnail && form.append('thumbnail', thumbnail as Blob);
    bannerFile && form.append('bannerFile', bannerFile as Blob);
    form.append('description', desc);

    const { data, config } = await requestSecurePatch(
      apiRoute.banners.updateBanner + state.data.bannerUid,
      { 'Content-Type': 'multipart/form-data' },
      form,
      getAccessToken()!,
    );

    if (config.status !== 202) {
      alertMessage('수정 요청이 실패했습니다.');
    }

    alertMessage('수정되었습니다');
    navigate(-1);
  }, [title, thumbnail, bannerFile, desc, state, navigate]);

  const onDeleteBtnClicked = useCallback(async () => {
    if (!window.confirm('정말로 삭제하시겠습니까?')) {
      return;
    }

    const { config } = await requestSecureDelete(
      apiRoute.banners.deleteBanner + state.data.bannerUid,
      {},
      getAccessToken()!,
    );

    if (config.status === 202) {
      alertMessage('배너가 삭제되었습니다');
      navigate(-1);
    } else {
      alertMessage('배너 삭제에 실패했습니다');
    }
  }, [state, navigate]);

  return (
    <MainBannerDetail
      data={state.data}
      title={title}
      desc={desc}
      preview={preview}
      thumbnail={thumbnail}
      thumbnailImage={thumbnailImage}
      bannerFile={bannerFile}
      bannerFileImage={bannerFileImage}
      onThumbnailChanged={onThumbnailChanged}
      onBannerFileChanged={onBannerFileChanged}
      onTitleChanged={onTitleChanged}
      onDescChanged={onDescChanged}
      onDeleteBtnClicked={onDeleteBtnClicked}
      onEditBtnClicked={onEditBtnClicked}
      onPreviewBtnClicked={onPreviewBtnClicked}
    />
  );
};

export default MainBannerDetailContainer;
