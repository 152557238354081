import CInputContainer from '@components/common/CInput/containers/CInputContainer';
import {
  CategoryListItemTypes,
  EditCategoryItemTypes,
  SubCategoryListItemTypes,
} from '@typedef/components/Category/category.types';
import React from 'react';
import './styles/category.styles.css';

type Props = {
  categoryList: CategoryListItemTypes[];
  subCategoryList: SubCategoryListItemTypes[];
  editItem: EditCategoryItemTypes;
  editInput: string;
  setEditInput: React.Dispatch<React.SetStateAction<string>>;
  setEditItem: React.Dispatch<React.SetStateAction<EditCategoryItemTypes>>;
  setTmpCategory: React.Dispatch<React.SetStateAction<string>>;
  setTmpSubCategory: React.Dispatch<React.SetStateAction<string>>;
  onCategoryAddBtnClicked: () => void;
  onSubCategoryAddBtnClicked: () => void;
  setSelectedCategory: React.Dispatch<
    React.SetStateAction<CategoryListItemTypes | null>
  >;
  onCategoryDeleteBtnClicked: (
    item: CategoryListItemTypes,
    idx: number,
  ) => void;
  onSubCategoryDeleteClicked: (
    item: SubCategoryListItemTypes,
    idx: number,
  ) => void;
  onCategoryUpClicked: (item: CategoryListItemTypes, idx: number) => void;
  onCategoryDownClicked: (item: CategoryListItemTypes, idx: number) => void;
  onSubCategoryUpClicked: (item: SubCategoryListItemTypes, idx: number) => void;
  onSubCategoryDownClicked: (
    item: SubCategoryListItemTypes,
    idx: number,
  ) => void;
  onEditBtnClicked: (type: 'main' | 'sub', index: number, uid: string) => void;
  onEditDoneBtnClicked: () => void;
};

const Category = ({
  categoryList,
  subCategoryList,
  editItem,
  editInput,
  setEditInput,
  setEditItem,
  setTmpCategory,
  setTmpSubCategory,
  onCategoryAddBtnClicked,
  onSubCategoryAddBtnClicked,
  setSelectedCategory,
  onCategoryDeleteBtnClicked,
  onSubCategoryDeleteClicked,
  onCategoryUpClicked,
  onCategoryDownClicked,
  onSubCategoryUpClicked,
  onSubCategoryDownClicked,
  onEditBtnClicked,
  onEditDoneBtnClicked,
}: Props) => {
  return (
    <div className='content-root category'>
      <div className='title-container'>
        <div className='title-item'>
          <div className='title-label'>{'카테고리'}</div>
          <div className='subtitle-label'>{'카테고리 관리 페이지 입니다'}</div>
        </div>
      </div>
      <div
        className='content-root'
        style={{
          flexDirection: 'row',
        }}>
        <div
          className='table-container'
          style={{
            marginRight: '16px',
          }}>
          <div className='header-section'>
            <div className='item'>대분류</div>
          </div>
          <div className='body-section'>
            {categoryList.map((item, idx) => (
              <div
                key={idx}
                className='row'
                onClick={() => {
                  setSelectedCategory(item);
                }}>
                <div className='item'>{idx + 1}</div>
                <div className='item'>
                  {editItem.type === 'main' &&
                  editItem.uid === item.productCategoryUid ? (
                    <input
                      type='text'
                      placeholder={item.name}
                      value={editInput}
                      onChange={(e) => setEditInput(e.target.value)}
                    />
                  ) : (
                    <span>{item.name}</span>
                  )}
                </div>
                <div className='item'>
                  {idx !== 0 && (
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        onCategoryUpClicked(item, idx);
                      }}
                      style={{
                        width: '20px',
                        height: '20px',
                        margin: '4px',
                      }}>
                      {'⇑'}
                    </button>
                  )}
                  {categoryList.length !== idx + 1 && (
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        onCategoryDownClicked(item, idx);
                      }}
                      style={{
                        width: '20px',
                        height: '20px',
                        margin: '4px',
                      }}>
                      {'⇓'}
                    </button>
                  )}
                </div>
                <div className='item'>
                  {editItem.type === 'main' &&
                  editItem.uid === item.productCategoryUid ? (
                    <button
                      style={{ width: '150px' }}
                      className='primary-btn'
                      onClick={(e) => {
                        e.stopPropagation();
                        onEditDoneBtnClicked();
                      }}>
                      수정완료
                    </button>
                  ) : (
                    <button
                      style={{ width: '150px' }}
                      className='primary-btn'
                      onClick={(e) => {
                        e.stopPropagation();
                        onEditBtnClicked('main', idx, item.productCategoryUid);
                      }}>
                      수정하기
                    </button>
                  )}
                  <button
                    style={{ width: '150px' }}
                    className='delete-btn'
                    onClick={(e) => {
                      e.stopPropagation();
                      onCategoryDeleteBtnClicked(item, idx);
                    }}>
                    삭제하기
                  </button>
                </div>
              </div>
            ))}
          </div>
          <div className='footer-section'>
            <CInputContainer
              containerType={{
                type: 'outline',
                adaptive: true,
              }}
              placeholder='대분류'
              label='대분류'
              inputId='category-category-input'
              onInputChanged={setTmpCategory}
              type='text'
            />
            <button className='primary-btn' onClick={onCategoryAddBtnClicked}>
              추가하기
            </button>
          </div>
        </div>
        <div className='table-container'>
          <div className='header-section'>
            <div className='item'>중분류</div>
          </div>
          <div className='body-section'>
            {subCategoryList.map((item, idx) => (
              <div key={idx} className='row'>
                <div className='item'>{idx + 1}</div>
                <div className='item'>
                  {editItem.type === 'sub' &&
                  editItem.uid === item.productCategoryDetailUid ? (
                    <input
                      type='text'
                      placeholder={item.name}
                      value={editInput}
                      onChange={(e) => setEditInput(e.target.value)}
                    />
                  ) : (
                    <span>{item.name}</span>
                  )}
                </div>
                <div className='item'>
                  {idx !== 0 && (
                    <button
                      onClick={() => onSubCategoryUpClicked(item, idx)}
                      style={{
                        width: '20px',
                        height: '20px',
                        margin: '4px',
                      }}>
                      {'⇑'}
                    </button>
                  )}
                  {subCategoryList.length !== idx + 1 && (
                    <button
                      onClick={() => onSubCategoryDownClicked(item, idx)}
                      style={{
                        width: '20px',
                        height: '20px',
                        margin: '4px',
                      }}>
                      {'⇓'}
                    </button>
                  )}
                </div>
                <div className='item'>
                  {editItem.type === 'sub' &&
                  editItem.uid === item.productCategoryDetailUid ? (
                    <button
                      style={{ width: '150px' }}
                      className='primary-btn'
                      onClick={(e) => {
                        e.stopPropagation();
                        onEditDoneBtnClicked();
                      }}>
                      수정완료
                    </button>
                  ) : (
                    <button
                      style={{ width: '150px' }}
                      className='primary-btn'
                      onClick={(e) => {
                        e.stopPropagation();
                        onEditBtnClicked(
                          'sub',
                          idx,
                          item.productCategoryDetailUid,
                        );
                      }}>
                      수정하기
                    </button>
                  )}
                  <button
                    style={{ width: '150px' }}
                    className='delete-btn'
                    onClick={(e) => {
                      e.stopPropagation();
                      onSubCategoryDeleteClicked(item, idx);
                    }}>
                    삭제하기
                  </button>
                </div>
              </div>
            ))}
          </div>
          <div className='footer-section'>
            <CInputContainer
              containerType={{
                type: 'outline',
                adaptive: true,
              }}
              placeholder='중분류'
              label='중분류'
              inputId='category-sub-category-input'
              onInputChanged={setTmpSubCategory}
              type='text'
            />
            <button
              className='primary-btn'
              onClick={onSubCategoryAddBtnClicked}>
              추가하기
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Category;
