import useToken from '@hooks/store/useToken';
import { alertMessage } from '@libs/alertMessage';
import { apiRoute, BasicListDataType, requestSecureGet } from '@libs/api/api';
import { PaymentListItemTypes } from '@typedef/components/Order/order.types';
import { UserPaymentListItemTypes } from '@typedef/components/User/user.types';
import React, { useCallback, useEffect, useState } from 'react';
import UserPaymentList from '../components/UserPaymentList';

type Props = {
  userUid: string;
};

const UserPaymentListContainer = ({ userUid }: Props) => {
  const { getAccessToken } = useToken();

  const [paymentList, setPaymentList] = useState<PaymentListItemTypes[]>([]);

  const [paymentListMaxPage, setPaymentListMaxPage] = useState(1);
  const [paymentListPage, setPaymentListPage] = useState(0);

  const loadPaymentList = useCallback(async () => {
    const { data, config } = await requestSecureGet<
      BasicListDataType<PaymentListItemTypes>
    >(
      apiRoute.user.getUserPaymentList +
        userUid +
        `?page=${paymentListPage}&perPage=${4}`,
      {},
      getAccessToken()!,
    );

    if (config.status === 200) {
      setPaymentList(data.rows);
      setPaymentListMaxPage(data.meta.maxPage);
    } else {
      alertMessage('목록을 불러오지 못했습니다');
    }
  }, [paymentListPage]);

  useEffect(() => {
    loadPaymentList();
  }, [loadPaymentList]);

  return (
    <UserPaymentList
      paymentList={paymentList}
      paymentListMaxPage={paymentListMaxPage}
      paymentListPage={paymentListPage}
      setPaymentListPage={setPaymentListPage}
    />
  );
};

export default UserPaymentListContainer;
