import {
  UserDetailTabTypes,
  UserDetailTypes,
  UserGenderTypes,
  UserListItemTypes,
} from '@typedef/components/User/user.types';
import React from 'react';
import UserCouponListContainer from '../containers/UserCouponListContainer';
import UserPaymentListContainer from '../containers/UserPaymentListContainer';
import '../styles/user.detail.styles.css';
import image from '@components/common/assets/image';
import { formatDate } from '@libs/factory';
import { checkIncludeNotNumber } from '@libs/validation';

type Props = {
  item: UserListItemTypes;
  userDetail: UserDetailTypes;
  tab: UserDetailTabTypes;
  tmpPoint: number;
  onInputChanged: (
    key: keyof UserDetailTypes,
    value: string | number | UserGenderTypes,
  ) => void;
  onTabCahnged: (value: UserDetailTabTypes) => void;
  onTmpPointChanged: (value: number) => void;
  onPointAddBtnClicked: () => void;
  onSaveBtnClicked: () => void;
  onDeleteUserBtnClicked: () => void;
};

const UserDetail = ({
  item,
  userDetail,
  tab,
  tmpPoint,
  onInputChanged,
  onTabCahnged,
  onTmpPointChanged,
  onPointAddBtnClicked,
  onSaveBtnClicked,
  onDeleteUserBtnClicked,
}: Props) => {
  return (
    <div className='content-root user-detail'>
      <div className='title-container'>
        <div className='title-item'>
          <div className='title-label'>{userDetail.userName}</div>
          <div className='subtitle-label'>{userDetail.email}</div>
        </div>
      </div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onSaveBtnClicked();
        }}>
        <div className='button-container'>
          <button
            className='delete-btn'
            type='button'
            onClick={onDeleteUserBtnClicked}>
            삭제하기
          </button>
          <button className='primary-btn' type='submit'>
            저장하기
          </button>
        </div>
        <div className='table-container basic-info'>
          <div className='body-section'>
            <div className='col'>
              <div className='input-container'>
                <label htmlFor='name-input' className='input-label'>
                  {'이름'}
                </label>
                <input
                  required
                  type='text'
                  className='input-box'
                  id='name-input'
                  value={userDetail.name}
                  onChange={(e) => onInputChanged('name', e.target.value)}
                />
              </div>
              <div className='input-container'>
                <label htmlFor='name-input' className='input-label'>
                  {'이메일'}
                </label>
                <input
                  required
                  type='text'
                  className='input-box'
                  id='name-input'
                  value={userDetail.email}
                  onChange={(e) => onInputChanged('email', e.target.value)}
                />
              </div>
              <div className='input-container'>
                <label htmlFor='name-input' className='input-label'>
                  {'전화번호'}
                </label>
                <input
                  required
                  type='text'
                  className='input-box'
                  id='name-input'
                  value={userDetail.phone}
                  onChange={(e) => onInputChanged('phone', e.target.value)}
                />
              </div>
              <div className='input-container'>
                <label htmlFor='name-input' className='input-label'>
                  {'생년월일'}
                </label>
                <input
                  type='date'
                  className='input-box'
                  id='name-input'
                  value={userDetail.birth}
                  onChange={(e) => onInputChanged('birth', e.target.value)}
                />
              </div>
            </div>
            <div className='col'>
              <div className='input-container'>
                <label htmlFor='name-input' className='input-label'>
                  {'성별'}
                </label>
                <select
                  value={userDetail.gender}
                  onChange={(e) => onInputChanged('gender', e.target.value)}
                  name='select-box-gender'
                  id='select-box-gender'
                  className='input-box'
                  style={{
                    background: `url(${image.selectArrow}) no-repeat 95%`,
                  }}>
                  <option value={'none'}>{'선택 안됨'}</option>
                  <option value={'F'}>{'여성'}</option>
                  <option value={'M'}>{'남성'}</option>;
                </select>
              </div>
              <div className='input-container'>
                <label htmlFor='name-input' className='input-label'>
                  {'보유포인트'}
                </label>
                <input
                  type='text'
                  className='input-box disabled '
                  id='name-input'
                  disabled
                  value={userDetail.point}
                />
              </div>
              <div className='input-container'>
                <label htmlFor='name-input' className='input-label'>
                  {'포인트발행'}
                </label>
                <div>
                  <input
                    type='text'
                    className='input-box point'
                    id='name-input'
                    placeholder='발행포인트 입력'
                    value={tmpPoint || ''}
                    onChange={(e) => {
                      if (!checkIncludeNotNumber(e.target.value)) return;
                      onTmpPointChanged(Number(e.target.value));
                    }}
                  />
                  <button
                    type='button'
                    className='input-button'
                    onClick={onPointAddBtnClicked}>
                    {'발행하기'}
                  </button>
                </div>
              </div>
            </div>
            <div className='col'>
              <div className='input-container'>
                <label htmlFor='name-input' className='input-label'>
                  {'생성일자'}
                </label>
                <input
                  type='text'
                  className='input-box disabled'
                  id='name-input'
                  disabled
                  value={formatDate(userDetail.createDate)}
                  readOnly
                />
              </div>
              <div className='input-container'>
                <label htmlFor='name-input' className='input-label'>
                  {'탈퇴일자'}
                </label>
                <input
                  type='text'
                  className='input-box disabled'
                  id='name-input'
                  disabled
                  value={userDetail.deleteDate ?? '-'}
                  readOnly
                />
              </div>
            </div>
          </div>
        </div>
      </form>
      <div className='content-root payment-info'>
        <div className='title-container'>
          <div
            onClick={() => onTabCahnged('payment')}
            className={`title-item ${tab === 'payment' && 'selected'}`}>
            <div className='title-label'>결제내역</div>
            <div className='subtitle-label'>사용자의 결제 내역 입니다</div>
          </div>
          <div
            onClick={() => onTabCahnged('coupon')}
            className={`title-item ${tab === 'coupon' && 'selected'}`}>
            <div className='title-label'>쿠폰내역</div>
            <div className='subtitle-label'>사용자의 쿠폰 내역 입니다</div>
          </div>
        </div>
        {tab === 'payment' && (
          <UserPaymentListContainer userUid={item.userUid} />
        )}
        {tab === 'coupon' && <UserCouponListContainer userUid={item.userUid} />}
      </div>
    </div>
  );
};

export default UserDetail;
