export function formatDate(dateString: string, isTimeIncluded?: boolean) {
  const date = new Date(dateString);

  return `${date.getFullYear()}-${(date.getMonth() + 1)
    .toString()
    .padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}${
    isTimeIncluded
      ? ` ${date.getHours().toString().padStart(2, '0')}:${date
          .getMinutes()
          .toString()
          .padStart(2, '0')}`
      : ''
  }`;
}

export function dateToString(date: Date) {
  return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
}

export function birthToDate(birth: string): Date {
  const res = new Date();

  const year = parseInt(birth.slice(0, 4));
  const month = parseInt(birth.slice(4, 6)) - 1;
  const date = parseInt(birth.slice(6, 8));

  res.setFullYear(year);
  res.setMonth(month);
  res.setDate(date);

  return res;
}

export function stringToInputDate(date: string) {
  return `${date.slice(0, 4)}-${date.slice(4, 6)}-${date.slice(6, 8)}`;
}

export function settlementCalc(amount: number, rate: number) {
  return Math.round((amount * (100 - rate)) / 100);
}

export function getStartOfDate(date: string) {
  return new Date(date).getTime() - 9 * 60 * 60 * 1000;
}

export function getEndOfDate(date: string) {
  return new Date(date).getTime() + 15 * 60 * 60 * 1000 - 1;
}
