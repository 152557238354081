import useToken from '@hooks/store/useToken';
import { useTab } from '@hooks/useTab';
import { alertMessage } from '@libs/alertMessage';
import {
  apiRoute,
  BasicListDataType,
  requestGet,
  requestSecurePatch,
} from '@libs/api/api';

import { StoreTypes } from '@typedef/components/Partners/partners.types';
import {
  SaleItemQueryParamsTypes,
  StoreListItemTypes,
} from '@typedef/components/SaleItem/sale.item.types';
import React, { RefObject, useCallback } from 'react';
import { useEffect } from 'react';
import { useRef } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SaleItem from '../SaleItem';

const itemCnt = 10;

const SaleItemContainer = () => {
  const { getAccessToken } = useToken();
  const navigate = useNavigate();
  const { queryParams, updateQueryParamsFromSession, removeTabFromSession } =
    useTab<SaleItemQueryParamsTypes>('saleItem');

  const [page, setPage] = useState(queryParams.page || 0);
  const [maxPage, setMaxPage] = useState(1);
  const [searchInput, setSearchInput] = useState(queryParams.searchInput || '');
  const [storeList, setStoreList] = useState<StoreListItemTypes[]>([]);
  const [discount, setDiscount] = useState(0);
  const [isLoaded, setIsLoaded] = useState(false);

  const onSearchInputChanged = useCallback((value: string) => {
    setSearchInput(value);
  }, []);

  const loadMaxDiscount = useCallback(async () => {
    const {
      data,
      config: { status },
    } = await requestGet<number>(apiRoute.store.getDiscounts, {});

    if (status !== 200) {
      alertMessage('특가상품 최대 개수 조회에 실패했습니다.');
    }

    // setDiscount(data);
  }, []);

  const loadStoreList = useCallback(async () => {
    const {
      data,
      config: { status },
    } = await requestGet<BasicListDataType<StoreListItemTypes>>(
      apiRoute.store.getStoreList +
        `?page=${page}&perPage=${itemCnt}${
          searchInput ? `&q=${searchInput}` : ''
        }`,
      {},
    );

    if (status !== 200) {
      alertMessage('목록을 불러오지 못했습니다.');
      return;
    }

    setIsLoaded(true);
    setStoreList(data.rows);
    setMaxPage(data.maxPage);
  }, [searchInput, page]);

  const onSearchBtnClicked = useCallback(() => {
    page === 0 ? loadStoreList() : setPage(0);
  }, [page, loadStoreList]);

  const onSaveClicked = useCallback(async () => {
    const {
      data,
      config: { status },
    } = await requestSecurePatch<{}>(
      apiRoute.store.updateDiscounts,
      {},
      { count: discount },
      getAccessToken()!,
    );

    if (status !== 202) {
      alertMessage('특가 상품 수령 변경에 실패했습니다');
      return;
    }

    alertMessage('특가상품 수량 일괄 변경 성공');
    setPage(0);
    setSearchInput('');
    loadStoreList();
    setDiscount(0);
  }, [discount, getAccessToken, loadStoreList]);

  const onItemClicked = useCallback(
    (idx: number) => {
      navigate('detail', { state: { store: storeList[idx] } });
    },
    [storeList, navigate],
  );

  useEffect(() => {
    loadMaxDiscount();
  }, []);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (isLoaded) {
      timeout = setTimeout(() => {
        onSearchBtnClicked();
      }, 500);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [searchInput]);

  useEffect(() => {
    loadStoreList();
  }, [page, discount]);

  useEffect(() => {
    updateQueryParamsFromSession('', {
      page,
      searchInput,
    });
  }, [page, searchInput]);

  return (
    <SaleItem
      storeList={storeList}
      page={page}
      setPage={setPage}
      maxPage={maxPage}
      discount={discount}
      searchInput={searchInput}
      onSearchInputChanged={onSearchInputChanged}
      onSearchBtnClicked={onSearchBtnClicked}
      setDiscount={setDiscount}
      onSaveClicked={onSaveClicked}
      onItemClicked={onItemClicked}
    />
  );
};

export default SaleItemContainer;
