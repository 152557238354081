import useToken from '@hooks/store/useToken';
import { alertMessage } from '@libs/alertMessage';
import { apiRoute, requestSecureGet, requestSecurePost } from '@libs/api/api';
import { getEndOfDate, getStartOfDate } from '@libs/factory';
import {
  GetPointListResponseTypes,
  PointListItemTypes,
} from '@typedef/components/Point/point.types';
import React, { useCallback, useEffect, useState } from 'react';
import QrPointList from '../components/QrPointList';

type Props = {
  startDate: string;
  endDate: string;
  keyword: string;
};

const QrPoinstListContainer = ({ startDate, endDate, keyword }: Props) => {
  const { getAccessToken } = useToken();

  const [points, setPoints] = useState<PointListItemTypes[]>([]);
  const [maxPage, setMaxPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);

  const loadPoints = useCallback(async () => {
    const { data, config } = await requestSecureGet<GetPointListResponseTypes>(
      apiRoute.point.getPoints +
        `?page=${currentPage}&perPage=${13}${
          keyword?.trim().length !== 0 ? '&q=' + keyword : ''
        }${startDate ? '&startDate=' + getStartOfDate(startDate) : ''}${
          endDate ? '&endDate=' + getEndOfDate(endDate) : ''
        }${`&types=QR`}`,
      {},
      getAccessToken()!,
    );

    if (config.status === 200) {
      setMaxPage(data.maxPage);
      setPoints(data.rows);
    } else {
      alertMessage('목록을 불러오지 못했습니다');
    }
  }, [currentPage, keyword, startDate, endDate, getAccessToken]);

  const onPageChanged = useCallback((page: number) => {
    setCurrentPage(page);
  }, []);

  const onCheckBtnClicked = useCallback(
    async (uid: string, settlementAmount: number) => {
      if (
        !window.confirm(
          `${settlementAmount.toLocaleString()}원 정산 완료 처리 하시겠습니까?`,
        )
      ) {
        return;
      }

      const {
        data,
        config: { status },
      } = await requestSecurePost(
        apiRoute.qrPoint.check + uid,
        {},
        {},
        getAccessToken()!,
      );

      if (status !== 202) {
        alertMessage('QR 포인트 정산 요청이 실패했습니다.');
        return;
      }

      loadPoints();
    },
    [getAccessToken, loadPoints],
  );

  useEffect(() => {
    loadPoints();
  }, [loadPoints]);

  useEffect(() => {
    setCurrentPage(0);
  }, [keyword, startDate, endDate]);

  return (
    <QrPointList
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      points={points}
      maxPage={maxPage}
      onPageChanged={onPageChanged}
      onCheckBtnClicked={onCheckBtnClicked}
    />
  );
};

export default QrPoinstListContainer;
