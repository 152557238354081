import CPagingContainer from '@components/common/CPaging/containers/CPagingContainer';
import { formatDate } from '@libs/factory';
import { NoticeListItemTypes } from '@typedef/components/NoticeEvent/notice.event.types';
import React from 'react';

type Props = {
  page: number;
  onAddBtnClicked: () => void;
  noticeList: NoticeListItemTypes[];
  setPage: React.Dispatch<React.SetStateAction<number>>;
  maxPage: number;
  onListItemClicked: (item: NoticeListItemTypes) => void;
};

const NoticeList = ({
  page,
  onAddBtnClicked,
  noticeList,
  setPage,
  maxPage,
  onListItemClicked,
}: Props) => {
  return (
    <>
      <div className='button-container'>
        <button className='primary-btn' onClick={onAddBtnClicked}>
          작성하기
        </button>
      </div>
      <div className='table-container'>
        <div className='header-section'>
          <div className='item'>제목</div>
          <div className='item'>내용</div>
          <div className='item'>공지대상</div>
          <div className='item'>게시날짜</div>
        </div>
        <div className='body-section'>
          {noticeList.map((item, idx) => (
            <div
              key={idx}
              className='row'
              onClick={() => onListItemClicked(item)}>
              <div className='item'>{item.title}</div>
              <div className='item'>{item.contents}</div>
              <div className='item'>
                {item.target === 'CLIENT' ? '클라이언트' : '파트너스'}
              </div>
              <div className='item'>{formatDate(item.createDate)}</div>
            </div>
          ))}
        </div>
        <div className='footer-section'>
          <CPagingContainer maxPage={maxPage} page={page} setPage={setPage} />
        </div>
      </div>
    </>
  );
};

export default NoticeList;
