import image from '@components/common/assets/image';
import React from 'react';
import QrPoinstListContainer from './containers/QrPoinstListContainer';
import './styles/qr.point.styles.css';

type Props = {
  startDate: string;
  endDate: string;
  setStartDate: React.Dispatch<React.SetStateAction<string>>;
  setEndDate: React.Dispatch<React.SetStateAction<string>>;
  searchInput: string;
  setSearchInput: React.Dispatch<React.SetStateAction<string>>;
  keyword: string;
  SearchButtonClicked: () => void;
};

const QrPoint = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  searchInput,
  setSearchInput,
  keyword,
  SearchButtonClicked,
}: Props) => {
  return (
    <div className='qr-point-root content-root'>
      <div className='title-container'>
        <div className='title-item'>
          <div className='title-label'>QR 포인트 관리</div>
          <div className='subtitle-label'>QR 포인트 관리 페이지 입니다.</div>
        </div>
      </div>
      <div className='search-container'>
        <form>
          <div className='input-container'>
            <label htmlFor='input-search' className='input-label' hidden>
              {'검색'}
            </label>
            <input
              className='input-box search'
              type='text'
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
              id='input-search'
              placeholder='가게명, 회원이름으로 검색해주세요'
            />
            <button
              className='input-button'
              onClick={(e) => {
                e.preventDefault();
                SearchButtonClicked();
              }}>
              <img src={image.searchBtn} alt='search-btn' />
            </button>
          </div>
        </form>
        <div className='date-box'>
          <input
            className='date-input'
            type='date'
            onChange={(e) => setStartDate(e.target.value)}
          />
          <span>{'~'}</span>
          <input
            className='date-input'
            type='date'
            onChange={(e) => setEndDate(e.target.value)}
          />
        </div>
      </div>
      <QrPoinstListContainer
        startDate={startDate}
        endDate={endDate}
        keyword={keyword}
      />
    </div>
  );
};

export default QrPoint;
