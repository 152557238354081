import useToken from '@hooks/store/useToken';
import { alertMessage } from '@libs/alertMessage';
import { apiRoute, BasicListDataType, requestSecureGet } from '@libs/api/api';
import { PaymentListItemTypes } from '@typedef/components/Order/order.types';
import { PartnersPaymentsTypes } from '@typedef/components/Partners/partners.types';
import React, { useCallback, useEffect, useState } from 'react';
import PartnersDetailPayment from '../components/PartnersDetailPayment';

type Props = {
  partnersUid?: string;
};

const itemCnt = 10;

const PartnersDetailPaymentContainer = ({ partnersUid }: Props) => {
  const { getAccessToken } = useToken();

  const [paymentList, setPaymentList] = useState<PaymentListItemTypes[]>([]);

  const [maxPage, setMaxPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const loadPaymentList = useCallback(async () => {
    const {
      data,
      config: { status },
    } = await requestSecureGet<BasicListDataType<PaymentListItemTypes>>(
      apiRoute.payment.getPartnersPayments +
        partnersUid +
        `?page=${currentPage}&perPage=${itemCnt}`,
      {},
      getAccessToken()!,
    );

    if (status !== 200) {
      alertMessage('목록을 불러오지 못했습니다.');
      return;
    }

    setPaymentList(data.rows);
    setMaxPage(data.meta.maxPage);
  }, [currentPage]);

  const onPageChanged = useCallback((page: number) => {
    setCurrentPage(page);
  }, []);

  useEffect(() => {
    loadPaymentList();
  }, [loadPaymentList]);

  return (
    <PartnersDetailPayment
      paymentList={paymentList}
      onPageChanged={onPageChanged}
      maxPage={maxPage}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
    />
  );
};

export default PartnersDetailPaymentContainer;
