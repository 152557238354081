import { useTab } from '@hooks/useTab';
import { PartnersTabTypes } from '@typedef/components/Partners/partners.types';
import React, { useCallback, useState } from 'react';
import Partners from '../Partners';

const PartnersContainer = () => {
  const { tab: tabItem } = useTab<{}>('partners');
  const [tab, setTab] = useState<PartnersTabTypes>(
    (tabItem as PartnersTabTypes) || 'partners',
  );

  const onTabClicked = useCallback((tab: PartnersTabTypes) => {
    setTab(tab);
  }, []);

  return <Partners tab={tab} onTabClicked={onTabClicked} />;
};

export default PartnersContainer;
