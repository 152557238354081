import { formatDate } from '@libs/factory';
import {
  DeliveryFeeObjTypes,
  PaymentDetailItemTypes,
  PaymentListItemTypes,
} from '@typedef/components/Order/order.types';
import React from 'react';
import '../styles/order.detail.styles.css';

type Props = {
  paymentDetail: PaymentListItemTypes;
  paymentDetailObj: PaymentDetailItemTypes;
  deliveryFeeObj?: DeliveryFeeObjTypes;
};

const OrderDetail = ({
  paymentDetail,
  paymentDetailObj,
  deliveryFeeObj,
}: Props) => {
  return (
    <div className='content-root order-detail'>
      <div className='title-container'>
        <div className='title-item'>
          <div className='title-label'>주문내역 상세</div>
          <div className='subtitle-label'>주문내역 상세 페이지입니다.</div>
        </div>
      </div>
      <div className={`body-section detail`}>
        <div className='list-item'>
          <span className='label'>구매자</span>
          <span className='item'>{paymentDetail.buyerName}</span>
        </div>
        <div className='list-item'>
          <span className='label'>전화번호</span>
          <span className='item'>{paymentDetail.phone}</span>
        </div>
        <div className='list-item'>
          <span className='label'>이메일</span>
          <span className='item'>{paymentDetail.buyerEmail}</span>
        </div>
        <div className='list-item'>
          <span className='label'>주문번호</span>
          <span className='item'>{paymentDetail.merchantUid}</span>
        </div>
        <div className='list-item'>
          <span className='label'>결제일시</span>
          <span className='item'>{formatDate(paymentDetail.createDate)}</span>
        </div>
        <div className='list-item'>
          <span className='label'>전체 포인트</span>
          <span className='item'>
            {paymentDetail.orders
              .reduce((prev, current) => {
                return (prev += current.pointDiscount);
              }, 0)
              .toLocaleString()}
          </span>
        </div>
        <div className='list-item'>
          <span className='label'>전체쿠폰</span>
          <span className='item'>
            {paymentDetail.orders
              .reduce((prev, current) => {
                return (prev += current.couponDiscount);
              }, 0)
              .toLocaleString()}
          </span>
        </div>
        <div className='list-item'>
          <span className='label'>총 상품금액</span>
          <span className='item'>
            {`${(
              paymentDetail.totalAmount +
              paymentDetail.discountedAmountByCoupon +
              paymentDetail.discountedAmountByPoint
            ).toLocaleString()} 원`}
          </span>
        </div>
      </div>
      <div className='table-container'>
        <div className='header-section'>
          <div className='item'>가게 이름</div>
          <div className='item'>상품명</div>
          <div className='item'>수량</div>
          <div className='item'>상품금액</div>
          <div className='item'>사용 쿠폰</div>
          <div className='item'>사용 포인트</div>
          <div className='item'>처리상태</div>
        </div>
        <div className='body-section list'>
          {Object.values(paymentDetailObj).map((storeItem, idx) => {
            return storeItem.map((item, itemIdx) => {
              return (
                <>
                  <div key={itemIdx} className='row'>
                    <div className='item'>
                      {itemIdx === 0 ? item.store.name : ''}
                    </div>
                    <div className='item'>{item.product.name}</div>
                    <div className='item'>{item.count}</div>
                    <div className='item'>
                      {`${(
                        (item.amount +
                          item.pointDiscount +
                          item.couponDiscount) /
                        item.count
                      ).toLocaleString()} 원`}
                    </div>
                    <div className='item'>
                      {item.couponDiscount.toLocaleString()}
                    </div>
                    <div className='item'>
                      {item.pointDiscount.toLocaleString()}
                    </div>
                    <div className='item'>{item.orderStatus}</div>
                  </div>
                  {storeItem.length - 1 === itemIdx && (
                    <div className='row account' key={idx}>
                      <div className='item'>{`배송비 : ${
                        deliveryFeeObj
                          ? deliveryFeeObj[
                              item.store.storeUid
                            ]?.amount?.toLocaleString() || 0
                          : '0'
                      }`}</div>
                    </div>
                  )}
                </>
              );
            });
          })}
        </div>
      </div>
    </div>
  );
};

export default OrderDetail;
