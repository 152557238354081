import useToken from '@hooks/store/useToken';
import { alertMessage } from '@libs/alertMessage';
import { apiRoute, requestSecureGet, requestSecurePatch } from '@libs/api/api';
import {
  GetMainBannerListResponseTypes,
  MainBannerListItemTypes,
} from '@typedef/components/MainBanner/main.banner.types';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MainBanner from '../MainBanner';

const MainBannerContainer = () => {
  const navigate = useNavigate();

  const { getAccessToken } = useToken();

  const [mainBannerList, setMainBannerList] = useState<
    MainBannerListItemTypes[]
  >([]);

  const loadBannerList = useCallback(async () => {
    const { data, config } =
      await requestSecureGet<GetMainBannerListResponseTypes>(
        apiRoute.banners.getBanners,
        {},
        getAccessToken()!,
      );

    if (config.status === 200) {
      setMainBannerList(data.rows);
    } else {
      alertMessage('목록을 불러오지 못했습니다');
    }
  }, []);

  const onBannerAddClicked = useCallback(() => {
    navigate('add');
  }, [navigate]);

  const onBannerUpClicked = useCallback((idx: number) => {
    setMainBannerList((prev) => {
      const clone = [...prev];
      const before = clone[idx - 1];
      clone[idx - 1] = clone[idx];
      clone[idx] = before;
      return clone;
    });
  }, []);

  const onBannerDownClicked = useCallback(
    (idx: number) => {
      setMainBannerList((prev) => {
        const clone = [...prev];
        const after = clone[idx + 1];
        clone[idx + 1] = clone[idx];
        clone[idx] = after;
        return clone;
      });
    },
    [mainBannerList],
  );

  const onPrioritySaveClicked = useCallback(() => {
    Promise.all(
      mainBannerList.map((item, idx) => {
        return new Promise((resolve, reject) => {
          const form = new FormData();

          form.append('priority', String(idx + 1));

          requestSecurePatch(
            apiRoute.banners.updateBanner + item.bannerUid,
            { 'Content-Type': 'multipart/form-data' },
            form,
            getAccessToken()!,
          ).then((data) => {
            if (data.config.status === 202) {
              resolve(data);
            } else {
              reject(`Http Request Failed With ${data.config.status}`);
            }
          });
        });
      }),
    )
      .then(() => {
        alertMessage('순위가 변경되었습니다');
      })
      .catch(() => {
        alertMessage('순위 변경에 실패했습니다');
      });
  }, [mainBannerList]);

  const onBannerItemClicked = useCallback(
    (item: MainBannerListItemTypes) => {
      navigate('detail', {
        state: {
          data: item,
        },
      });
    },
    [navigate],
  );

  useEffect(() => {
    loadBannerList();
  }, []);

  return (
    <MainBanner
      mainBannerList={mainBannerList}
      onBannerAddClicked={onBannerAddClicked}
      onPrioritySaveClicked={onPrioritySaveClicked}
      onBannerItemClicked={onBannerItemClicked}
      onBannerUpClicked={onBannerUpClicked}
      onBannerDownClicked={onBannerDownClicked}
    />
  );
};

export default MainBannerContainer;
