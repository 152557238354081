import CFileInputContainer from '@components/common/CFileInput/containers/CFileInputContainer';
import CMultiFileInputContainer from '@components/common/CFileInput/containers/CMultiFileInputContainer';
import CBankSelectContainer, {
  BanksObj,
} from '@components/common/CBankSelect/containers/CBankSelectContainer';
import CInputContainer from '@components/common/CInput/containers/CInputContainer';
import CMultiSelectContainer from '@components/common/CSelect/containers/CMultiSelectContainer';
import CSelectContainer from '@components/common/CSelect/containers/CSelectContainer';
import CTimeSelectContainer from '@components/common/CTimeSelect/containers/CTimeSelectContainer';
import Item from '@components/Item/Item';
import { formatDate } from '@libs/factory';
import { checkIncludeNotNumber } from '@libs/validation';
import { BankTypes } from '@typedef/components/common/bank.types';
import {
  PartnersCategoryListItemTypes,
  PartnersDetailFormTypes,
  PartnersDetailTypes,
  PartnersListItemTypes,
  StoreRestsTypes,
} from '@typedef/components/Partners/partners.types';
import React from 'react';
import PartnersDetailCouponContainer from '../containers/PartnersDetailCouponContainer';
import PartnersDetailPaymentContainer from '../containers/PartnersDetailPaymentContainer';

import '../styles/partners.detail.styles.css';

type Props = {
  type: 'detail' | 'signUp' | 'update';
  isEditable: boolean;
  partners?: PartnersDetailTypes;
  partnersDetailForm: PartnersDetailFormTypes;
  images: (File | null)[];
  storeRests: StoreRestsTypes[];
  isRestHoliday: number;
  location: string;
  shopCategory: PartnersCategoryListItemTypes[];
  onPartnersDetailFormChanged: (
    key: keyof PartnersDetailFormTypes,
    value: string | number,
  ) => void;
  setImages: React.Dispatch<React.SetStateAction<(File | null)[]>>;
  setTargetTagUid: React.Dispatch<
    React.SetStateAction<{ targetTagUid: number; name: string } | null>
  >;
  setPartnersProfile: React.Dispatch<React.SetStateAction<string>>;
  setIsRestHoliday: React.Dispatch<React.SetStateAction<number>>;
  setLocation: React.Dispatch<React.SetStateAction<string>>;
  setStoreProfile: React.Dispatch<React.SetStateAction<string[]>>;
  setBusinessRegistrationFile: React.Dispatch<
    React.SetStateAction<File | undefined>
  >;
  setBusinessReportFile: React.Dispatch<React.SetStateAction<File | undefined>>;
  onStoreRestClicked: (week: number, day: number) => void;
  onSaveButtonClicked: () => void;
  onDeleteButtonClicked: () => void;
  onBackButtonClicked: () => void;
  onAddressInputClicked: () => void;
};

const PartnersDetail = ({
  type,
  isEditable,
  partners,
  partnersDetailForm,
  images,
  isRestHoliday,
  location,
  storeRests,
  shopCategory,
  onPartnersDetailFormChanged,
  setImages,
  setTargetTagUid,
  setIsRestHoliday,
  setLocation,
  setPartnersProfile,
  setStoreProfile,
  setBusinessRegistrationFile,
  setBusinessReportFile,
  onStoreRestClicked,
  onSaveButtonClicked,
  onBackButtonClicked,
  onDeleteButtonClicked,
  onAddressInputClicked,
}: Props) => {
  return (
    <div className='content-root partners-detail'>
      <div className='title-container'>
        <div className='title-item'>
          <div className='title-label'>{partners?.name}</div>
          <div className='subtitle-label'>{partners?.email}</div>
        </div>
      </div>
      <div className='button-container '>
        <button
          className='delete-btn'
          type='button'
          onClick={onDeleteButtonClicked}>
          삭제하기
        </button>
        <button
          type='submit'
          className='primary-btn'
          form='partners-detail-form'>
          저장하기
        </button>
      </div>
      <div className={`content-root `}>
        <form
          id='partners-detail-form'
          onSubmit={(e) => {
            e.preventDefault();
            onSaveButtonClicked();
          }}>
          <div className='table-container basic'>
            <span className='table-title'>기본정보</span>

            <div className={`body-section ${!isEditable ? 'view' : ''}`}>
              <CMultiFileInputContainer
                onSelectdFilesChanged={setImages}
                initialValue={images as File[]}
              />
              <div className='row'>
                <div className='item'>
                  <CInputContainer
                    containerStyle={{
                      width: 'calc((100vw - 400px) / 3)',
                    }}
                    containerType={{
                      type: 'outline',
                      adaptive: false,
                    }}
                    type='text'
                    initialValue={partners?.name}
                    onInputChanged={(item) =>
                      onPartnersDetailFormChanged('name', item)
                    }
                    inputId='user-detail-name-input'
                    label='이름'
                    isRequired={true}
                  />
                </div>
              </div>
              <div className='row'>
                <div className='item'>
                  <CInputContainer
                    containerStyle={{
                      width: 'calc((100vw - 400px) / 3)',
                    }}
                    containerType={{
                      type: 'outline',
                      adaptive: false,
                    }}
                    type='text'
                    initialValue={partners?.phone}
                    onInputChanged={(item) =>
                      onPartnersDetailFormChanged('phone', item)
                    }
                    inputId='user-detail-phone-input'
                    label='전화번호'
                    isRequired={true}
                  />
                </div>
              </div>
              <div className='row'>
                <div className='item'>
                  <CInputContainer
                    containerStyle={{
                      width: 'calc((100vw - 400px) / 3)',
                    }}
                    containerType={{
                      type: 'outline',
                      adaptive: false,
                    }}
                    type='text'
                    initialValue={partners?.partnersName}
                    onInputChanged={(item) =>
                      onPartnersDetailFormChanged('partnersName', item)
                    }
                    inputId='user-detail-partners-name-input'
                    label='아이디'
                    isRequired={true}
                  />
                </div>
              </div>
              <div className='row'>
                <div className='item'>
                  <div className='shop-category-label'>{'은행명'}</div>
                  <div
                    className='time-container'
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '10px',
                    }}>
                    <CBankSelectContainer
                      selectedBank={partnersDetailForm.accountBank}
                      onBankSelected={(accountBank: keyof BankTypes) =>
                        onPartnersDetailFormChanged('accountBank', accountBank)
                      }
                    />
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='item'>
                  <CInputContainer
                    containerStyle={{
                      width: 'calc((100vw - 400px) / 3)',
                    }}
                    containerType={{
                      type: 'outline',
                      adaptive: false,
                    }}
                    type='text'
                    initialValue={partners?.accountName}
                    testValue={(item) => {
                      return !/[0-9]/.test(item);
                    }}
                    onInputChanged={(item) => {
                      onPartnersDetailFormChanged('accountName', item);
                    }}
                    inputId='user-detail-account-name-input'
                    label='예금주명'
                    isRequired={true}
                  />
                </div>
              </div>
              <div className='row'>
                <div className='item'>
                  <CInputContainer
                    containerStyle={{
                      width: 'calc((100vw - 400px) / 3)',
                    }}
                    containerType={{
                      type: 'outline',
                      adaptive: false,
                    }}
                    type='text'
                    initialValue={partners?.accountNumber}
                    onInputChanged={(item) =>
                      onPartnersDetailFormChanged('accountNumber', item)
                    }
                    inputId='user-detail-account-number-input'
                    label='계좌번호'
                    isRequired={true}
                  />
                </div>
              </div>

              <div className='row'>
                <div className='item'>
                  <CInputContainer
                    containerStyle={{
                      width: 'calc((100vw - 400px) / 3)',
                    }}
                    containerType={{
                      type: 'outline',
                      adaptive: false,
                    }}
                    type='text'
                    initialValue={partners?.email}
                    onInputChanged={(item) =>
                      onPartnersDetailFormChanged('email', item)
                    }
                    inputId='user-detail-email-input'
                    label='이메일'
                    isRequired={true}
                  />
                </div>
              </div>
              <div className='row'>
                <div className='item'>
                  <CInputContainer
                    containerStyle={{
                      width: 'calc((100vw - 400px) / 3)',
                    }}
                    containerType={{
                      type: 'outline',
                      adaptive: false,
                    }}
                    type='text'
                    disabled
                    initialValue={
                      partners?.createDate
                        ? formatDate(partners.createDate)
                        : '-'
                    }
                    onInputChanged={() => {}}
                    inputId='user-detail-create-input'
                    label='가입일'
                  />
                </div>
              </div>
              <div className='row'>
                <div className='item'>
                  <CInputContainer
                    containerStyle={{
                      width: 'calc((100vw - 400px) / 3)',
                    }}
                    containerType={{
                      type: 'outline',
                      adaptive: false,
                    }}
                    type='text'
                    disabled
                    initialValue={
                      partners?.deleteDate
                        ? formatDate(partners.deleteDate)
                        : '-'
                    }
                    onInputChanged={() => {}}
                    inputId='user-detail-delete-input'
                    label='삭제일'
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='table-container store'>
            <span className='table-title'>가게정보</span>
            <div className={`store-file-section ${!isEditable ? 'view' : ''}`}>
              <div className='business-file-section'>
                <CFileInputContainer
                  onFileChanged={setBusinessRegistrationFile}
                  label='사업자등록증'
                />
                <a
                  className='file-download-btn'
                  href={partners?.store.businessRegistrationFile[0]}>
                  {'사업자등록증 다운로드'}
                </a>
                <CFileInputContainer
                  onFileChanged={setBusinessReportFile}
                  label='통신판매업 신고증'
                />
                <a
                  className='file-download-btn'
                  href={partners?.store.businessReportFile[0]}>
                  {'통신판매업 신고증 다운로드'}
                </a>
                <div className='item'>
                  <CInputContainer
                    containerStyle={{
                      width: '390px',
                    }}
                    containerType={{
                      type: 'outline',
                      adaptive: false,
                    }}
                    type='text'
                    initialValue={partners?.store.telSalesBusinessReportNumber}
                    onInputChanged={(item) =>
                      onPartnersDetailFormChanged(
                        'telSalesBusinessReportNumber',
                        item,
                      )
                    }
                    inputId='user-detail-owner-name-input'
                    label='통신판매업 번호'
                    isRequired={true}
                  />
                </div>
              </div>
              <div className='rest-day-section'>
                <span>휴점일</span>
                {[
                  '첫째주',
                  '둘째주',
                  '셋째주',
                  '넷째주',
                  '다섯째주',
                  '여섯째주',
                ].map((week, weekIdx) => {
                  return (
                    <div className='week-container' key={weekIdx}>
                      <div className={`week item`}>{week}</div>
                      {['월', '화', '수', '목', '금', '토', '일'].map(
                        (day, dayIdx) => {
                          return (
                            <div
                              className={`day item  ${
                                storeRests.some(
                                  (item) =>
                                    item.week === weekIdx + 1 &&
                                    item.day === dayIdx + 1,
                                ) && 'selected'
                              }`}
                              key={dayIdx}
                              onClick={() =>
                                onStoreRestClicked(weekIdx + 1, dayIdx + 1)
                              }>
                              {day}
                            </div>
                          );
                        },
                      )}
                    </div>
                  );
                })}
                <div
                  className={`holidays item ${
                    Boolean(isRestHoliday) && 'selected'
                  }`}
                  onClick={() => {
                    setIsRestHoliday((prev) => {
                      return prev === 1 ? 0 : 1;
                    });
                  }}>
                  공휴일
                </div>
              </div>
            </div>
            <div className={`body-section ${!isEditable ? 'view' : ''}`}>
              <div className='row'>
                <div className='item'>
                  <CInputContainer
                    containerStyle={{
                      width: 'calc((100vw - 400px) / 3)',
                    }}
                    containerType={{
                      type: 'outline',
                      adaptive: false,
                    }}
                    type='text'
                    initialValue={partners?.store.ownerName}
                    onInputChanged={(item) =>
                      onPartnersDetailFormChanged('ownerName', item)
                    }
                    inputId='user-detail-owner-name-input'
                    label='대표자명'
                    isRequired={true}
                  />
                </div>
              </div>
              <div className='row'>
                <div className='item'>
                  <CInputContainer
                    containerStyle={{
                      width: 'calc((100vw - 400px) / 3)',
                    }}
                    containerType={{
                      type: 'outline',
                      adaptive: false,
                    }}
                    type='text'
                    initialValue={partners?.store.businessNumber}
                    onInputChanged={(item) =>
                      onPartnersDetailFormChanged('businessNumber', item)
                    }
                    inputId='user-detail-business-number-input'
                    label='사업자 번호'
                    isRequired={true}
                  />
                </div>
              </div>
              <div className='row'>
                <div className='item'>
                  <div className='partners-location-root'>
                    <div className='label'>사업장 주소</div>
                    <input
                      className='location-input'
                      type='text'
                      value={location}
                      onChange={(e) => setLocation(e.target.value)}
                      onClick={onAddressInputClicked}
                      required
                    />
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='item'>
                  <CInputContainer
                    containerStyle={{
                      width: 'calc((100vw - 400px) / 3)',
                    }}
                    containerType={{
                      type: 'outline',
                      adaptive: false,
                    }}
                    type='text'
                    initialValue={partners?.store.locationDetail}
                    onInputChanged={(item) =>
                      onPartnersDetailFormChanged('locationDetail', item)
                    }
                    inputId='user-detail-store-location-input'
                    label='사업장 상세주소'
                    isRequired={true}
                  />
                </div>
              </div>
              <div className='row'>
                <div className='item'>
                  <CInputContainer
                    containerStyle={{
                      width: 'calc((100vw - 400px) / 3)',
                    }}
                    containerType={{
                      type: 'outline',
                      adaptive: false,
                    }}
                    type='text'
                    initialValue={partners?.store.name}
                    onInputChanged={(item) =>
                      onPartnersDetailFormChanged('storeName', item)
                    }
                    inputId='user-detail-store-name-input'
                    label='가게명'
                    isRequired={true}
                  />
                </div>
              </div>
              <div className='row'>
                <div className='item'>
                  <CInputContainer
                    containerStyle={{
                      width: 'calc((100vw - 400px) / 3)',
                    }}
                    containerType={{
                      type: 'outline',
                      adaptive: false,
                    }}
                    type='text'
                    initialValue={partners?.store.phone}
                    onInputChanged={(item) =>
                      onPartnersDetailFormChanged('storePhone', item)
                    }
                    inputId='user-detail-store-phone-input'
                    label='가게 전화번호'
                    isRequired={true}
                  />
                </div>
              </div>
              <div className='row'>
                <div className='item'>
                  <CInputContainer
                    containerStyle={{
                      width: 'calc((100vw - 400px) / 3)',
                    }}
                    containerType={{
                      type: 'outline',
                      adaptive: false,
                    }}
                    type='text'
                    initialValue={partners?.store.description}
                    onInputChanged={(item) =>
                      onPartnersDetailFormChanged('description', item)
                    }
                    inputId='user-detail-store-description-input'
                    label='가게 설명'
                    isRequired={true}
                  />
                </div>
              </div>
              <div className='row'>
                <div className='item'>
                  <div className='shop-category-label'>샵종류</div>
                  <CSelectContainer
                    options={shopCategory}
                    labelSelector={(item) => item.name}
                    onSelected={setTargetTagUid}
                    placeholder={
                      shopCategory.filter(
                        (item) =>
                          item.targetTagUid ===
                          partners?.store.targetTag.targetTagUid,
                      )[0].name
                    }
                  />
                </div>
              </div>

              <div className='row'>
                <div className='item'>
                  <div className='shop-category-label'>{'영업시간'}</div>
                  <div
                    className='time-container'
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '10px',
                    }}>
                    <CTimeSelectContainer
                      selectedHour={partnersDetailForm.startHours}
                      selectedMinutes={partnersDetailForm.startMinutes}
                      onHourSelected={(item) =>
                        onPartnersDetailFormChanged('startHours', item)
                      }
                      onMinutesSelected={(item) =>
                        onPartnersDetailFormChanged('startMinutes', item)
                      }
                    />
                    <span>{' ~ '}</span>
                    <CTimeSelectContainer
                      selectedHour={partnersDetailForm.endHours}
                      selectedMinutes={partnersDetailForm.endMinutes}
                      onHourSelected={(item) =>
                        onPartnersDetailFormChanged('endHours', item)
                      }
                      onMinutesSelected={(item) =>
                        onPartnersDetailFormChanged('endMinutes', item)
                      }
                    />
                  </div>
                </div>
              </div>

              <div className='row'>
                <div className='item'>
                  <CInputContainer
                    containerStyle={{
                      width: 'calc((100vw - 400px) / 3)',
                    }}
                    containerType={{
                      type: 'outline',
                      adaptive: false,
                    }}
                    type='text'
                    testValue={checkIncludeNotNumber}
                    initialValue={String(partners?.store?.freeDelivery)}
                    onInputChanged={(item) =>
                      onPartnersDetailFormChanged('freeDelivery', item)
                    }
                    inputId='user-detail-store-close-time-input'
                    label='무료 배송(원 이상)'
                    isRequired={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
        <PartnersDetailCouponContainer
          storeUid={partners?.store.storeUid}
          isEditable={isEditable}
        />
        <PartnersDetailPaymentContainer partnersUid={partners?.partnersUid} />
      </div>
    </div>
  );
};

export default PartnersDetail;
