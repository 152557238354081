import usePopup from '@hooks/store/usePopup';
import useToken from '@hooks/store/useToken';
import { alertMessage } from '@libs/alertMessage';
import {
  apiRoute,
  BasicApiResponse,
  BasicListDataType,
  requestGet,
  requestMultipartPatch,
  requestSecureGet,
  requestSecurePost,
} from '@libs/api/api';
import { uriToFile } from '@libs/trans';
import {
  ExistingStoreProfileTypes,
  KakaoAddressType,
  PartnersCategoryListItemTypes,
  PartnersDetailTypes,
  PartnersListItemTypes,
  PartnersPaymentsTypes,
  PartnersReqCategoryTypes,
  StoreRestsTypes,
} from '@typedef/components/Partners/partners.types';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useCallback } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import PartnersDetail from '../components/PartnersDetail';
import SignupRequestDetail from '../components/SignupRequestDetail';
import StoreImagePopup from '../components/StoreImagePopup';

const SignupRequestDetailContainer = () => {
  const navigate = useNavigate();
  const { getAccessToken } = useToken();
  const { partnersUid } = useLocation().state as {
    partnersUid: string;
  };
  const { __showPopupFromHooks } = usePopup();

  const [partners, setPartners] = useState<PartnersDetailTypes>();

  const loadPartnersData = useCallback(async () => {
    const {
      data,
      config: { status },
    } = await requestSecureGet<PartnersDetailTypes>(
      apiRoute.partners.getPartners + `/${partnersUid}`,
      {},
      getAccessToken()!,
    );

    if (status !== 200) {
      alertMessage('정보를 불러오지 못했습니다.');
    }

    setPartners(data);
  }, [getAccessToken, partnersUid]);

  const onBackButtonClicked = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const onSignUpButtonClicked = useCallback(
    async (approve: number) => {
      const {
        data,
        config: { status },
      } = await requestSecurePost<BasicApiResponse<{}>>(
        apiRoute.partners.updatePartner + `${partnersUid}/signup/permission`,
        {},
        {
          approve,
          ...(approve === -1 && {
            rejectMessage: '가입조건에 부합하지 않습니다.',
          }),
        },
        getAccessToken()!,
      );

      if (status !== 202) {
        alertMessage(`${approve === 1 ? '승인' : '거절'} 요청이 실패했습니다.`);
        return;
      }

      alertMessage(`가입요청이 ${approve === 1 ? '승인' : '거절'}되었습니다.`);
      navigate(-1);
    },
    [navigate, partnersUid, getAccessToken],
  );

  const onImageClicked = useCallback(
    (src: string) => {
      __showPopupFromHooks(<StoreImagePopup imageSrc={src} />);
    },
    [__showPopupFromHooks],
  );

  useEffect(() => {
    loadPartnersData();
  }, []);

  return partners ? (
    <SignupRequestDetail
      partners={partners}
      onBackButtonClicked={onBackButtonClicked}
      onSignUpButtonClicked={onSignUpButtonClicked}
      onImageClicked={onImageClicked}
    />
  ) : (
    <div className='content-root'></div>
  );
};

export default SignupRequestDetailContainer;
