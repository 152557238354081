import React, { useCallback, useEffect, useState } from 'react';
import CCheckbox from '../CCheckbox';

type Props = {
  onCheckChanged: (checked: boolean) => void;
  checkType: 'square' | 'round';
  colorType: 'fill' | 'border';
  label?: string;
  disabled?: boolean;
  initialState?: boolean;
};

const CCheckboxContainer = ({
  onCheckChanged,
  checkType,
  colorType,
  label = '',
  disabled = false,
  initialState = false,
}: Props) => {
  const [checked, setChecked] = useState(initialState);

  const onCheckClicked = useCallback(() => {
    setChecked((prev) => !prev);
  }, []);

  useEffect(() => {
    onCheckChanged(checked);
  }, [checked]);

  return (
    <CCheckbox
      checked={checked}
      onCheckClicked={onCheckClicked}
      checkType={checkType}
      colorType={colorType}
      label={label}
      disabled={disabled}
    />
  );
};

export default CCheckboxContainer;
