import CMultiFileInputContainer from '@components/common/CFileInput/containers/CMultiFileInputContainer';
import CInputContainer from '@components/common/CInput/containers/CInputContainer';
import CSelectContainer from '@components/common/CSelect/containers/CSelectContainer';
import {
  FaqCategoryListItemTypes,
  FaqDetailTypes,
} from '@typedef/components/FAQ/faq.types';
import React from 'react';
import '../styles/faq.detail.styles.css';

type Props = {
  faq: FaqDetailTypes;
  images: (File | null)[];
  categoryOption: FaqCategoryListItemTypes[];
  content: string;
  onUpdateBtnClicked: () => void;
  onDeleteBtnClicked: () => void;
  onBackBtnClicked: () => void;
  setCategory: React.Dispatch<
    React.SetStateAction<FaqCategoryListItemTypes | null>
  >;
  setImages: React.Dispatch<React.SetStateAction<(File | null)[]>>;
  setTitle: React.Dispatch<React.SetStateAction<string>>;
  setContent: React.Dispatch<React.SetStateAction<string>>;
};

const FAQDetail = ({
  faq,
  images,
  categoryOption,
  content,
  onUpdateBtnClicked,
  onDeleteBtnClicked,
  onBackBtnClicked,
  setCategory,
  setImages,
  setTitle,
  setContent,
}: Props) => {
  return (
    <div className='faq-root content-root'>
      <div className='title-container'>
        <div className='title-item'>
          <div className='title-label'>{'FAQ 상세'}</div>
          <div className='subtitle-label'>{'FAQ 상세 페이지 입니다'}</div>
        </div>
      </div>
      <div className='button-container'>
        <button className='primary-btn' onClick={onBackBtnClicked}>
          뒤로가기
        </button>
        <button className='primary-btn' onClick={onDeleteBtnClicked}>
          삭제하기
        </button>
        <button className='primary-btn' onClick={onUpdateBtnClicked}>
          저장하기
        </button>
      </div>
      <div className='table-container'>
        <div className='body-section'>
          <div className='row'>
            <div className='item'>
              <CInputContainer
                initialValue={faq.title}
                containerStyle={{
                  width: '100%',
                }}
                containerType={{
                  type: 'outline',
                  adaptive: true,
                }}
                type='text'
                inputId='faq-add-title-input'
                onInputChanged={setTitle}
                label='제목'
                placeholder='제목을 입력해주세요'
              />
              {/* <label htmlFor='title-input'>제목</label>
              <input
                id='title-input'
                className='title-input'
                type='text'
                placeholder='제목을 입력해주세요'
                value={title}
                onChange={onChangeTitle}
              /> */}
            </div>
            <div>
              <CSelectContainer
                options={categoryOption}
                onSelected={setCategory}
                placeholder={faq.faqCategory.name}
                labelSelector={(item) => item.name}
              />
            </div>
          </div>
          <div>
            {images && (
              <CMultiFileInputContainer
                onSelectdFilesChanged={setImages}
                initialValue={images as File[]}
              />
            )}
          </div>
          <div className='row content'>
            <textarea
              name='content'
              id='event-detail-content'
              cols={30}
              rows={10}
              value={content}
              onChange={(e) => setContent(e.target.value)}
              placeholder='내용을 입력해주세요'
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQDetail;
