import usePopup from '@hooks/store/usePopup';
import useToken from '@hooks/store/useToken';
import { alertMessage } from '@libs/alertMessage';
import {
  apiRoute,
  BasicListDataType,
  requestGet,
  requestSecurePatch,
} from '@libs/api/api';
import {
  CodyProductsTypes,
  ProductTypes,
} from '@typedef/components/Item/item.types';
import React, { useCallback, useEffect, useState } from 'react';
import CodyPopup from '../components/CodyPopup';

type Props = {
  storeUid: number;
  productUid: number;
  codyList: CodyProductsTypes[];
  setCodyList: React.Dispatch<React.SetStateAction<CodyProductsTypes[]>>;
};

const itemCnt = 5;

const CodyPopupContainer = ({
  storeUid,
  productUid,
  codyList,
  setCodyList,
}: Props) => {
  const { getAccessToken } = useToken();
  const { __hidePopupFromHooks } = usePopup();
  const [productList, setProductList] = useState<ProductTypes[]>([]);
  const [page, setPage] = useState(0);
  const [maxPage, setMaxPage] = useState(0);
  const [searchInput, setSearchInput] = useState('');

  const loadProductList = useCallback(async () => {
    const q = searchInput !== '' ? `&q=${searchInput}` : '';

    const {
      data,
      config: { status },
    } = await requestGet<BasicListDataType<ProductTypes>>(
      apiRoute.product.getProducts +
        `?page=${page}&perPage=${itemCnt}&isDiscountedProduct=0&isRecommendedProduct=0&storeUid=${storeUid}` +
        q,
      {},
    );

    if (status !== 200) {
      alertMessage('상품 목록을 불러오지 못했습니다.');
      return;
    }

    setProductList(data.rows);
    setMaxPage(data.maxPage);
  }, [page, storeUid, searchInput]);

  const onSearchClicked = useCallback(
    (keyword: string) => {
      setPage(1);
      setSearchInput(keyword);
      loadProductList();
    },
    [loadProductList],
  );

  const onSelectCodyItemClicked = useCallback(
    async (idx: number) => {
      const uidList = codyList.map((item, idx) => item.productUid);
      uidList.push(productList[idx].productUid);

      const {
        data,
        config: { status },
      } = await requestSecurePatch<{}>(
        apiRoute.product.updateProductDetail + `${productUid}/detail`,
        {},
        { codyProductsUid: uidList },
        getAccessToken()!,
      );

      if (status !== 202) {
        alertMessage('코디 아이템 추가에 실패했습니다.');
        return;
      }

      setCodyList((prev) => {
        const clone = [...prev];
        clone.push({
          productUid: productList[idx].productUid,
          name: productList[idx].name,
          price: productList[idx].price,
          discountedPrice: productList[idx].discountedPrice,
          productFile: productList[idx].productFile ?? '',
        });

        return clone;
      });

      __hidePopupFromHooks();
    },
    [productList, productUid, getAccessToken],
  );

  useEffect(() => {
    loadProductList();
  }, [loadProductList]);

  return (
    <CodyPopup
      productList={productList}
      maxPage={maxPage}
      page={page}
      setPage={setPage}
      onSearchClicked={onSearchClicked}
      onSelectCodyItemClicked={onSelectCodyItemClicked}
    />
  );
};

export default CodyPopupContainer;
