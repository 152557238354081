import image from '@components/common/assets/image';
import CDateInputContainer from '@components/common/CInput/containers/CDateInputContainer';
import CInputContainer from '@components/common/CInput/containers/CInputContainer';

import CPagingContainer from '@components/common/CPaging/containers/CPagingContainer';
import CSearchContainer from '@components/common/CSearch/containers/CSearchContainer';
import CSelectContainer from '@components/common/CSelect/containers/CSelectContainer';
import {
  UserGenderOptionTypes,
  UserGenderTypes,
  UserListItemTypes,
} from '@typedef/components/User/user.types';
import React from 'react';
import { MutableRefObject } from 'react';
import { RefObject } from 'react';
import './styles/user.styles.css';

type Props = {
  userList: UserListItemTypes[];
  maxPage: number;
  currentPage: number;
  searchInput: string;
  createDate: string;
  searchGender: UserGenderTypes;
  genderOptions: UserGenderOptionTypes;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  onListItemClicked: (item: UserListItemTypes) => void;
  setSearchInput: React.Dispatch<React.SetStateAction<string>>;
  setCreateDate: React.Dispatch<React.SetStateAction<string>>;
  setSearchGender: React.Dispatch<React.SetStateAction<UserGenderTypes>>;
  onSearchBtnClicked: () => void;
};

const User = ({
  userList,
  maxPage,
  currentPage,
  createDate,
  searchGender,
  searchInput,
  genderOptions,
  setCurrentPage,
  onListItemClicked,
  setSearchInput,
  setCreateDate,
  setSearchGender,
  onSearchBtnClicked,
}: Props) => {
  return (
    <div className='content-root user'>
      <div className='title-container'>
        <div className='title-item'>
          <div className='title-label'>{'사용자 목록'}</div>
          <div className='subtitle-label'>{'사용자 목록 페이지 입니다'}</div>
        </div>
      </div>

      <div className='table-container'>
        <div className='search-section'>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              setCurrentPage(0);
              onSearchBtnClicked();
            }}>
            <div className='input-container'>
              <label htmlFor='input-search' className='input-label' hidden>
                {'검색'}
              </label>
              <input
                className='input-box search'
                type='text'
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
                id='input-search'
                placeholder={'이메일, 이름, 전화번호로 검색해주세요'}
              />
              <button className='input-button'>
                <img src={image.searchBtn} alt='search-btn' />
              </button>
            </div>
          </form>
          <div className='input-container'>
            <label htmlFor='input-birth' className='input-label' hidden>
              {'생년월일'}
            </label>
            <input
              className='input-box'
              type='date'
              id='input-birth'
              value={createDate}
              onChange={(e) => {
                setCurrentPage(0);
                setCreateDate(e.target.value);
              }}
            />
          </div>
          <div className='select-container'>
            <label htmlFor='gender-select' hidden>
              {'성별'}
            </label>
            <select
              className={'select-box'}
              placeholder='성별'
              name='select-gender'
              id='select-gender'
              value={searchGender}
              onChange={(e) => {
                setSearchGender(e.target.value as UserGenderTypes);
                setCurrentPage(0);
              }}
              style={{ background: `url(${image.selectArrow}) no-repeat 90%` }}>
              {genderOptions.map((item, idx) => {
                return (
                  <option
                    key={idx}
                    value={item.value}
                    className={'option-gender'}>
                    {item.label}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        <div className='header-section'>
          <div className='item'>이메일</div>
          <div className='item'>이름</div>
          <div className='item'>전화번호</div>
          <div className='item'>생년월일</div>
          <div className='item'>성별</div>
        </div>
        <div className='body-section'>
          {userList.map((item, idx) => (
            <div
              key={idx}
              className='row'
              onClick={() => onListItemClicked(item)}>
              <div className='item'>{item.email}</div>
              <div className='item'>{item.name}</div>
              <div className='item'>{item.phone}</div>
              <div className='item'>{item.birth}</div>
              <div className='item'>
                {!item.gender
                  ? '선택 안됨'
                  : item.gender === 'F'
                  ? '여성'
                  : '남성'}
              </div>
            </div>
          ))}
        </div>
        <div className='footer-section'>
          <CPagingContainer
            maxPage={maxPage}
            page={currentPage}
            setPage={setCurrentPage}
          />
        </div>
      </div>
    </div>
  );
};

export default User;
