import useToken from '@hooks/store/useToken';
import { alertMessage } from '@libs/alertMessage';
import {
  apiRoute,
  requestGet,
  requestSecureDelete,
  requestSecurePatch,
} from '@libs/api/api';
import { uriToFile } from '@libs/trans';
import {
  NoticeDetailTypes,
  NoticeListItemTypes,
  NoticeOptionItemTypes,
} from '@typedef/components/NoticeEvent/notice.event.types';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import NoticeDetail from '../components/NoticeDetail';

const NoticeDetailContainer = () => {
  const navigate = useNavigate();

  const { getAccessToken } = useToken();

  const { item } = useLocation().state as {
    item: NoticeListItemTypes;
  };

  const [notice, setNotice] = useState<NoticeDetailTypes | null>(null);
  const [images, setImages] = useState<(File | null)[]>([]);

  const [categoryOption, setCategoryOption] = useState<
    [NoticeOptionItemTypes, NoticeOptionItemTypes]
  >([
    {
      type: 'CLIENT',
      label: '클라이언트',
    },
    {
      type: 'PARTNERS',
      label: '파트너스',
    },
  ]);

  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [category, setCategory] = useState<NoticeOptionItemTypes | null>(null);

  const loadDetail = useCallback(async () => {
    const { data, config } = await requestGet<NoticeDetailTypes>(
      apiRoute.notice.updateNotice + item.noticeUid,
      {},
    );

    if (config.status === 200) {
      setTitle(data.title);
      setContent(data.contents);
      setCategory(
        data.target === 'CLIENT'
          ? {
              type: 'CLIENT',
              label: '클라이언트',
            }
          : {
              type: 'PARTNERS',
              label: '파트너스',
            },
      );

      data.noticeFile &&
        setImages(
          await Promise.all(
            data.noticeFile.map((item) => uriToFile(item, 'image/*')),
          ),
        );

      setNotice(data);
    } else {
      alertMessage('상세정보를 불러오지 못했습니다');
    }
  }, []);

  const onDeleteBtnClicked = useCallback(async () => {
    if (!window.confirm('정말로 삭제하시겠습니까?')) {
      return;
    }
    const { config } = await requestSecureDelete(
      apiRoute.notice.deleteNotice + item.noticeUid,
      {},
      getAccessToken()!,
    );

    if (config.status === 202) {
      alertMessage('공지가 삭제되었습니다');
      navigate(-1);
    } else {
      alertMessage('공지 삭제에 실패했습니다');
    }
  }, [item, navigate]);

  const onUpdateBtnClicked = useCallback(async () => {
    const form = new FormData();

    form.append('target', category?.type || 'CLIENT');
    form.append('title', title);
    form.append('contents', content);

    images?.forEach((item) => {
      form.append('noticeFile', item as File);
    });

    const { config } = await requestSecurePatch(
      apiRoute.notice.updateNotice + notice?.noticeUid,
      {},
      form,
      getAccessToken()!,
    );

    if (config.status === 202) {
      navigate(-1);
    } else {
      alertMessage('공지사항 수정에 실패 했습니다');
    }
  }, [notice, category, title, content, images, navigate]);

  useEffect(() => {
    loadDetail();
  }, [loadDetail]);

  return notice ? (
    <NoticeDetail
      content={content}
      notice={notice}
      images={images}
      categoryOption={categoryOption}
      onUpdateBtnClicked={onUpdateBtnClicked}
      onDeleteBtnClicked={onDeleteBtnClicked}
      setCategory={setCategory}
      setImages={setImages}
      setTitle={setTitle}
      setContent={setContent}
    />
  ) : (
    <div className='content-root'></div>
  );
};

export default NoticeDetailContainer;
