import CPagingContainer from '@components/common/CPaging/containers/CPagingContainer';
import { formatDate } from '@libs/factory';
import { CouponTypes } from '@typedef/components/Coupon/coupon.types';
import React from 'react';
import './styles/coupon.styles.css';

type Props = {
  page: number;
  couponList: CouponTypes[];
  onListItemClicked: (item: CouponTypes) => void;
  maxPage: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  onAddCouponClicked: () => void;
};

const Coupon = ({
  page,
  couponList,
  onAddCouponClicked,
  onListItemClicked,
  maxPage,
  setPage,
}: Props) => {
  return (
    <div className='content-root coupon'>
      <div className='title-container'>
        <div className='title-item'>
          <div className='title-label'>{'쿠폰 관리'}</div>
          <div className='subtitle-label'>{'쿠폰 관리페이지입니다'}</div>
        </div>
        <button
          className='add-coupon-button'
          onClick={(e) => {
            e.preventDefault();
            onAddCouponClicked();
          }}>
          발행하기
        </button>
      </div>
      <div className='search-container'></div>
      <div className='table-container'>
        <div className='header-section'>
          <div className='item name'>쿠폰 이름</div>
          <div className='item amount'>쿠폰 금액</div>
          <div className='item condition'>사용조건</div>
          <div className='item limit-date'>사용기한</div>
          <div className='item'>쿠폰 발급처</div>
        </div>
        <div className='body-section'>
          {couponList.map((item, idx) => (
            <div
              key={idx}
              className='row'
              onClick={() => onListItemClicked(item)}>
              <div className='item name'>{item.name}</div>
              <div className='item amount'>
                {item.amount.toLocaleString()}원
              </div>
              <div className='item condition'>
                {item.condition.toLocaleString()} 원 이상 구매 시
              </div>
              <div className='item limit-date'>
                {`${formatDate(item.createDate)} ~ ${
                  item.limitDate ? formatDate(item.limitDate) : '제한없음'
                }`}
              </div>
              <div className='item name'>
                {item.storeUid ? item.store?.name : '관리자쿠폰'}
              </div>
            </div>
          ))}
        </div>
        <div className='footer-section'>
          <CPagingContainer maxPage={maxPage} page={page} setPage={setPage} />
        </div>
      </div>
    </div>
  );
};

export default Coupon;
