import image from '@components/common/assets/image';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import CMultiFileInput from '../components/CMultiFileInput';

type Props = {
  onSelectdFilesChanged: (files: File[]) => void;
  initialValue?: File[];
};

const CMultiFileInputContainer = ({
  onSelectdFilesChanged,
  initialValue = [],
}: Props) => {
  const [files, setFiles] = useState<File[]>(initialValue);

  const inputRef = useRef<HTMLInputElement>(null);

  const onFileSelectClicked = useCallback(() => {
    const input = inputRef?.current;
    if (!input) return;

    input.value = '';
    input.click();
  }, [inputRef]);

  const onFileSelected = useCallback(
    (selected: File[]) => {
      console.log('file selected');

      setFiles([...files, ...selected]);
    },
    [files],
  );

  const onDeleteClicked = useCallback(
    (idx: number) => {
      const clone = [...files];

      clone.splice(idx, 1);

      setFiles(clone);
    },
    [files],
  );

  useEffect(() => {
    onSelectdFilesChanged(files);
  }, [onSelectdFilesChanged, files]);

  useEffect(() => {
    files.forEach((item, idx) => {
      const fr = new FileReader();

      fr.onload = () => {
        (
          document.getElementById(`image-preview-${idx}`) as HTMLImageElement
        ).src = fr.result as string;
      };

      item !== null
        ? fr.readAsDataURL(item)
        : ((
            document.getElementById(`image-preview-${idx}`) as HTMLInputElement
          ).src = image.placeholderImg);
    });
  }, [files]);

  return (
    <CMultiFileInput
      inputRef={inputRef}
      files={files}
      onFileSelectClicked={onFileSelectClicked}
      onFileSelected={onFileSelected}
      onDeleteClicked={onDeleteClicked}
    />
  );
};

export default CMultiFileInputContainer;
