import React, { RefObject } from 'react';
import images from './assets/images';
import './styles/csearch.styles.css';

type Props = {
  formRef: RefObject<HTMLFormElement>;
  inputRef: RefObject<HTMLInputElement>;
  deleteBtnVisible: boolean;
  setInput: React.Dispatch<React.SetStateAction<string>>;
  onSearchBtnClicked: () => void;
  onDeleteBtnClicked: () => void;
  inputId: string;
  placeholder: string;
  onFocused: () => void;
  onBlured: () => void;
};

const CSearch = ({
  formRef,
  inputRef,
  deleteBtnVisible,
  setInput,
  onSearchBtnClicked,
  onDeleteBtnClicked,
  inputId,
  placeholder,
  onFocused,
  onBlured,
}: Props) => {
  return (
    <div className='csearch-root'>
      <label htmlFor={inputId} hidden>{`${inputId} search`}</label>
      <form
        ref={formRef}
        className='csearch-input-container'
        onSubmit={(e) => {
          e.preventDefault();
          onSearchBtnClicked();
        }}>
        <input
          ref={inputRef}
          className='csearch-input'
          type='text'
          placeholder={placeholder}
          onChange={(e) => setInput(e.target.value)}
          onFocus={onFocused}
          onBlur={onBlured}
        />
        {deleteBtnVisible && (
          <button
            className='csearch-delete-btn'
            type='button'
            onClick={onDeleteBtnClicked}>
            <img src={images.deleteBtn} alt={`${inputId} delete btn`} />
          </button>
        )}
        <button className='csearch-search-btn' type='submit'>
          <img src={images.searchBtn} alt={`${inputId} search btn`} />
        </button>
      </form>
    </div>
  );
};

export default CSearch;
