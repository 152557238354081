import CCheckboxContainer from '@components/common/CCheckbox/containers/CCheckboxContainer';
import CInputContainer from '@components/common/CInput/containers/CInputContainer';
import { checkIncludeNotNumber } from '@libs/validation';
import React from 'react';
import '../styles/coupon.add.styles.css';

type Props = {
  name: string;
  setName: React.Dispatch<React.SetStateAction<string>>;
  amount: number;
  setAmount: React.Dispatch<React.SetStateAction<number>>;
  condition: number;
  setCondition: React.Dispatch<React.SetStateAction<number>>;
  limitDate: string | null;
  setLimitDate: React.Dispatch<React.SetStateAction<string | null>>;
  isInfinit: boolean;
  setIsInfinite: React.Dispatch<React.SetStateAction<boolean>>;
  onAddCouponClicked: () => void;
};

const CouponAdd = ({
  name,
  setName,
  amount,
  setAmount,
  condition,
  setCondition,
  limitDate,
  setLimitDate,
  isInfinit,
  setIsInfinite,
  onAddCouponClicked,
}: Props) => {
  return (
    <div className='content-root coupon-add'>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onAddCouponClicked();
        }}>
        <div className='title-container'>
          <div className='title-item'>
            <div className='title-label'>{'쿠폰 관리'}</div>
            <div className='subtitle-label'>{'쿠폰 상세페이지입니다'}</div>
          </div>
          <button type='submit' className='add-coupon-button'>
            발행하기
          </button>
        </div>
        <div className='body-section'>
          <CInputContainer
            isRequired={true}
            type={'text'}
            inputId='add-coupon-name'
            containerType={{ type: 'outline', adaptive: false }}
            onInputChanged={setName}
            label='쿠폰 이름'
          />
          <div className='coupon-input-wrapper'>
            <CInputContainer
              isRequired={true}
              type='text'
              testValue={checkIncludeNotNumber}
              inputId='add-coupon-name'
              containerType={{ type: 'outline', adaptive: false }}
              onInputChanged={(item) => {
                setAmount(Number(item));
              }}
              label='쿠폰 금액'
              placeholder='금액을 입력해주세요'
            />
            <span>원</span>
          </div>
          <div className='coupon-input-wrapper'>
            <CInputContainer
              isRequired={true}
              type='text'
              testValue={checkIncludeNotNumber}
              inputId='add-coupon-name'
              containerType={{ type: 'outline', adaptive: false }}
              onInputChanged={(item) => {
                setCondition(Number(item));
              }}
              label='사용 조건'
              placeholder='금액을 입력해주세요'
            />
            <span>원 이상부터 사용 가능</span>
          </div>
          <div className='limit-date-wrapper'>
            {!isInfinit && (
              <CInputContainer
                type={'date'}
                inputId='add-coupon-name'
                containerType={{ type: 'outline', adaptive: false }}
                onInputChanged={(item) => {
                  setLimitDate(item);
                }}
                label='사용 기한'
                disabled={isInfinit}
              />
            )}
            <div className='checkbox-wrapper'>
              <CCheckboxContainer
                onCheckChanged={(checked) => {
                  console.log(checked);
                  if (checked) setLimitDate(null);
                  setIsInfinite(checked);
                }}
                checkType='square'
                colorType='border'
              />
              <span>기간 제한 없음</span>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default CouponAdd;
