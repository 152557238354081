import useToken from '@hooks/store/useToken';
import { alertMessage } from '@libs/alertMessage';
import { apiRoute, requestSecureGet, requestSecurePatch } from '@libs/api/api';
import { AccumulationRateTypes } from '@typedef/components/Point/point.types';
import { useCallback, useEffect, useState } from 'react';
import Point from '../Point';

const PointContainer = () => {
  const [searchInput, setSearchInput] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [qr, setQr] = useState('');
  const [payment, setPayment] = useState('');
  const [keyword, setKeyword] = useState('');
  const [isAdminPoint, setIsAdminPoint] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const { getAccessToken } = useToken();

  const loadPointAccumulationRate = useCallback(async () => {
    const {
      data,
      config: { status },
    } = await requestSecureGet<AccumulationRateTypes>(
      apiRoute.point.getPointAccumulation + 'accumulation-rate',
      {},
      getAccessToken()!,
    );

    if (status !== 200) {
      alertMessage('정보를 불러오지 못했습니다.');
      return;
    }

    setQr(data.qrPointAccumulationRate);
    setPayment(data.paymentPointAccumulationRate);
    setIsLoaded(true);
  }, [getAccessToken]);

  const onQrSaveClicked = useCallback(async () => {
    if (Number(qr) > 100 || Number(qr) < 0) {
      alertMessage('0에서 100사이의 값을 입력해주세요.');
      return;
    }

    const {
      data,
      config: { status },
    } = await requestSecurePatch<{}>(
      apiRoute.point.qr,
      {},
      {
        accumulationRate: qr,
      },
      getAccessToken()!,
    );

    if (status !== 202) {
      alertMessage('QR적립비율 설정에 실패했습니다.');
      return;
    }
    if (status === 202) {
      alertMessage('저장되었습니다!');
    }
  }, [getAccessToken, qr]);

  const onPaymentSaveClicked = useCallback(async () => {
    if (Number(payment) > 100 || Number(payment) < 0) {
      alertMessage('0에서 100사이의 값을 입력해주세요.');
      return;
    }

    const {
      data,
      config: { status },
    } = await requestSecurePatch<{}>(
      apiRoute.point.payment,
      {},
      {
        accumulationRate: payment,
      },
      getAccessToken()!,
    );

    if (status !== 202) {
      alertMessage('앱 결제 적립비율 설정에 실패했습니다.');
      return;
    }

    if (status === 202) {
      alertMessage('저장되었습니다!');
    }
  }, [getAccessToken, payment]);

  const onAdminPointClicked = useCallback(() => {
    setIsAdminPoint((prev) => !prev);
  }, []);

  const onChangeQr = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;
    setQr(val);
  }, []);

  const onChangePayment = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const val = e.target.value;
      setPayment(val);
    },
    [],
  );

  const SearchButtonClicked = useCallback(() => {
    setKeyword(searchInput);
  }, [searchInput]);

  useEffect(() => {
    loadPointAccumulationRate();
  }, []);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (isLoaded) {
      timeout = setTimeout(() => {
        SearchButtonClicked();
      }, 1000);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [searchInput]);

  return (
    <Point
      startDate={startDate}
      endDate={endDate}
      setStartDate={setStartDate}
      setEndDate={setEndDate}
      searchInput={searchInput}
      setSearchInput={setSearchInput}
      qr={qr}
      payment={payment}
      isAdminPoint={isAdminPoint}
      onAdminPointClicked={onAdminPointClicked}
      setPayment={setPayment}
      onQrSaveClicked={onQrSaveClicked}
      onPaymentSaveClicked={onPaymentSaveClicked}
      onChangeQr={onChangeQr}
      onChangePayment={onChangePayment}
      keyword={keyword}
      SearchButtonClicked={SearchButtonClicked}
    />
  );
};

export default PointContainer;
