import React, { RefObject } from 'react';
import images from '../assets/images';
import { CInputPropTypes } from '../typedef/cinput.types';
import '../styles/coutline.input.styles.css';

const COutlineInput = ({
  inputId,
  input,
  setInput,
  type,
  label,
  error: { onError, errorMessage },
  inputRef,
  icon,
  onFocused,
  onBlured,
  onDeleteInputClicked = () => {},
  disabled = false,
  isRequired,
  placeholder,
  testValue = () => true,
}: CInputPropTypes & { icon?: string }) => {
  return (
    <div className='coutline-root'>
      <label htmlFor={inputId}>
        <span className='coutline-label'>
          {label}
          {isRequired && <span className='coutline-input-required'>*</span>}
        </span>
      </label>
      <div className='coutline-input'>
        {disabled ? (
          <div className='coutline-disabled-input'>{input}</div>
        ) : (
          <>
            {icon && (
              <img
                className='coutline-input-icon'
                src={icon}
                alt='input icon'
              />
            )}
            <input
              style={{
                paddingLeft: icon ? '44px' : '12px',
              }}
              id={inputId}
              onFocus={onFocused}
              onBlur={onBlured}
              className='coutline-input-input'
              ref={inputRef}
              type={type}
              value={input}
              onChange={(e) => {
                if (!testValue(e.target.value)) {
                  return;
                }
                setInput(e.target.value);
              }}
              placeholder={placeholder}
              required={isRequired}
            />
            {input !== '' && type !== 'date' && type !== 'time' && (
              <button
                className='coutline-delete-btn'
                onClick={onDeleteInputClicked}>
                <img
                  src={images.deleteBtn}
                  alt={`delete input btn of ${inputId}`}
                />
              </button>
            )}
          </>
        )}
      </div>
      {!disabled && onError && (
        <div className='coutline-error-message'>{errorMessage}</div>
      )}
    </div>
  );
};

export default COutlineInput;
