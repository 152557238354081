import useDetectClickOut from '@hooks/useDetectClickOut';
import React, { useCallback, useEffect, useState } from 'react';
import CTimeSelect from '../CTimeSelect';
import CSelect from '../CTimeSelect';

type Props = {
  selectedHour: string | null;
  onHourSelected: (item: string) => void;
  selectedMinutes: string | null;
  onMinutesSelected: (item: string) => void;
  isDisabled?: boolean;
};

function CTimeSelectContainer({
  selectedHour,
  selectedMinutes,
  onHourSelected,
  onMinutesSelected,
  isDisabled = false,
}: Props) {
  const { show, nodeRef, triggerRef, setShow } = useDetectClickOut(false);

  return (
    <CTimeSelect
      selectedHour={selectedHour}
      selectedMinutes={selectedMinutes}
      onHourSelected={onHourSelected}
      onMinutesSelected={onMinutesSelected}
      show={show}
      nodeRef={nodeRef}
      triggerRef={triggerRef}
      isDisabled={isDisabled}
    />
  );
}

export default CTimeSelectContainer;
