import image from '@components/common/assets/image';

import CPagingContainer from '@components/common/CPaging/containers/CPagingContainer';
import CSearchContainer from '@components/common/CSearch/containers/CSearchContainer';
import CSelectContainer from '@components/common/CSelect/containers/CSelectContainer';
import { ProductTypes } from '@typedef/components/Item/item.types';
import React from 'react';

const selectOptionList: { label: string; idx: number }[] = [
  { label: '전체', idx: 0 },
  { label: '특가 상품', idx: 1 },
  { label: '일반 상품', idx: -1 },
];

type Props = {
  searchInput: string;
  onSearchInputChanged: (value: string) => void;
  productList: ProductTypes[];
  selectedOption: number;
  onSelectedOptionChanged: (value: number) => void;
  maxPage: number;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  onSearchBtnClicked: () => void;
  onItemClicked: (productUid: number, idx: number) => void;
};

const Item = ({
  searchInput,
  onSearchInputChanged,
  productList,
  selectedOption,
  onSelectedOptionChanged,
  maxPage,
  page,
  setPage,
  onSearchBtnClicked,
  onItemClicked,
}: Props) => {
  return (
    <div className='content-root sale-item-root'>
      <div className='title-container'>
        <div className='title-item'>
          <div className='title-label'>{'상품 관리'}</div>
          <div className='subtitle-label'>{'상품 관리 페이지 입니다'}</div>
        </div>
      </div>

      <div className='table-container'>
        <div className='search-section'>
          <form>
            <div className='input-container'>
              <label htmlFor='input-item-search' className='input-label' hidden>
                {'검색'}
              </label>
              <input
                className='input-box search'
                type='text'
                value={searchInput}
                onChange={(e) => onSearchInputChanged(e.target.value)}
                id='input-item-search'
                placeholder='상품명으로 검색해주세요'
              />
              <button
                className='input-button'
                onClick={(e) => {
                  e.preventDefault();
                  onSearchBtnClicked();
                }}>
                <img src={image.searchBtn} alt='search-btn' />
              </button>
            </div>
          </form>
          <div className='select-container'>
            <label htmlFor='item-select' hidden>
              {'상픔 종류'}
            </label>
            <select
              className={'select-box'}
              placeholder='상품 종류'
              name='select-item'
              id='select-item'
              value={selectedOption}
              onChange={(e) => {
                setPage(0);
                onSelectedOptionChanged(Number(e.target.value));
              }}
              style={{ background: `url(${image.selectArrow}) no-repeat 90%` }}>
              {selectOptionList.map((item, idx) => {
                return (
                  <option key={idx} value={item.idx} className={'option-store'}>
                    {item.label}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        <div className='header-section'>
          <div className='item'>{'상품사진'}</div>
          <div className='item'>{'상품명'}</div>
          <div className='item'>{'가격'}</div>
          <div className='item'>{'가게명'}</div>
          <div className='item'> {'옵션'}</div>
          <div className='item'> {'특가상품여부'}</div>
        </div>
        <div className='body-section'>
          {productList.map((item, idx) => {
            return (
              <div
                className='row'
                key={idx}
                onClick={() => {
                  onItemClicked(item.productUid, idx);
                }}>
                <div className='item'>
                  <img
                    className='image'
                    src={item.productFile[0] ?? image.placeholderImg}
                    alt='상품 사진'
                    onError={(e) => {
                      e.currentTarget.src = image.placeholderImg;
                    }}
                  />
                </div>
                <div className='item'>{item.name}</div>
                <div className='item'>{item.price.toLocaleString()}</div>
                <div className='item'>{item.store.name}</div>
                <div className='item'>
                  {item.productOptionGroups[0]
                    ? Object.values(item.productOptionGroups[0])
                        .filter((item) => item !== null)
                        .join(', ')
                    : ''}
                </div>
                <div className='item'>
                  {item.discountedPrice ? '특가상품' : '일반상품'}
                </div>
              </div>
            );
          })}
        </div>
        <div className='footer-section'>
          <CPagingContainer maxPage={maxPage} page={page} setPage={setPage} />
        </div>
      </div>
    </div>
  );
};

export default Item;
