import image from '@components/common/assets/image';
import CDateInputContainer from '@components/common/CInput/containers/CDateInputContainer';
import CInputContainer from '@components/common/CInput/containers/CInputContainer';

import CPagingContainer from '@components/common/CPaging/containers/CPagingContainer';
import CSearchContainer from '@components/common/CSearch/containers/CSearchContainer';
import { formatDate } from '@libs/factory';
import {
  OrderListItemTypes,
  PaymentListItemTypes,
} from '@typedef/components/Order/order.types';
import React from 'react';
import './styles/order.styles.css';

type Props = {
  paymentList: PaymentListItemTypes[];
  maxPage: number;
  currentPage: number;
  searchInput: string;
  paymentFrom: string;
  paymentTo: string;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  onSearchInputChanged: (value: string) => void;
  onPageChanged: (page: number) => void;
  onListItemClicked: (item: PaymentListItemTypes) => void;
  setSearchInput: React.Dispatch<React.SetStateAction<string>>;
  setPaymentFrom: React.Dispatch<React.SetStateAction<string>>;
  setPaymentTo: React.Dispatch<React.SetStateAction<string>>;
  onSearchBtnClicked: () => void;
  onApplyBtnClicked: () => void;
};

const Order = ({
  paymentList,
  maxPage,
  currentPage,
  searchInput,
  paymentFrom,
  paymentTo,
  setCurrentPage,
  onPageChanged,
  onListItemClicked,
  onSearchInputChanged,
  setSearchInput,
  setPaymentFrom,
  setPaymentTo,
  onSearchBtnClicked,
  onApplyBtnClicked,
}: Props) => {
  return (
    <div className='content-root order'>
      <div className='title-container'>
        <div className='title-item'>
          <div className='title-label'>{'주문내역'}</div>
          <div className='subtitle-label'>{'주문내역입니다.'}</div>
        </div>
      </div>

      <div className='table-container'>
        <div className='search-section'>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              onSearchBtnClicked();
            }}>
            <div className='input-container'>
              <label
                htmlFor='input-order-search'
                className='input-label'
                hidden>
                {'검색'}
              </label>
              <input
                className='input-box search'
                type='text'
                value={searchInput}
                onChange={(e) => onSearchInputChanged(e.target.value)}
                id='input-order-search'
                placeholder='주문번호, 구매자명으로 검색해주세요'
              />
              <button className='input-button'>
                <img src={image.searchBtn} alt='search-btn' />
              </button>
            </div>
          </form>
          <div className='duration-search-container'>
            <div className='input-wrapper'>
              <input
                type='date'
                className='date-input'
                value={paymentFrom}
                onChange={(e) => {
                  setPaymentFrom(e.target.value);
                }}
              />
              <span>~</span>
              <input
                type='date'
                className='date-input'
                value={paymentTo}
                onChange={(e) => {
                  setPaymentTo(e.target.value);
                }}
              />
            </div>
          </div>
        </div>
        <div className='header-section'>
          <div className='item'>{'주문번호'}</div>
          <div className='item'>{'결제일시'}</div>
          <div className='item'>{'구매자'}</div>
          <div className='item'>{'총 상품금액'}</div>
          <div className='item'>{'상품 수'}</div>
        </div>
        <div className='body-section'>
          {paymentList.map((item, idx) => {
            return (
              <div
                key={idx}
                className='row'
                onClick={() => onListItemClicked(item)}>
                <div className='item'>{item.merchantUid}</div>
                <div className='item'>{formatDate(item.createDate)}</div>
                <div className='item'>{item.buyerName}</div>
                <div className='item'>
                  {`${(
                    item.discountedAmountByCoupon +
                    item.discountedAmountByPoint +
                    item.totalAmount
                  ).toLocaleString()} 원`}
                </div>
                <div className='item'>
                  {`${item.orders.reduce((prev, current) => {
                    return (prev += current.count);
                  }, 0)}
                  개`}
                </div>
              </div>
            );
          })}
        </div>
        <div className='footer-section'>
          <CPagingContainer
            maxPage={maxPage}
            page={currentPage}
            setPage={setCurrentPage}
          />
        </div>
      </div>
    </div>
  );
};

export default Order;
