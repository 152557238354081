import CPagingContainer from '@components/common/CPaging/containers/CPagingContainer';
import { formatDate } from '@libs/factory';
import { PointListItemTypes } from '@typedef/components/Point/point.types';

type Props = {
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  maxPage: number;
  onPageChanged: (page: number) => void;
  points: PointListItemTypes[];
};

const List = ({
  currentPage,
  setCurrentPage,
  maxPage,
  onPageChanged,
  points,
}: Props) => {
  return (
    <div className='table-container'>
      <div className='header-section'>
        <div className='item'>가게명</div>
        <div className='item'>구매자 이름</div>
        <div className='item'>결제금액</div>
        <div className='item'>포인트</div>
        <div className='item'>적립일</div>
        <div className='item'>비고</div>
      </div>
      <div className='body-section'>
        {points.map((item, index) => {
          const storeNames = new Set<string>();

          item.contents?.forEach((item, idx) => {
            storeNames.add(item.storeName);
          });
          return (
            <div key={index} className='row'>
              <div className='item'>
                {item.type === 'ADMIN' && '관리자 적립'}
                {(item.type === 'PAYMENT' || item.type === 'QR') &&
                  `${Array.from(storeNames)[0]} ${
                    storeNames.size > 1
                      ? `외 ${storeNames.size - 1}개 매장`
                      : ''
                  }`}
                {item.type === 'CANCEL' && '주문 취소'}
                {item.type === 'RETURN' && '주문 반품'}
              </div>
              <div className='item'>{item.user.name}</div>
              <div className='item'>
                {item.contents
                  ?.reduce((prev, current) => {
                    return (prev += current.productPrice);
                  }, 0)
                  .toLocaleString()}
              </div>
              <div className='item'>{item.point.toLocaleString()}</div>
              <div className='item'>{formatDate(item.createDate)}</div>
              <div className='item'>
                {item.type === 'QR' && 'QR적립'}
                {item.type === 'ADMIN' && '관리자 적립'}
                {(item.type === 'CANCEL' || item.type === 'RETURN') &&
                  '포인트 반환'}
              </div>
            </div>
          );
        })}
      </div>
      <div className='footer-section'>
        <CPagingContainer
          maxPage={maxPage}
          page={currentPage}
          setPage={setCurrentPage}
        />
      </div>
    </div>
  );
};

export default List;
