import usePopup from '@hooks/store/usePopup';
import useToken from '@hooks/store/useToken';
import { alertMessage } from '@libs/alertMessage';
import {
  apiRoute,
  BasicApiResponse,
  BasicListDataType,
  requestSecureDelete,
  requestSecureGet,
  requestSecurePatch,
  requestSecurePost,
} from '@libs/api/api';
import { CouponTypes } from '@typedef/components/Coupon/coupon.types';
import React from 'react';
import { useEffect } from 'react';
import { useCallback } from 'react';
import { useState } from 'react';
import PartnersDetailCoupon from '../components/PartnersDetailCoupon';
import AddCouponPopupContainer from '../../Coupon/containers/AddCouponPopupContainer';

type Props = {
  storeUid?: number;
  isEditable: boolean;
};

const PartnersDetailCouponContainer = ({ storeUid, isEditable }: Props) => {
  const { visible, __showPopupFromHooks, __hidePopupFromHooks } = usePopup();
  const { getAccessToken } = useToken();
  const [couponList, setCouponList] = useState<CouponTypes[]>([]);
  const [maxPage, setMaxPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(0);
  const itemCnt = 5;

  const onAddCouponClicked = useCallback(() => {
    __showPopupFromHooks(<AddCouponPopupContainer storeUid={storeUid ?? -1} />);
  }, [storeUid]);

  const onEditCouponClicked = useCallback(
    async (idx: number) => {
      __showPopupFromHooks(
        <AddCouponPopupContainer
          storeUid={storeUid ?? -1}
          coupon={couponList[idx]}
        />,
      );
    },
    [couponList, __showPopupFromHooks, storeUid],
  );

  const onDeleteCouponClicked = useCallback(
    async (idx: number) => {
      if (couponList[idx].couponUid !== -1) {
        const {
          data,
          config: { status },
        } = await requestSecureDelete<BasicApiResponse<{}>>(
          apiRoute.coupon.deleteCoupons + couponList[idx].couponUid,
          {},
          getAccessToken()!,
        );

        if (status !== 202) return;
      }

      setCouponList((prev) => {
        const clone = [...prev];
        clone.splice(idx, 1);
        return clone;
      });
    },
    [couponList],
  );

  const loadCouponList = useCallback(async () => {
    if (storeUid === undefined) return;

    const {
      data,
      config: { status },
    } = await requestSecureGet<BasicListDataType<CouponTypes>>(
      apiRoute.coupon.getStoreCoupons +
        storeUid +
        `?page=${currentPage}&perPage=${itemCnt}`,
      {},
      getAccessToken()!,
    );

    if (status !== 200) return;
    console.log('page ', currentPage, data);
    setCouponList(data.rows);
    setMaxPage(data.maxPage);
  }, [storeUid, currentPage]);

  useEffect(() => {
    loadCouponList();
  }, [loadCouponList, visible]);

  return (
    <PartnersDetailCoupon
      isEditable={isEditable}
      maxPage={maxPage}
      couponList={couponList}
      setCouponList={setCouponList}
      onAddCouponClicked={onAddCouponClicked}
      onDeleteCouponClicked={onDeleteCouponClicked}
      onEditCouponClicked={onEditCouponClicked}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
    />
  );
};

export default PartnersDetailCouponContainer;
