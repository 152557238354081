import usePopup from '@hooks/store/usePopup';
import {
  ParsedOptionsTypes,
  ProductOptionsTypes,
} from '@typedef/components/Item/item.types';
import { stringify } from 'querystring';
import React, { useCallback } from 'react';
import { useState } from 'react';
import { cloneElement } from 'react';
import { useEffect } from 'react';
import OptionPopup from '../components/OptionPopup';

type Props = {
  parsedOptions: ParsedOptionsTypes[];
  productOptions: ProductOptionsTypes[];
  setParsedOptions: React.Dispatch<React.SetStateAction<ParsedOptionsTypes[]>>;
  setProductOptions: React.Dispatch<
    React.SetStateAction<ProductOptionsTypes[]>
  >;
  idx: number;
};

const OptionPopupContainer = ({
  parsedOptions,
  productOptions,
  setParsedOptions,
  setProductOptions,
  idx,
}: Props) => {
  const { __hidePopupFromHooks } = usePopup();
  const [itemOption, setItemOption] = useState(
    parsedOptions[idx]
      ? {
          opt: [...parsedOptions[idx].opt],
          optName: parsedOptions[idx].optName,
        }
      : { opt: [], optName: '' },
  );

  const onAddOptionItemClicked = useCallback(() => {
    setItemOption((prev) => {
      const clone = Object.assign({}, prev);
      clone.opt = [...clone.opt, ''];
      return clone;
    });
  }, []);

  const onDeleteOptionItemClicked = useCallback((idx: number) => {
    setItemOption((prev) => {
      const clone = Object.assign({}, prev);
      clone.opt.splice(idx, 1);
      return clone;
    });
  }, []);

  const onUpdateOptionItemClicked = useCallback(() => {
    if (itemOption.optName === '') {
      __hidePopupFromHooks();
      return;
    }

    const itemOptionExceptNull = Object.assign({}, itemOption);
    itemOptionExceptNull.opt = itemOptionExceptNull.opt.filter(
      (item) => item !== '',
    );

    setParsedOptions((prev) => {
      const clone = [...prev];
      if (!clone[idx]) clone.push(itemOptionExceptNull);
      else clone[idx] = itemOptionExceptNull;
      return clone;
    });
    __hidePopupFromHooks();
  }, [itemOption, __hidePopupFromHooks]);

  return (
    <OptionPopup
      idx={idx}
      itemOption={itemOption}
      setItemOption={setItemOption}
      parsedOptions={parsedOptions}
      productOptions={productOptions}
      setParsedOptions={setParsedOptions}
      setProductOptions={setProductOptions}
      onUpdateOptionItemClicked={onUpdateOptionItemClicked}
      onAddOptionItemClicked={onAddOptionItemClicked}
      onDeleteOptionItemClicked={onDeleteOptionItemClicked}
    />
  );
};

export default OptionPopupContainer;
