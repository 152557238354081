import LoginContainer from '@components/Login/containers/LoginContainer';
import useToken from '@hooks/store/useToken';
import { useTab } from '@hooks/useTab';
import { alertMessage } from '@libs/alertMessage';
import { apiRoute, requestSecureGet } from '@libs/api/api';

import {
  GetPartnersListResponseTypes,
  PartnersCategoryListItemTypes,
  PartnersListItemTypes,
  PartnersQueryParamsTypes,
  PartnersReqCategoryTypes,
  PartnersSignupQueryParamsTypes,
} from '@typedef/components/Partners/partners.types';
import React, { useCallback, useEffect, useState } from 'react';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import SignupRequestList from '../components/SignupRequestList';

const SignupRequestListContainer = () => {
  const navigate = useNavigate();
  const { queryParams, updateQueryParamsFromSession, tab } =
    useTab<PartnersSignupQueryParamsTypes>('partners');

  const { getAccessToken } = useToken();

  const [partners, setPartners] = useState<PartnersListItemTypes[]>([]);
  const [maxPage, setMaxPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(
    (tab === 'signup-req' && queryParams.page) || 0,
  );

  const [shopCategory, setShopCategory] = useState<
    PartnersCategoryListItemTypes[]
  >([]);

  const [selectedCategory, setSelectedCategory] =
    useState<PartnersCategoryListItemTypes | null>(
      (tab === 'signup-req' && queryParams.selectedCategory) || null,
    );

  const [selectedReqCategory, setSelectedReqCategory] = useState<number>(
    (tab === 'signup-req' && queryParams.selectedReqCategory) || 0,
  );
  const [requestCategory, setRequestCategory] = useState<
    PartnersReqCategoryTypes[]
  >([
    { label: '가입요청', idx: 0 },
    { label: '가입승인', idx: 1 },
    { label: '가입거절', idx: -1 },
  ]);

  const [searchInput, setSearchInput] = useState(
    (tab === 'signup-req' && queryParams.searchInput) || '',
  );

  const [isLoaded, setIsLoaded] = useState(false);

  const onCurrentPageChanged = useCallback((page: number) => {
    setCurrentPage(page);
  }, []);

  const onSearchInputChanged = useCallback((value: string) => {
    setSearchInput(value);
  }, []);

  const loadPartners = useCallback(async () => {
    const { data, config } =
      await requestSecureGet<GetPartnersListResponseTypes>(
        apiRoute.partners.getPartners +
          `?page=${currentPage}&perPage=${10}` +
          `${searchInput ? '&q=' + searchInput : ''}` +
          `${
            selectedCategory && selectedCategory.targetTagUid !== -1
              ? '&targetTagUid=' + selectedCategory.targetTagUid
              : ''
          }` +
          `${
            selectedReqCategory !== 2
              ? `&allowSignIn=${selectedReqCategory}`
              : ''
          }`,
        {},
        getAccessToken()!,
      );

    if (config.status === 200) {
      setMaxPage(data.maxPage);
      setPartners(data.rows);
    } else {
      alertMessage('목록을 불러오지 못했습니다');
    }
  }, [currentPage, selectedCategory, selectedReqCategory, searchInput]);

  const onPageChanged = useCallback((page: number) => {
    setCurrentPage(page);
  }, []);

  const onPartnersItemClicked = useCallback(
    (item: PartnersListItemTypes) => {
      navigate('signupDetail', {
        state: {
          partnersUid: item.partnersUid,
        },
      });
    },
    [navigate],
  );

  const loadCategory = useCallback(async () => {
    const { data, config } = await requestSecureGet<
      PartnersCategoryListItemTypes[]
    >(apiRoute.partners.tags.getTags, {}, getAccessToken()!);

    if (config.status === 200) {
      setShopCategory([
        {
          targetTagUid: -1,
          name: '전체보기',
          createDate: '',
          updateDate: '',
          deleteDate: null,
        } as PartnersCategoryListItemTypes,
        ...data,
      ]);
    } else {
      alertMessage('목록을 불러오지 못했습니다');
    }
  }, []);

  const onSearchBtnClicked = useCallback(() => {
    currentPage === 0 ? loadPartners() : setCurrentPage(0);
  }, [loadPartners, currentPage]);

  useEffect(() => {
    loadCategory();
  }, []);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (isLoaded) {
      timeout = setTimeout(() => {
        onSearchBtnClicked();
      }, 1000);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [searchInput]);

  useEffect(() => {
    loadPartners();
  }, [selectedCategory, currentPage, selectedReqCategory]);

  useEffect(() => {
    updateQueryParamsFromSession('signup-req', {
      page: currentPage,
      searchInput,
      selectedCategory,
      selectedReqCategory,
    });
  }, [currentPage, searchInput, selectedCategory, selectedReqCategory]);

  return (
    <SignupRequestList
      searchInput={searchInput}
      onSearchInputChanged={onSearchInputChanged}
      selectedCategory={selectedCategory}
      selectedReqCategory={selectedReqCategory}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      partners={partners}
      maxPage={maxPage}
      onPageChanged={onPageChanged}
      onPartnersItemClicked={onPartnersItemClicked}
      shopCategory={shopCategory}
      onSearchBtnClicked={onSearchBtnClicked}
      setSelectedCategory={setSelectedCategory}
      requestCategory={requestCategory}
      setSelectedReqCategory={setSelectedReqCategory}
    />
  );
};

export default SignupRequestListContainer;
