import CPagingContainer from '@components/common/CPaging/containers/CPagingContainer';
import { formatDate } from '@libs/factory';
import { PointListItemTypes } from '@typedef/components/Point/point.types';
import React from 'react';

type Props = {
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  maxPage: number;
  onPageChanged: (page: number) => void;
  points: PointListItemTypes[];
  onCheckBtnClicked: (uid: string, settlementAmount: number) => void;
};

const QrPointList = ({
  currentPage,
  setCurrentPage,
  maxPage,
  onPageChanged,
  points,
  onCheckBtnClicked,
}: Props) => {
  return (
    <div className='table-container'>
      <div className='header-section'>
        <div className='item'>가게명</div>
        <div className='item'>구매자 이름</div>
        <div className='item'>결제금액</div>
        <div className='item'>포인트</div>
        <div className='item'>적립일</div>
        <div className='item'>정산금액</div>
        <div className='item'>정산일자</div>
      </div>
      <div className='body-section'>
        {points.map((item, index) => {
          const storeNames = new Set<string>();

          item.contents?.forEach((item, idx) => {
            storeNames.add(item.storeName);
          });

          const price =
            item.contents?.reduce((prev, current) => {
              return (prev += current.productPrice);
            }, 0) ?? 0;

          return (
            <div key={index} className='row'>
              <div className='item'>
                {`${Array.from(storeNames)[0]} ${
                  storeNames.size > 1 ? `외 ${storeNames.size - 1}개 매장` : ''
                }`}
              </div>
              <div className='item'>{item.user.name}</div>
              <div className='item'>{price.toLocaleString()}</div>
              <div className='item'>{item.point.toLocaleString()}</div>
              <div className='item'>{formatDate(item.createDate)}</div>
              <div className='item'>
                {Math.round(
                  (price * (item?.commissionRate ?? 10)) / 100,
                ).toLocaleString()}
              </div>
              <div className='item'>
                {item.checkDate ? (
                  formatDate(item.checkDate)
                ) : (
                  <button
                    className='qr-point-btn'
                    onClick={() =>
                      onCheckBtnClicked(
                        item.pointLogUid,
                        Math.round(
                          (price * (item?.commissionRate ?? 10)) / 100,
                        ),
                      )
                    }>
                    {'정산하기'}
                  </button>
                )}
              </div>
            </div>
          );
        })}
      </div>
      <div className='footer-section'>
        <CPagingContainer
          maxPage={maxPage}
          page={currentPage}
          setPage={setCurrentPage}
        />
      </div>
    </div>
  );
};

export default QrPointList;
