import CPagingContainer from '@components/common/CPaging/containers/CPagingContainer';
import { formatDate } from '@libs/factory';
import { CouponTypes } from '@typedef/components/Coupon/coupon.types';
import { UserCouponTypes } from '@typedef/components/User/user.types';
import React from 'react';
import '../styles/user.coupon.list.styles.css';

type Props = {
  userUid: string;
  couponList: UserCouponTypes[];
  page: number;
  maxPage: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  onAddCouponClicked: () => void;
  onDeleteCouponClicked: (couponUid: number) => void;
};

const UserCouponList = ({
  userUid,
  couponList,
  page,
  maxPage,
  setPage,
  onAddCouponClicked,
  onDeleteCouponClicked,
}: Props) => {
  return (
    <div className='content-root user-coupon-list'>
      <div className='button-container'>
        <button className='primary-btn' onClick={onAddCouponClicked}>
          추가하기
        </button>
      </div>
      <div className='table-container'>
        <div className='header-section'>
          <div className='item'>수령일</div>
          <div className='item'>발급자명</div>
          <div className='item'>쿠폰이름</div>
          <div className='item'>금액</div>
          <div className='item'>사용조건</div>
          <div className='item'>삭제</div>
        </div>
        <div className='body-section'>
          {couponList.map((item, idx) => {
            return (
              <div className='row' key={idx}>
                <div className='item'>{formatDate(item.downloadDate)}</div>
                <div className='item'>{item.storeName ?? '관리자 쿠폰'}</div>
                <div className='item'>{item.name}</div>
                <div className='item'>{item.amount.toLocaleString()}</div>
                <div className='item'>{item.condition.toLocaleString()}</div>
                <div className='item'>
                  <button
                    className='delete-btn '
                    onClick={() => onDeleteCouponClicked(item.couponUid)}>
                    삭제하기
                  </button>
                </div>
              </div>
            );
          })}
        </div>
        <div className='footer-section'>
          <CPagingContainer maxPage={maxPage} page={page} setPage={setPage} />
        </div>
      </div>
    </div>
  );
};

export default UserCouponList;
