import useToken from '@hooks/store/useToken';
import { alertMessage } from '@libs/alertMessage';
import { apiRoute, requestSecureGet } from '@libs/api/api';
import {
  DeliveryFeeObjTypes,
  OrderDetailTypes,
  PaymentDetailItemTypes,
  PaymentListItemTypes,
} from '@typedef/components/Order/order.types';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import OrderDetail from '../components/OrderDetail';

const OrderDetailContainer = () => {
  const { paymentUid } = useLocation().state as {
    paymentUid: string;
  };
  const { getAccessToken } = useToken();
  const [paymentDetail, setPaymentDetail] = useState<PaymentListItemTypes>();
  const [paymentDetailObj, setPaymentDetailObj] =
    useState<PaymentDetailItemTypes>();

  const [deliveryFeeObj, setDeliveryFeeObj] = useState<DeliveryFeeObjTypes>();

  const loadOrderDetail = useCallback(async () => {
    const {
      data,
      config: { status },
    } = await requestSecureGet<PaymentListItemTypes>(
      apiRoute.payment.getPaymentDetail + paymentUid,
      {},
      getAccessToken()!,
    );

    if (status !== 200) {
      alertMessage('목록을 가져오지 못했습니다.');
      return;
    }

    const detailTmpObj: PaymentDetailItemTypes = {};
    const deliveryTmpObj: DeliveryFeeObjTypes = {};
    data.orders.forEach((item) => {
      detailTmpObj[item.store.storeUid] = [];
    });

    data.orders.map((item) => {
      detailTmpObj[item.store.storeUid].push(item);
    });

    data.deliveryFees?.forEach((item) => {
      deliveryTmpObj[item.storeUid] = item;
    });

    setPaymentDetailObj(detailTmpObj);
    setDeliveryFeeObj(deliveryTmpObj);
    setPaymentDetail(data);
  }, [paymentUid]);

  useEffect(() => {
    loadOrderDetail();
  }, [loadOrderDetail]);

  return paymentDetail && paymentDetailObj ? (
    <OrderDetail
      paymentDetail={paymentDetail}
      paymentDetailObj={paymentDetailObj}
      deliveryFeeObj={deliveryFeeObj}
    />
  ) : (
    <div></div>
  );
};

export default OrderDetailContainer;
