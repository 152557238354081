import React from 'react';
import '../styles/store.item.popup.styles.css';

type Props = {
  imageSrc: string;
};

const StoreImagePopup = ({ imageSrc }: Props) => {
  return (
    <div className='store-image-popup-root'>
      <img src={imageSrc} alt='이미지 팝업' />
    </div>
  );
};

export default StoreImagePopup;
