import RootNavigationContainer from '@routes/containers/RootNavigationContainer';
import rootReducer from '@store/rootReducer';
import React from 'react';
import { Provider } from 'react-redux';
import { applyMiddleware, createStore } from 'redux';
import '../styles/core.css';

const store = createStore(rootReducer);

const App = () => {
  return (
    <Provider store={store}>
      <RootNavigationContainer />
    </Provider>
  );
};

export default App;
