import useToken from '@hooks/store/useToken';
import { useTab } from '@hooks/useTab';
import { alertMessage } from '@libs/alertMessage';
import {
  apiRoute,
  BasicListDataType,
  requestSecureGet,
  requestSecurePatch,
} from '@libs/api/api';
import { getEndOfDate, getStartOfDate } from '@libs/factory';

import { uriToFile } from '@libs/trans';
import {
  CalcQueryParamsTypes,
  SettlementListItemTypes,
} from '@typedef/components/Calc/calc.types';
import React, { useCallback, useState } from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Calc from '../Calc';

const CalcContainer = () => {
  const navigate = useNavigate();
  const { getAccessToken } = useToken();
  const { queryParams, updateQueryParamsFromSession, removeTabFromSession } =
    useTab<CalcQueryParamsTypes>('calculate');

  const [settlementList, setSettlementList] = useState<
    SettlementListItemTypes[]
  >([]);
  const [maxPage, setMaxPage] = useState(0);
  const [page, setPage] = useState(queryParams.page || 0);
  const [searchInput, setSearchInput] = useState(queryParams.searchInput ?? '');
  const [paymentFrom, setPaymentFrom] = useState(queryParams.paymentFrom ?? '');
  const [paymentTo, setPaymentTo] = useState(queryParams.paymentTo ?? '');
  const [selectedOption, setSelectedOption] = useState(
    queryParams.isDone ?? -1,
  );
  const [rate, setRate] = useState(0);
  const [isLoaded, setIsLoaded] = useState(false);

  const onSearchInputChanged = useCallback((value: string) => {
    setSearchInput(value);
  }, []);

  const loadRate = useCallback(async () => {
    const {
      data,
      config: { status },
    } = await requestSecureGet<number>(
      apiRoute.settlement.getRate,
      {},
      getAccessToken()!,
    );

    if (status !== 200) {
      alertMessage('수수료 비율을 불러오지 못했습니다.');
      return;
    }
    setRate(data);
  }, []);

  const loadSettlementList = useCallback(async () => {
    const paymentFromQ = paymentFrom
      ? '&paymentFrom=' + getStartOfDate(paymentFrom)
      : '';
    const paymentToQ = paymentTo ? '&paymentTo=' + getEndOfDate(paymentTo) : '';

    const q = searchInput ? '&q=' + searchInput : '';

    const isDoneQ = selectedOption !== -1 ? '&isDone=' + selectedOption : '';

    const {
      data,
      config: { status },
    } = await requestSecureGet<BasicListDataType<SettlementListItemTypes>>(
      apiRoute.settlement.getSettlementList +
        `?page=${page}&perPage=${10}` +
        paymentFromQ +
        paymentToQ +
        q +
        isDoneQ,
      {},
      getAccessToken()!,
    );

    if (status !== 200) {
      alertMessage('목록을 불러오지 못했습니다.');
      return;
    }

    setSettlementList(data.rows);
    setMaxPage(data.meta.maxPage);
    setIsLoaded(true);
  }, [
    page,
    paymentFrom,
    paymentTo,
    searchInput,
    selectedOption,
    getAccessToken,
  ]);

  const onRateChange = useCallback((value: number) => {
    setRate(value);
  }, []);

  const onRateButtonClicked = useCallback(async () => {
    if (rate < 0 || rate > 100) {
      alertMessage('잘못된 수수료 퍼센트입니다.');
      return;
    }

    const {
      data,
      config: { status },
    } = await requestSecurePatch<{}>(
      apiRoute.settlement.updateRate,
      {},
      {
        settlementRate: rate.toFixed(1),
      },
      getAccessToken()!,
    );

    if (status !== 202) {
      alertMessage('정산 비율 수정에 실패했습니다');
      return;
    }

    alertMessage('정산 비율 수정에 성공했습니다');
  }, [rate, getAccessToken]);

  const onSearchBtnClicked = useCallback(() => {
    page === 0 ? loadSettlementList() : setPage(0);
  }, [page, loadSettlementList]);

  const onListItemClicked = useCallback(
    (paymentUid: number) => {
      navigate('detail', {
        state: {
          paymentUid,
          rate,
        },
      });
    },
    [navigate, rate],
  );

  useEffect(() => {
    loadRate();
  }, []);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (isLoaded) {
      timeout = setTimeout(() => {
        onSearchBtnClicked();
      }, 1000);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [searchInput]);

  useEffect(() => {
    loadSettlementList();
  }, [page, selectedOption, paymentFrom, paymentTo]);

  useEffect(() => {
    updateQueryParamsFromSession('', {
      page,
      searchInput,
      paymentFrom: paymentFrom,
      paymentTo: paymentTo,
      isDone: selectedOption,
    });
  }, [searchInput, page, selectedOption, paymentFrom, paymentTo]);

  return isLoaded ? (
    <Calc
      rate={rate}
      settlementList={settlementList}
      page={page}
      searchInput={searchInput}
      paymentFrom={paymentFrom}
      paymentTo={paymentTo}
      selectedOption={selectedOption}
      setPage={setPage}
      maxPage={maxPage}
      setPaymentFrom={setPaymentFrom}
      setPaymentTo={setPaymentTo}
      setSelectedOption={setSelectedOption}
      onSearchInputChanged={onSearchInputChanged}
      onSearchBtnClicked={onSearchBtnClicked}
      onListItemClicked={onListItemClicked}
      onRateChange={onRateChange}
      onRateButtonClicked={onRateButtonClicked}
    />
  ) : (
    <div></div>
  );
};

export default CalcContainer;
