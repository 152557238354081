import image from '@components/common/assets/image';
import { images } from './assets/images';
import ListContainer from './containers/ListContainer';
import './styles/points.css';

type Props = {
  startDate: string;
  endDate: string;
  setStartDate: React.Dispatch<React.SetStateAction<string>>;
  setEndDate: React.Dispatch<React.SetStateAction<string>>;
  searchInput: string;
  setSearchInput: React.Dispatch<React.SetStateAction<string>>;
  qr: string;
  payment: string;
  isAdminPoint: boolean;
  onAdminPointClicked: () => void;
  setPayment: React.Dispatch<React.SetStateAction<string>>;
  onQrSaveClicked: () => void;
  onPaymentSaveClicked: () => void;
  onChangeQr: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangePayment: (e: React.ChangeEvent<HTMLInputElement>) => void;
  keyword: string;
  SearchButtonClicked: () => void;
};

const Point = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  searchInput,
  setSearchInput,
  qr,
  payment,
  isAdminPoint,
  onAdminPointClicked,
  onQrSaveClicked,
  onPaymentSaveClicked,
  onChangeQr,
  onChangePayment,
  keyword,
  SearchButtonClicked,
}: Props) => {
  return (
    <div className='point-root content-root'>
      <div className='header-container'>
        <div className='left-wrapper'>
          <div className='title-container'>
            <div className='title-item'>
              <div className='title-label'>포인트 내역</div>
              <div className='subtitle-label'>포인트 내역 페이지 입니다.</div>
            </div>
          </div>
          <div className='search-container'>
            <div className='row'>
              <form>
                <div className='input-container'>
                  <label htmlFor='input-search' className='input-label' hidden>
                    {'검색'}
                  </label>
                  <input
                    className='input-box search'
                    type='text'
                    value={searchInput}
                    onChange={(e) => setSearchInput(e.target.value)}
                    id='input-search'
                    placeholder='가게명, 회원이름으로 검색해주세요'
                  />
                  <button
                    className='input-button'
                    onClick={(e) => {
                      e.preventDefault();
                      SearchButtonClicked();
                    }}>
                    <img src={image.searchBtn} alt='search-btn' />
                  </button>
                </div>
              </form>
              <div className='date-box'>
                <input
                  className='date-input'
                  type='date'
                  onChange={(e) => setStartDate(e.target.value)}
                />
                <span>{'~'}</span>
                <input
                  className='date-input'
                  type='date'
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </div>
            </div>
            <div className='row'>
              <label className='admin-checkbox' htmlFor='checkbox'>
                <span>{'관리자 적립만 보기'}</span>
                <input
                  id='checkbox'
                  type='checkbox'
                  checked={isAdminPoint}
                  onClick={onAdminPointClicked}
                />
              </label>
            </div>
          </div>
        </div>
        <div className='right-wrapper'>
          <div className='setting-card-container'>
            <form className='setting-card'>
              <div className='setting-card-title'>QR적립비율 설정</div>
              <div className='setting-card-input-box'>
                <input
                  className='setting-card-input'
                  type='number'
                  value={qr}
                  onChange={onChangeQr}
                />
                <span>%</span>
              </div>
              <button
                className='setting-button'
                onClick={(e) => {
                  e.preventDefault();
                  onQrSaveClicked();
                }}>
                저장하기
              </button>
            </form>
            <form className='setting-card'>
              <div className='setting-card-title'>앱 결제 적립비율 설정</div>
              <div className='setting-card-input-box'>
                <input
                  className='setting-card-input'
                  type='number'
                  value={payment}
                  onChange={onChangePayment}
                />
                <span>%</span>
              </div>
              <button
                className='setting-button'
                onClick={(e) => {
                  e.preventDefault();
                  onPaymentSaveClicked();
                }}>
                저장하기
              </button>
            </form>
          </div>
        </div>
      </div>
      <ListContainer
        isAdminPoint={isAdminPoint}
        startDate={startDate}
        endDate={endDate}
        keyword={keyword}
      />
    </div>
  );
};

export default Point;
