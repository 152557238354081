import image from '@components/common/assets/image';

import CPagingContainer from '@components/common/CPaging/containers/CPagingContainer';
import { formatDate } from '@libs/factory';
import { EventListItemTypes } from '@typedef/components/NoticeEvent/notice.event.types';
import React from 'react';

type Props = {
  page: number;
  maxPage: number;
  eventList: EventListItemTypes[];
  onAddEventBtnClicked: () => void;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  onItemClicked: (item: EventListItemTypes) => void;
};

const EventList = ({
  page,
  maxPage,
  eventList,
  onAddEventBtnClicked,
  setPage,
  onItemClicked,
}: Props) => {
  return (
    <>
      <div className='button-container'>
        <button className='primary-btn' onClick={onAddEventBtnClicked}>
          작성하기
        </button>
      </div>
      <div className='table-container'>
        <div className='header-section'>
          <div className='item'>{'이미지'}</div>
          <div className='item'>{'이벤트 URL'}</div>
          <div className='item'>{'게시날짜'}</div>
        </div>
        <div className='body-section'>
          {eventList.map((item, idx) => (
            <div key={idx} className='row' onClick={() => onItemClicked(item)}>
              <div className='item'>
                <img
                  style={{
                    width: 200,
                    height: 150,
                    backgroundColor: '#e4e4e4',
                  }}
                  className='event-img'
                  src={item.eventFile[0]}
                  alt='이벤트 이미지'
                  onError={(e) => (e.currentTarget.src = image.placeholderImg)}
                />
              </div>
              <div className='item'>{item.referenceUrl}</div>
              <div className='item'>
                {formatDate(item.startDate).split(' ')[0] +
                  ' ~ ' +
                  formatDate(item.endDate).split(' ')[0]}
              </div>
            </div>
          ))}
        </div>
        <div className='footer-section'>
          <CPagingContainer maxPage={maxPage} page={page} setPage={setPage} />
        </div>
      </div>
    </>
  );
};

export default EventList;
