import { useTab } from '@hooks/useTab';
import { alertMessage } from '@libs/alertMessage';
import { apiRoute, BasicListDataType, requestGet } from '@libs/api/api';
import { NoticeListItemTypes } from '@typedef/components/NoticeEvent/notice.event.types';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import NoticeList from '../components/NoticeList';

const NoticeListContainer = () => {
  const navigate = useNavigate();
  const { queryParams, tab, updateQueryParamsFromSession } = useTab<{
    page: number;
  }>('noticeEvent');

  const [noticeList, setNoticeList] = useState<NoticeListItemTypes[]>([]);
  const [maxPage, setMaxPage] = useState(0);
  const [page, setPage] = useState((tab === 'notice' && queryParams.page) || 0);

  const loadList = useCallback(async () => {
    const { data, config } = await requestGet<
      BasicListDataType<NoticeListItemTypes>
    >(apiRoute.notice.getNotice + `?page=${page}&perPage=10`, {});

    if (config.status === 200) {
      setNoticeList(data.rows);
      setMaxPage(data.maxPage);
    } else {
      alertMessage('목록을 불러오지 못했습니다');
    }
  }, [page]);

  const onAddBtnClicked = useCallback(() => {
    navigate('noticeAdd');
  }, [navigate]);

  const onListItemClicked = useCallback(
    (item: NoticeListItemTypes) => {
      navigate('noticeDetail', { state: { item } });
    },
    [navigate],
  );

  useEffect(() => {
    loadList();
  }, [loadList]);

  useEffect(() => {
    updateQueryParamsFromSession('notice', { page });
  }, [page]);

  return (
    <NoticeList
      page={page}
      onAddBtnClicked={onAddBtnClicked}
      noticeList={noticeList}
      setPage={setPage}
      maxPage={maxPage}
      onListItemClicked={onListItemClicked}
    />
  );
};

export default NoticeListContainer;
