import { FqaTabTypes } from '@typedef/components/FAQ/faq.types';
import React from 'react';
import FAQCategoryContainer from './containers/FAQCategoryContainer';
import FAQLListContainer from './containers/FAQLListContainer';

type Props = {
  tab: FqaTabTypes;
  onTabClicked: (tab: FqaTabTypes) => void;
};

const FAQ = ({ onTabClicked, tab }: Props) => {
  return (
    <div className='faq-root content-root'>
      <div className='title-container'>
        <div
          className={`title-item ${tab === 'faq' && 'selected'}`}
          onClick={() => onTabClicked('faq')}>
          <div className='title-label'>{'FAQ 관리'}</div>
          <div className='subtitle-label'>{'FAQ 관리 페이지 입니다'}</div>
        </div>
        <div
          className={`title-item ${tab === 'category' && 'selected'}`}
          onClick={() => onTabClicked('category')}>
          <div className='title-label'>{'카테고리 관리'}</div>
          <div className='subtitle-label'>{'카테고리 관리 페이지입니다'}</div>
        </div>
      </div>

      <div className='faq-content-container'>
        {tab === 'faq' && <FAQLListContainer />}
        {tab === 'category' && <FAQCategoryContainer />}
      </div>
    </div>
  );
};

export default FAQ;
