import { formatDate } from '@libs/factory';
import { CouponTypes } from '@typedef/components/Coupon/coupon.types';
import React from 'react';

type Props = {
  couponItem: CouponTypes;
  setCouponList: React.Dispatch<React.SetStateAction<CouponTypes[]>>;
  onDeleteCouponClicked: (idx: number) => void;
  onEditCouponClicked: (idx: number) => void;
  idx: number;
};

const CouponItem = ({
  couponItem,
  setCouponList,
  onDeleteCouponClicked,
  onEditCouponClicked,
  idx,
}: Props) => {
  return (
    <div className='row'>
      <div className='item create'>
        <span>{formatDate(couponItem.createDate)}</span>
      </div>
      <div className='item limit'>
        <span>
          {couponItem.limitDate ? formatDate(couponItem.limitDate) : '기간없음'}
        </span>
      </div>
      <div className='item name'>
        <span>{couponItem.name}</span>
      </div>
      <div className='item amount'>
        <span>{couponItem.amount}</span>
      </div>
      <div className='item condition'>
        <span>{couponItem.condition}</span>
      </div>
      <div className='button-wrapper item'>
        <button
          className=' delete button'
          onClick={() => onDeleteCouponClicked(idx)}>
          삭제하기
        </button>
      </div>
      <div className='button-wrapper item'>
        <button
          className=' save button'
          onClick={() => onEditCouponClicked(idx)}>
          수정하기
        </button>
      </div>
    </div>
  );
};

export default CouponItem;
